import React, { useEffect, useState } from 'react';
import Camera from '../../components/Camera';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

function CapturePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const [captureType, setCaptureType] = useState('building'); // Default to building

  useEffect(() => {
    // Get the type from URL query parameters
    const typeParam = searchParams.get('type');
    if (typeParam) {
      setCaptureType(typeParam);
    }
  }, [searchParams]);

  const downloadImage = (dataUrl) => {
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = 'taken';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const onPhotoTaken = (photoUrl, photoBlob) => {
    // downloadImage(photoUrl);

    // Navigate to the full path with projectId and pass the type parameter
    navigate(`/project/${projectId}/capture-preview`, { 
      state: { 
        ...location.state, 
        photoUrl, 
        photoBlob, 
        previousPage: `/project/${projectId}/capture`,
        type: captureType // Pass the capture type
      }, 
      replace: true 
    });
  }

  return <Camera onPhotoTaken={onPhotoTaken} asBlob={true} />
}

export default CapturePage;