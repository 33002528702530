import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useNonBuildingService } from "../../services/NonBuildingService";
import { useRequestService } from "../../services/RequestService";
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, Marker, GeoJSON, useMapEvents, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { toast } from 'react-toastify';
import HashtagInput from '../../components/HashtagInput';

// Fix for default marker icon in Leaflet
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

// Simple function to check if a point is inside a polygon using ray casting algorithm
function isPointInPolygon(point, polygon) {
  try {
    if (!polygon || !polygon.geometry || !polygon.geometry.coordinates || !polygon.geometry.coordinates[0]) {
      return true; // If no polygon is provided, assume the point is valid
    }
    
    const x = point.lng;
    const y = point.lat;
    
    // Get the coordinates of the polygon (first ring)
    const coords = polygon.geometry.coordinates[0];
    
    let inside = false;
    for (let i = 0, j = coords.length - 1; i < coords.length; j = i++) {
      const xi = coords[i][0], yi = coords[i][1];
      const xj = coords[j][0], yj = coords[j][1];
      
      const intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    
    return inside;
  } catch (error) {
    console.error('Error checking if point is in polygon:', error);
    return true; // In case of error, allow the point
  }
}

// Component to handle map clicks and set marker
function LocationMarker({ position, setPosition, projectBoundary }) {
  const map = useMapEvents({
    click(e) {
      // Check if the clicked position is within the project boundary
      if (projectBoundary && !isPointInPolygon(e.latlng, projectBoundary)) {
        toast.warning('Please select a location within the project boundary');
        return;
      }
      
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    }
  });

  return position ? <Marker position={position} /> : null;
}

// Component to recenter map when coordinates change
function MapUpdater({ center, zoom }) {
  const map = useMap();
  
  useEffect(() => {
    if (center && center[0] && center[1]) {
      map.flyTo(center, zoom, {
        duration: 1.5,
        easeLinearity: 0.5
      });
    }
  }, [center, zoom, map]);
  
  return null;
}

export default function SubmitNonBuilding() {
  const nonBuildingService = useNonBuildingService();
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLocating, setIsLocating] = useState(false);
  const [position, setPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]); // Default center
  const [projectBoundary, setProjectBoundary] = useState(null);
  const [formData, setFormData] = useState({
    category: 'GREEN',
    description: ''
  });
  const [popularHashtags, setPopularHashtags] = useState([
    'green', 'heritage', 'art', 'community', 'history', 'nature', 'culture', 'landmark'
  ]);
  const [error, setError] = useState(null);
  
  // Create a RequestService instance for direct API calls
  const requestService = useRequestService();

  // For debugging
  useEffect(() => {
    console.log("NonBuildingService:", nonBuildingService);
    if (nonBuildingService) {
      console.log("Methods:", Object.getOwnPropertyNames(Object.getPrototypeOf(nonBuildingService)));
      console.log("createNonBuildingFromForm:", nonBuildingService.createNonBuildingFromForm);
    }
    
    // Log the photo blob from location state for debugging
    if (location.state?.photoBlob) {
      console.log("Photo blob available:", location.state.photoBlob);
      console.log("Photo blob type:", location.state.photoBlob.type);
      console.log("Photo blob size:", location.state.photoBlob.size);
    } else {
      console.log("No photo blob in location state");
    }
  }, [nonBuildingService, location.state]);

  // Helper function to validate and format center coordinates
  const validateCenterCoordinates = (coords) => {
    if (!coords) return null;
    
    // Check if coords is in the format {lat: number, lng: number}
    if (typeof coords.lat === 'number' && typeof coords.lng === 'number') {
      return [coords.lat, coords.lng];
    }
    
    // Check if coords is already in the format [lat, lng]
    if (Array.isArray(coords) && coords.length === 2 && 
        typeof coords[0] === 'number' && typeof coords[1] === 'number') {
      return coords;
    }
    
    console.error('Invalid center coordinates format:', coords);
    return null;
  };

  // Function to get user's current location
  const getCurrentLocation = () => {
    setError(null);
    setIsLocating(true);
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          
          console.log("Current location detected:", newPosition);
          
          // Set map center to current location
          setMapCenter([newPosition.lat, newPosition.lng]);
          
          // Only set position marker if within project boundary
          if (projectBoundary && isPointInPolygon(newPosition, projectBoundary)) {
            setPosition(newPosition);
          } else if (!projectBoundary) {
            setPosition(newPosition);
          }
          
          setIsLocating(false);
          toast.success(t('capture.submitNonBuilding.locationDetected') || "Location detected successfully");
        },
        (error) => {
          console.error("Error detecting location:", error);
          setError("Could not detect your location. Please try again.");
          setIsLocating(false);
          toast.error(t('capture.submitNonBuilding.locationError') || "Could not detect your location");
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      setIsLocating(false);
      toast.error(t('capture.submitNonBuilding.geolocationNotSupported') || "Geolocation is not supported by your browser");
    }
  };

  // Fetch project boundary data and set initial location
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const projectData = await requestService.get(`projects/${projectId}`);
        console.log('Project data:', projectData);
        
        if (projectData) {
          // Parse regionGeoJson if it's a string
          let boundary = null;
          if (projectData.regionGeoJson) {
            try {
              boundary = typeof projectData.regionGeoJson === 'string' 
                ? JSON.parse(projectData.regionGeoJson) 
                : projectData.regionGeoJson;
              setProjectBoundary(boundary);
            } catch (error) {
              console.error('Error parsing regionGeoJson:', error);
            }
          }
          
          // Set map center using centerCoordinates if available
          if (projectData.centerCoordinates) {
            const validatedCenter = validateCenterCoordinates(projectData.centerCoordinates);
            if (validatedCenter) {
              console.log('Setting map center to project center:', validatedCenter);
              setMapCenter(validatedCenter);
            } else {
              console.warn('Invalid centerCoordinates in project data:', projectData.centerCoordinates);
              
              // Fallback to calculating center from boundary
              if (boundary && boundary.geometry && 
                  boundary.geometry.coordinates && 
                  boundary.geometry.coordinates.length > 0) {
                
                // For Polygon, calculate center from first ring
                const coords = boundary.geometry.coordinates[0];
                if (coords && coords.length > 0) {
                  // Calculate center of polygon
                  let lat = 0, lng = 0;
                  coords.forEach(coord => {
                    lng += coord[0];
                    lat += coord[1];
                  });
                  lat /= coords.length;
                  lng /= coords.length;
                  const newCenter = [lat, lng];
                  console.log('Calculated center from boundary:', newCenter);
                  setMapCenter(newCenter);
                }
              }
            }
          }
          
          // After setting project data, try to get user's current location
          getCurrentLocation();
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
        toast.error(t('Error loading project data'));
        
        // Even if project data fails, try to get user's location
        getCurrentLocation();
      }
    };

    fetchProjectData();
  }, [projectId, t]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleDescriptionChange = (e) => {
    setFormData(prevData => ({
      ...prevData,
      description: e.target.value
    }));
  };

  const detectLocation = () => {
    getCurrentLocation();
  };

  const submitNonBuilding = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      if (!position || typeof position.lng !== 'number' || typeof position.lat !== 'number') {
        throw new Error("Location coordinates are required. Please select a location on the map.");
      }
      
      // Validate that the position is within the project boundary
      if (projectBoundary && !isPointInPolygon(position, projectBoundary)) {
        throw new Error("Please select a location within the project boundary.");
      }

      const form = document.getElementById("nonbuilding-form");
      const blob = location.state?.photoBlob;
      
      // Log the blob for debugging
      console.log("Using photo blob:", blob);
      
      if (!blob) {
        console.warn("No photo blob available for upload");
      }
      
      // Add coordinates to hidden form fields
      let latitudeInput = form.querySelector('input[name="latitude"]');
      if (!latitudeInput) {
        latitudeInput = document.createElement('input');
        latitudeInput.type = 'hidden';
        latitudeInput.name = 'latitude';
        form.appendChild(latitudeInput);
      }
      latitudeInput.value = position.lat;
      
      let longitudeInput = form.querySelector('input[name="longitude"]');
      if (!longitudeInput) {
        longitudeInput = document.createElement('input');
        longitudeInput.type = 'hidden';
        longitudeInput.name = 'longitude';
        form.appendChild(longitudeInput);
      }
      longitudeInput.value = position.lng;

      try {
        // Create the request data object
        const requestData = {
          description: formData.description,
          category: formData.category,
          latitude: position.lat,
          longitude: position.lng
        };
        
        console.log("Submitting with data:", requestData);
        
        // Create FormData for the request
        const formDataObj = new FormData();
        
        // Add the request data as a JSON blob with the correct content type
        const jsonBlob = new Blob([JSON.stringify(requestData)], {
          type: 'application/json'
        });
        formDataObj.append('data', jsonBlob);
        
        // Add the photo if available - ensure it has the correct MIME type
        if (blob) {
          // If the blob doesn't have a type, create a new blob with the correct type
          const imageBlob = blob.type ? blob : new Blob([blob], { type: 'image/jpeg' });
          console.log("Appending image to form data:", imageBlob);
          formDataObj.append('image', imageBlob, 'image.jpg');
        }
        
        // Get the auth token from the auth service
        const authService = window.authService;
        if (!authService || !authService.isTokenValid()) {
          throw new Error('No valid authentication token found');
        }
        
        // Make the API call with proper headers
        const response = await fetch(`${requestService.baseAddress}projects/${projectId}/nonbuildings`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${authService.token.trim()}`
          },
          body: formDataObj,
          mode: 'cors',
          credentials: 'include'
        });
        
        // Log the response status and headers
        console.log('Response status:', response.status);
        console.log('Response headers:', Object.fromEntries(response.headers.entries()));
        
        // Check if the response is ok
        if (!response.ok) {
          const contentType = response.headers.get('content-type');
          let errorData;
          
          if (contentType && contentType.includes('application/json')) {
            errorData = await response.json();
          } else {
            const text = await response.text();
            console.error('Received non-JSON error response:', text);
            throw new Error('Server returned an error response');
          }
          
          throw new Error(errorData.error || "Failed to submit meaningful place");
        }
        
        // Parse the response as JSON
        const result = await response.json();
        console.log("Submission result:", result);
        
        if (!result.success) {
          throw new Error(result.error || "Failed to submit meaningful place");
        }
        
        // Navigate to success page
        navigate(`/project/${projectId}`, { 
          state: { dorple: result.nonBuilding }, 
          replace: true 
        });
      } catch (error) {
        console.error("Error from service:", error);
        throw error;
      }
    } catch (error) {
      console.error("Error submitting meaningful place:", error);
      setError(error.message || "An error occurred while submitting the meaningful place");
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen p-8 pb-24 overflow-y-auto">
      <div className="w-full max-w-md">
        <h2 className="text-xl font-semibold text-center mb-4">
          {t('capture.submitNonBuilding.title') || 'Submit Meaningful Place'}
        </h2>
        
        {location.state?.photoUrl && (
          <div className="mb-4 rounded-lg overflow-hidden border border-gray-300">
            <img 
              src={location.state.photoUrl} 
              alt="Meaningful Place" 
              className="w-full h-auto"
            />
          </div>
        )}
        
        {error && (
          <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
            {error}
          </div>
        )}
        
        <form 
          id="nonbuilding-form" 
          onSubmit={submitNonBuilding} 
          className="flex flex-col space-y-4"
        >
          <div>
            <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
              {t('capture.submitNonBuilding.categoryLabel') || 'Category'}
            </label>
            <select 
              id="category"
              name="category" 
              value={formData.category}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-sky-500 focus:border-sky-500"
              required
            >
              <option value="GREEN">{t('nonbuildings.category.green') || 'Green'}</option>
              <option value="HERITAGE">{t('nonbuildings.category.heritage') || 'Heritage'}</option>
              <option value="COMMUNITY">{t('nonbuildings.category.community') || 'Community'}</option>
              <option value="MOBILITY">{t('nonbuildings.category.mobility') || 'Mobility'}</option>
              <option value="ONTSPANNEN">{t('nonbuildings.category.ontspannen') || 'Ontspannen'}</option>
              <option value="CULTURE">{t('nonbuildings.category.culture') || 'Culture'}</option>
              <option value="OTHER">{t('nonbuildings.category.other') || 'Other'}</option>
            
            </select>
          </div>
          
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              {t('capture.submitNonBuilding.descriptionLabel') || 'Description'}
            </label>
            <HashtagInput
              id="description"
              name="description"
              value={formData.description}
              onChange={handleDescriptionChange}
              placeholder={t('capture.submitNonBuilding.descriptionPlaceholder') || 'Why is this place meaningful?'}
              rows={3}
              className="focus:ring-sky-500 focus:border-sky-500"
              popularHashtags={popularHashtags}
              showSuggestionButtons={true}
            />
          </div>
          
          {/* Map for Location Selection */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('capture.submitNonBuilding.location') || 'Location'} 
              {position && ` (${position.lat.toFixed(6)}, ${position.lng.toFixed(6)})`}
            </label>
            <div className="h-64 w-full border border-gray-300 rounded-md overflow-hidden">
              <MapContainer 
                center={mapCenter} 
                zoom={15} 
                style={{ height: '100%', width: '100%' }}
                className="z-0"
              >
                <MapUpdater center={mapCenter} zoom={15} />
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {projectBoundary && (
                  <GeoJSON 
                    data={projectBoundary} 
                    style={() => ({
                      color: '#3b82f6',
                      weight: 2,
                      fillOpacity: 0.1
                    })}
                  />
                )}
                <LocationMarker 
                  position={position} 
                  setPosition={setPosition} 
                  projectBoundary={projectBoundary}
                />
              </MapContainer>
            </div>
            <div className="flex justify-between items-center mt-1">
              <p className="text-sm text-gray-500">
                {t('capture.submitNonBuilding.mapInstructions') || 'Click on the map to select a location'}
              </p>
              <button
                type="button"
                onClick={detectLocation}
                disabled={isLocating}
                className="text-xs px-2 py-1 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              >
                {isLocating 
                  ? (t('capture.submitNonBuilding.locating') || 'Locating...') 
                  : (t('capture.submitNonBuilding.useMyLocation') || 'Use My Location')}
              </button>
            </div>
            {projectBoundary && (
              <p className="text-xs text-amber-600 mt-1">
                {t('capture.submitNonBuilding.boundaryNote') || 'Note: You can only select locations within the highlighted project boundary'}
              </p>
            )}
          </div>
          
          <button 
            type="submit"
            disabled={isSubmitting || !position || isLocating}
            className="px-4 py-2 bg-sky-600 text-white rounded-md hover:bg-sky-700 disabled:bg-sky-300"
          >
            {isSubmitting 
              ? (t('capture.submitNonBuilding.submitting') || 'Submitting...') 
              : (t('capture.submitNonBuilding.submit') || 'Submit Place')}
          </button>
        </form>
      </div>
    </div>
  );
}