import { useState } from 'react';
import RequestService from './RequestService';

export default class BuildingService extends RequestService {

  constructor() {
    super();
    // We're not appending "buildings/" to the baseAddress anymore
    // This was causing issues with project-specific endpoints
  }


  getGallery = async (page, imageWidth, imageHeight) => this.get("buildings/gallery", { page, imageWidth, imageHeight });
  getGallerymap = async page => this.get("buildings/gallerymap", { page });
  getImages = async id => this.get([`buildings/${id}`, "images"]);


  async createBuildingFromForm(form, imageBlob) {
    // Create a FormData object
    const formData = new FormData();
    
    // Add form fields
    Object.entries(form).forEach(([key, value]) => {
      if (value && value.value) {
        formData.append(key, value.value);
      }
    });
    
    // Add image if present
    if (imageBlob) {
      formData.append('image', imageBlob);
    }
    
    return this.postForm("buildings", formData);
  }
  
  /**
   * Finds buildings near the given coordinates within a specified radius
   * 
   * @param {string} projectId - The ID of the project
   * @param {number} latitude - The latitude coordinate
   * @param {number} longitude - The longitude coordinate
   * @param {number} radius - The search radius in meters (default: 10)
   * @returns {Promise<Object>} - The result from the API containing nearby buildings
   */
  async findBuildingsNearCoordinates(projectId, latitude, longitude, radius = 10) {
    if (!projectId) {
      throw new Error("Project ID is required");
    }
    
    if (typeof latitude !== 'number' || isNaN(latitude)) {
      throw new Error("Valid latitude is required");
    }
    
    if (typeof longitude !== 'number' || isNaN(longitude)) {
      throw new Error("Valid longitude is required");
    }
    
    try {
      // Use the correct endpoint for the project's nearby buildings
      const endpoint = `projects/${projectId}/buildings/nearby`;
      
      // Call the get method from the parent RequestService class
      const result = await this.get(endpoint, {
        latitude,
        longitude,
        radius
      });
      
      if (result === null || result === undefined) {
        throw new Error("No data received from API");
      }
      
      // The backend now returns NearbyBuildingDTO objects, so we need to extract the building data
      if (Array.isArray(result)) {
        const processedResults = result.map(dto => {
          // If it's already a building object (direct-submission), return it
          if (!dto.building) {
            return dto;
          }
          
          // Otherwise, extract the building and add the distance
          const building = dto.building;
          building.distance = dto.distance;
          return building;
        });
        
        return processedResults;
      }
      
      return result;
    } catch (error) {
      // If the endpoint doesn't exist, create a mock building with the provided coordinates
      const mockBuilding = {
        id: "direct-submission",
        name: "Direct Submission",
        address: `Coordinates: ${latitude.toFixed(6)}, ${longitude.toFixed(6)}`,
        latitude: latitude,
        longitude: longitude,
        distance: 0
      };
      
      return [mockBuilding];
    }
  }
  
  /**
   * Adds an image to a building or creates a new building at the specified coordinates.
   * The building will be set as verified and published.
   * 
   * @param {string} projectId - The ID of the project
   * @param {Object} buildingData - Object containing coordinates, verified and publish flags, and buildingId
   * @param {Blob} imageBlob - The image blob to add to the building
   * @returns {Promise<Object>} - The result from the API containing the updated building
   */
  async addImageToNearestBuilding(projectId, buildingData, imageBlob) {
    try {
      // Use the same endpoint for both direct submissions and existing buildings
      const endpoint = `projects/${projectId}/building`;
      
      // Create a FormData object
      const formData = new FormData();
      
      // Add building data
      Object.entries(buildingData).forEach(([key, value]) => {
        formData.append(key, String(value));
      });
      
      // Add image if present
      if (imageBlob) {
        formData.append('image', imageBlob);
      }
      
      // Use the postForm method
      return this.postForm(endpoint, formData);
    } catch (error) {
      throw error;
    }
  }

}


/**
 * 
 * @returns {BuildingService} Building service.
 */
export function useBuildingService() {
  const [buildingService] = useState(new BuildingService());
  return buildingService;
}