import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useRequestService } from '../services/RequestService';
import DorpleLogo from '../images/logo-large.svg';

function RequestPasswordResetPage() {
  const { t } = useTranslation();
  const { trackPageView } = useMatomo();
  const navigate = useNavigate();
  const requestService = useRequestService();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    trackPageView();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    
    try {
      console.log('Attempting password reset for email:', email);
      
      const authService = requestService.getAuthService();
      await authService.requestPasswordReset(email);
      console.log('Password reset request successful');
      setSuccess(true);
    } catch (err) {
      console.error('Password reset request error:', {
        status: err.response?.status,
        statusText: err.response?.statusText,
        data: err.response?.data,
        message: err.message,
        error: err
      });

      if (err.response?.data?.message) {
        // Use server's error message if available
        setError(err.response.data.message);
      } else if (err.response) {
        // Log the response data to help with debugging
        console.error('Server response:', err.response.data);
        
        switch (err.response.status) {
          case 404:
            setError(t('password_reset.request.email_not_found'));
            break;
          case 429:
            setError(t('password_reset.request.too_many_requests'));
            break;
          case 400:
            setError(t('password_reset.request.invalid_email'));
            break;
          case 500:
            setError(t('common.server_error'));
            break;
          default:
            setError(t('password_reset.request.error'));
        }
      } else if (err.request) {
        console.error('Network error:', err.request);
        setError(t('common.network_error'));
      } else {
        setError(t('password_reset.request.error'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (

      <div className="flex flex-col vh-100">

        <div className="items-center justify-center">
        <Link
                  to={`/login`}
         id="logo" className="flex flex-col justify-center items-center w-full pb-2 mb-3">
          <img className="bg-white bg-opacity-75 border-sky-800 w-14 h-14 max-w-14 border rounded-full m-2" src={DorpleLogo} alt="Dorple Logo" />
          <h1 className="text-6xl text-sky-800 apptitle font-bold">Dorple</h1>
          <p className="text-2xl text-sky-800 appsubtitle font-bold">{t('home.subtitle')}</p>
        </Link>

        <div className="border max-w-md rounded-lg shadow-md p-4">
          {!success ? (
            <form onSubmit={handleSubmit}>
              <h2 className="text-xl font-bold mb-4 text-center">{t('password_reset.request.title')}</h2>
              <p className="text-sm text-gray-600 mb-4">{t('password_reset.request.description')}</p>
              
              {error && <p className="text-red-600 text-sm mb-4">{error}</p>}
              
              <input
                className="border appearance-none rounded w-full p-2 text-gray-700 leading-tight focus:outline-none bg-white border-gray-300 focus:bg-sky-50 focus:border-sky-700"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('login.email')}
                required
              />
              
              <button
                type="submit"
                disabled={isLoading}
                className="w-full mt-4 shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-md p-2 rounded disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? t('common.loading') : t('password_reset.request.submit')}
              </button>
            </form>
          ) : (
            <div className="text-center">
              <p className="text-green-600 mb-4">{t('password_reset.request.success')}</p>
              <button
                onClick={() => navigate('/login')}
                className="text-sky-600 hover:underline"
              >
                {t('login.button')}
              </button>
            </div>
          )}
        </div>



      </div>
    </div>

  );
}

export default RequestPasswordResetPage; 