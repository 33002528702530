import React from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function PhotoPreview() {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { t } = useTranslation();
  
  // Extract the type from location state
  const captureType = location.state?.type || 'building';

  console.log("state", location.state);

  const handleNext = () => {
    // Navigate based on the type
    if (captureType === 'building') {
      navigate(`/project/${projectId}/submit-building`, { 
        state: location.state, 
        replace: true 
      });
    } else {
      navigate(`/project/${projectId}/submit-nonbuilding`, { 
        state: location.state, 
        replace: true 
      });
    }
  };

  const handleRetake = () => {
    // Preserve the type parameter when going back to capture
    navigate(`/project/${projectId}/capture?type=${captureType}`, { replace: true });
  };

  // Determine the title based on the type
  const getTitle = () => {
    if (captureType === 'building') {
      return t('capture.preview.buildingTitle') || 'Preview of your building';
    } else {
      return t('capture.preview.nonBuildingTitle') || 'Preview of your meaningful place';
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-full p-4">
      <div className="w-full max-w-md">
        <h2 className="text-xl font-semibold text-center mb-4">{getTitle()}</h2>
        
        <div className="mb-4 rounded-lg overflow-hidden border border-gray-300">
          {location.state?.photoUrl ? (
            <img 
              src={location.state.photoUrl} 
              alt="Preview" 
              className="w-full h-auto"
            />
          ) : (
            <div className="bg-gray-200 h-64 flex items-center justify-center">
              <p className="text-gray-500">{t('capture.preview.noPhoto') || 'No photo available'}</p>
            </div>
          )}
        </div>
        
        <div className="flex justify-between">
          <button 
            onClick={handleRetake}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
          >
            {t('capture.preview.retake') || 'Retake'}
          </button>
          
          <button 
            onClick={handleNext}
            className="px-4 py-2 bg-sky-600 text-white rounded-md hover:bg-sky-700"
          >
            {t('capture.preview.next') || 'Continue'}
          </button>
        </div>
      </div>
    </div>
  );
}