import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ProjectGalleryEmpty({ projectId }) {
  const {t} = useTranslation();

  return (
    <div style={{ textAlign: 'center', padding: '20px' }} className="flex flex-col items-center justify-center">
        
        <h2 className="text-xl font-bold text-sky-800 mb-2 ">{t("gallery.empty")}</h2>          
        <p className="text-lg text-sky-800">{t("gallery.swipeToFill")}</p>

    </div>
  );
}