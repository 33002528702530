import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { TiTick, TiTimes } from "react-icons/ti";
import BuildingComments from '../components/BuildingComments';
import { useRequestService } from '../services/RequestService';

function ProjectBuildingDetailPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [buildingState, setBuildingState] = useState(location.state?.building);
  const requestService = useRequestService();

  const handleScore = async (liked) => {
    try {
      // If clicking the same score, remove it
      if ((liked && buildingState.userScore === true) || (!liked && buildingState.userScore === false)) {
        setBuildingState(prev => ({ ...prev, userScore: null }));
      } else {
        // Set new score
        setBuildingState(prev => ({ ...prev, userScore: liked }));
      }

      // Make API call to update score
      await requestService.post('scores', {
        id: buildingState.id,
        type: 'BUILDING',
        scoring: liked ? '1' : '0'
      });
    } catch (error) {
      console.error('Error updating score:', error);
      // Revert state on error
      setBuildingState(prev => ({ ...prev, userScore: location.state?.building.userScore }));
    }
  };

  return (
    <div id="building-detail-container" className='flex flex-col h-screen overflow-auto pb-24'>
        <div className="h-12 w-full flex items-start justify-left p-2 ">
          <button
           onClick={() => navigate(-1)}
          className='p-2 hover:bg-gray-100 rounded-full border border-gray-300 w-10 h-10'
          type='button'>
                        <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              >
                <path d="M19 12H5M12 19l-7-7 7-7"/>
              </svg>
          </button>
        </div>

        <div className="flex items-center justify-center">
          <div className="relative vh-64 hw-64">
            <div
                   style={buildingState.imageLink && buildingState.imageLink.length > 0 ? { 
                    backgroundImage: `url(${buildingState.imageLink})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  } : {
                    backgroundColor: 'rgb(241 245 249)' // bg-sky-50
                  }}
                    className='card border grid grid-cols-1 gap-0 content-end'
                  >
                      <div className="flex flex-row  align-middle m-2 bg-white opacity-75 rounded-md">
                        <div className="m-1">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeLinejoin="1.5" stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
                          </svg>
                        </div>
                        <div className="m-1 font-bold text-sm">{buildingState.name}</div>
                      </div>

                      <div className="flex flex-row align-middle items-start ml-2 mb-2">
                          <button 
                            onClick={() => handleScore(false)}
                            className={`flex flex-row items-start align-middle mr-3 cursor-pointer`}>
                            <div className={`rounded-full ${buildingState.userScore === false ? 'bg-red-600' : 'bg-gray-300'} text-white m-1 p-1`}>
                              <TiTimes className="w-7 h-7" />
                            </div>
                          </button>
                          <button 
                            onClick={() => handleScore(true)}
                            className={`flex flex-row items-start align-middle cursor-pointer`}>
                            <div className={`rounded-full ${buildingState.userScore === true ? 'bg-green-600' : 'bg-gray-300'} text-white m-1 p-1`}>
                              <TiTick className="w-7 h-7" />
                            </div>
                          </button>
                      </div>
          </div>
        </div>
      </div>

      <BuildingComments buildingId={buildingState.id} />
    </div>
  );
}

export default ProjectBuildingDetailPage;    