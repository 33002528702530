import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { TiTick, TiTimes } from "react-icons/ti";
import ProjectIdeaComments from '../components/ProjectIdeaComments';
import { MapContainer, TileLayer } from 'react-leaflet';
import CustomMarker from "../components/CustomMarker";
import { useTranslation } from 'react-i18next';
import { useRequestService } from '../services/RequestService';
import "leaflet/dist/leaflet.css";

function ProjectIdeaDetailPage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [projectIdeaState, setProjectIdeaState] = useState(location.state?.projectIdea);
  const requestService = useRequestService();

  const handleScore = async (liked) => {
    try {
      // If clicking the same score, remove it
      if ((liked && projectIdeaState.userScore === true) || (!liked && projectIdeaState.userScore === false)) {
        setProjectIdeaState(prev => ({ ...prev, userScore: null }));
      } else {
        // Set new score
        setProjectIdeaState(prev => ({ ...prev, userScore: liked }));
      }

      // Make API call to update score
      await requestService.post('scores', {
        id: projectIdeaState.id,
        type: 'PROJECT_IDEA',
        scoring: liked ? '1' : '0'
      });
    } catch (error) {
      console.error('Error updating score:', error);
      // Revert state on error
      setProjectIdeaState(prev => ({ ...prev, userScore: location.state?.projectIdea.userScore }));
    }
  };

  return (
    <div id="project-idea-detail-container" className='flex flex-col h-screen overflow-auto pb-24'>
        <div className="h-12 w-full flex items-start justify-left p-2">
          <button
            onClick={() => navigate(-1)}
            className='p-2 hover:bg-gray-100 rounded-full border border-gray-300 w-10 h-10'
            type='button'>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M19 12H5M12 19l-7-7 7-7"/>
            </svg>
          </button>
        </div>

        <div className="flex items-center justify-center px-4">
          <div className="border border-gray-200 rounded-md w-full max-w-md flex flex-col">
            <div
                dangerouslySetInnerHTML={{ __html: projectIdeaState.imageSrc }}
                className="w-full"
              />

            {/* Location Map */}
            {projectIdeaState.latitude && projectIdeaState.longitude && (
              <div className="pb-4">
              
                <div className="h-64 w-fulld overflow-hidden border-t border-gray-200">
                  <MapContainer 
                    center={[projectIdeaState.latitude, projectIdeaState.longitude]} 
                    zoom={15} 
                    scrollWheelZoom={false}
                    style={{ height: '100%', width: '100%' }}
                    className="z-0"
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <CustomMarker 
                      position={[projectIdeaState.latitude, projectIdeaState.longitude]}
                      scoreY={projectIdeaState.scorey || 0}
                      scoreN={projectIdeaState.scoren || 0}
                    />
                  </MapContainer>
                </div>
              </div>
            )}

            {/* Likes/Dislikes */}
            <div className="flex flex-row items-start px-4 py-2 mb-2">
              <div className="flex flex-row align-middle items-start ml-2 mb-2">
                  <button 
                    onClick={() => handleScore(false)}
                    className={`flex flex-row items-start align-middle mr-3 cursor-pointer`}>
                    <div className={`rounded-full ${projectIdeaState.userScore === false ? 'bg-red-600' : 'bg-gray-300'} text-white m-1 p-1`}>
                      <TiTimes className="w-7 h-7" />
                    </div>
                  </button>
                  <button 
                    onClick={() => handleScore(true)}
                    className={`flex flex-row items-start align-middle cursor-pointer`}>
                    <div className={`rounded-full ${projectIdeaState.userScore === true ? 'bg-green-600' : 'bg-gray-300'} text-white m-1 p-1`}>
                      <TiTick className="w-7 h-7" />
                    </div>
                  </button>
              </div>
            </div>
          </div>
        </div>

        {/* Comments section */}
        <ProjectIdeaComments projectIdeaId={projectIdeaState.id} />
    </div>
  );
}

export default ProjectIdeaDetailPage;    