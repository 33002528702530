import { useState } from 'react';
import RequestService from './RequestService';

// Import AuthenticationService lazily to avoid circular dependency
let AuthService;

export default class AccountService extends RequestService {

  constructor() {
    super("account");
    this._authService = null;
  }

  get authService() {
    if (!this._authService) {
      if (!AuthService) {
        AuthService = require('./AuthenticationService').default;
      }
      this._authService = new AuthService();
    }
    return this._authService;
  }

  isLoggedIn() {
    return this.authService.isTokenValid();
  }

  getNameFromUser = user => user.name;
  
  getStats = async () => {
    try {
      // Check if user is logged in before making the request
      if (!this.isLoggedIn()) {
        console.warn('User is not logged in, returning default stats');
        return {
          totalVotes: 0,
          totalLikes: 0,
          totalDislikes: 0
        };
      }
      
      return await this.get("account/stats");
    } catch (error) {
      console.error("Error fetching stats:", error);
      // Return default stats object if the endpoint is not available
      return {
        totalVotes: 0,
        totalLikes: 0,
        totalDislikes: 0
      };
    }
  };
  
  getUserName = async () => {
    try {
      if (!this.isLoggedIn()) {
        console.warn('User is not logged in, cannot get username');
        return null;
      }
      return await this.get('name').then(this.getNameFromUser);
    } catch (error) {
      console.error("Error fetching username:", error);
      return null;
    }
  };
  
  setUserName = async name => this.put('name', { name }).then(this.getNameFromUser);
}

// Create a singleton instance
const accountServiceInstance = new AccountService();

export function useAccountService() {
  return accountServiceInstance;
}