import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, GeoJSON, useMapEvents, Marker, useMap } from 'react-leaflet';
import { useRequestService } from '../services/RequestService';
import { toast } from 'react-toastify';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import HashtagInput from '../components/HashtagInput';

// Fix for default marker icon in Leaflet
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

// Simple function to check if a point is inside a polygon using ray casting algorithm
function isPointInPolygon(point, polygon) {
  try {
    if (!polygon || !polygon.geometry || !polygon.geometry.coordinates || !polygon.geometry.coordinates[0]) {
      return true; // If no polygon is provided, assume the point is valid
    }
    
    const x = point.lng;
    const y = point.lat;
    
    // Get the coordinates of the polygon (first ring)
    const coords = polygon.geometry.coordinates[0];
    
    let inside = false;
    for (let i = 0, j = coords.length - 1; i < coords.length; j = i++) {
      const xi = coords[i][0], yi = coords[i][1];
      const xj = coords[j][0], yj = coords[j][1];
      
      const intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    
    return inside;
  } catch (error) {
    console.error('Error checking if point is in polygon:', error);
    return true; // In case of error, allow the point
  }
}

// Component to handle map clicks and set marker
function LocationMarker({ position, setPosition, projectBoundary }) {
  const map = useMapEvents({
    click(e) {
      // Check if the clicked position is within the project boundary
      if (projectBoundary && !isPointInPolygon(e.latlng, projectBoundary)) {
        toast.warning('Please select a location within the project boundary');
        return;
      }
      
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    }
  });

  return position ? <Marker position={position} /> : null;
}

// Component to recenter map when coordinates change
function MapUpdater({ center, zoom }) {
  const map = useMap();
  
  // Set view when component mounts
  useEffect(() => {
    if (center && center[0] && center[1]) {
      console.log('Initial map center set to:', center);
      map.setView(center, zoom);
    }
  }, []);
  
  // Update view when center changes
  useEffect(() => {
    if (center && center[0] && center[1]) {
      console.log('Updating map center to:', center);
      map.flyTo(center, zoom, {
        duration: 1.5, // Animation duration in seconds
        easeLinearity: 0.5
      });
    }
  }, [center, zoom, map]);
  
  return null;
}

// Debug component to display current map center
function MapDebug() {
  const map = useMap();
  const [center, setCenter] = useState(map.getCenter());
  
  useEffect(() => {
    const updateCenter = () => {
      setCenter(map.getCenter());
    };
    
    map.on('move', updateCenter);
    return () => {
      map.off('move', updateCenter);
    };
  }, [map]);
  
  return (
    <div style={{
      position: 'absolute',
      bottom: '10px',
      left: '10px',
      zIndex: 1000,
      backgroundColor: 'white',
      padding: '5px',
      borderRadius: '5px',
      fontSize: '12px',
      boxShadow: '0 0 5px rgba(0,0,0,0.3)'
    }}>
      Center: [{center.lat.toFixed(6)}, {center.lng.toFixed(6)}]
    </div>
  );
}

function ProjectAddIdeaPage() {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const requestService = useRequestService();
  const [loading, setLoading] = useState(false);
  const [projectBoundary, setProjectBoundary] = useState(null);
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]); // Default center
  const [position, setPosition] = useState(null);
  const [formData, setFormData] = useState({
    description: '',
    category: 'MOBILITY', // Default category
    coordinates: null
  });
  const [popularHashtags, setPopularHashtags] = useState([
    'mobility', 'green', 'relaxation', 'community', 'infrastructure', 'sustainable', 'urban'
  ]);
  
  // Log when mapCenter changes
  useEffect(() => {
    console.log('mapCenter updated:', mapCenter);
  }, [mapCenter]);

  // Helper function to validate and format center coordinates
  const validateCenterCoordinates = (coords) => {
    if (!coords) return null;
    
    // Check if coords is in the format {lat: number, lng: number}
    if (typeof coords.lat === 'number' && typeof coords.lng === 'number') {
      return [coords.lat, coords.lng];
    }
    
    // Check if coords is already in the format [lat, lng]
    if (Array.isArray(coords) && coords.length === 2 && 
        typeof coords[0] === 'number' && typeof coords[1] === 'number') {
      return coords;
    }
    
    console.error('Invalid center coordinates format:', coords);
    return null;
  };

  // Fetch project boundary data
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const projectData = await requestService.get(`projects/${projectId}`);
        console.log('Project data:', projectData);
        
        if (projectData) {
          // Parse regionGeoJson if it's a string
          let boundary = null;
          if (projectData.regionGeoJson) {
            try {
              boundary = typeof projectData.regionGeoJson === 'string' 
                ? JSON.parse(projectData.regionGeoJson) 
                : projectData.regionGeoJson;
              setProjectBoundary(boundary);
            } catch (error) {
              console.error('Error parsing regionGeoJson:', error);
            }
          }
          
          // Set map center using centerCoordinates if available
          if (projectData.centerCoordinates) {
            const validatedCenter = validateCenterCoordinates(projectData.centerCoordinates);
            if (validatedCenter) {
              console.log('Setting map center to:', validatedCenter);
              setMapCenter(validatedCenter);
            } else {
              console.warn('Invalid centerCoordinates in project data:', projectData.centerCoordinates);
              
              // Fallback to calculating center from boundary
              if (boundary && boundary.geometry && 
                  boundary.geometry.coordinates && 
                  boundary.geometry.coordinates.length > 0) {
                
                // For Polygon, calculate center from first ring
                const coords = boundary.geometry.coordinates[0];
                if (coords && coords.length > 0) {
                  // Calculate center of polygon
                  let lat = 0, lng = 0;
                  coords.forEach(coord => {
                    lng += coord[0];
                    lat += coord[1];
                  });
                  lat /= coords.length;
                  lng /= coords.length;
                  const newCenter = [lat, lng];
                  console.log('Calculated center from boundary:', newCenter);
                  setMapCenter(newCenter);
                }
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
        toast.error(t('Error loading project data'));
      }
    };

    fetchProjectData();
  }, [projectId, requestService, t]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDescriptionChange = (e) => {
    setFormData({
      ...formData,
      description: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.description.trim()) {
      toast.error(t('Please enter a description'));
      return;
    }
    
    if (!position || typeof position.lng !== 'number' || typeof position.lat !== 'number') {
      toast.error(t('Please select a valid location on the map'));
      return;
    }
    
    // Validate that the position is within the project boundary
    if (projectBoundary && !isPointInPolygon(position, projectBoundary)) {
      toast.error(t('Please select a location within the project boundary'));
      return;
    }
    
    setLoading(true);
    
    try {
      // Format coordinates correctly for the backend
      const ideaData = {
        description: formData.description,
        category: formData.category,
        coordinates: {
          longitude: position.lng,
          latitude: position.lat
        }
      };
      
      console.log('Sending project idea data:', ideaData);
      
      await requestService.post(`project-ideas/project/${projectId}`, ideaData);
      toast.success(t('Project idea added successfully'));
      navigate(`/project/${projectId}`);
    } catch (error) {
      console.error('Error adding project idea:', error);
      toast.error(t('Error adding project idea: ' + (error.message || 'Unknown error')));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen pb-24 overflow-y-auto">
      {/* Header */}
      <div className="h-12 w-full flex items-start justify-left p-2">
        <button
          onClick={() => navigate(-1)}
          className="p-2 hover:bg-gray-100 rounded-full border border-gray-300 w-10 h-10"
          type="button"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d="M19 12H5M12 19l-7-7 7-7"/>
          </svg>
        </button>
        <h1 className="text-xl font-semibold ml-4 mt-1">{t('Add Project Idea')}</h1>
      </div>

      {/* Form */}
      <div className="flex-1 p-4">
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto">
          {/* Category Selection */}
          <div className="mb-4">
            <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
              {t('capture.submitProjectIdea.category')}
            </label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              required
            >
              <option value="GREEN">{t('capture.submitProjectIdea.categoryOptions.GREEN')}</option>
              <option value="HERITAGE">{t('capture.submitProjectIdea.categoryOptions.HERITAGE')}</option>
              <option value="COMMUNITY">{t('capture.submitProjectIdea.categoryOptions.COMMUNITY')}</option>
              <option value="MOBILITY">{t('capture.submitProjectIdea.categoryOptions.MOBILITY')}</option>
              <option value="ONTSPANNEN">{t('capture.submitProjectIdea.categoryOptions.ONTSPANNEN')}</option>
              <option value="CULTURE">{t('capture.submitProjectIdea.categoryOptions.CULTURE')}</option>
              <option value="OTHER">{t('capture.submitProjectIdea.categoryOptions.OTHER')}</option>
            </select>
          </div>

          {/* Description */}
          <div className="mb-4">
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              {t('capture.submitProjectIdea.description')}
            </label>
            <HashtagInput
              id="description"
              name="description"
              value={formData.description}
              onChange={handleDescriptionChange}
              placeholder={t('capture.submitProjectIdea.descriptionPlaceholder')}
              rows={4}
              className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              popularHashtags={popularHashtags}
              showSuggestionButtons={true}
            />
          </div>

          {/* Map for Location Selection */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('capture.submitProjectIdea.location')} {position && `(${position.lat.toFixed(6)}, ${position.lng.toFixed(6)})`}
            </label>
            <div className="h-64 w-full border border-gray-300 rounded-md overflow-hidden">
              {/* Only render MapContainer when we have valid coordinates */}
              {mapCenter && mapCenter[0] && mapCenter[1] && (
                <MapContainer 
                  key={`map-${mapCenter[0]}-${mapCenter[1]}`} // Force re-render when center changes
                  center={mapCenter} 
                  zoom={15} 
                  style={{ height: '100%', width: '100%' }}
                  className="z-0"
                >
                  <MapUpdater center={mapCenter} zoom={15} />
                  <MapDebug />
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {projectBoundary && (
                    <GeoJSON 
                      data={projectBoundary} 
                      style={() => ({
                        color: '#3b82f6',
                        weight: 2,
                        fillOpacity: 0.1
                      })}
                    />
                  )}
                  <LocationMarker 
                    position={position} 
                    setPosition={setPosition} 
                    projectBoundary={projectBoundary}
                  />
                </MapContainer>
              )}
            </div>
            <p className="text-sm text-gray-500 mt-1">
              {t('capture.submitProjectIdea.mapInstructions')}
            </p>
          </div>

          {/* Submit Button */}
          <div className="mt-6">
            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sky-700  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
            >
              {loading ? t('Adding...') : t('capture.submitProjectIdea.button')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProjectAddIdeaPage; 