import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { SwipeCard } from "../components/SwipeCard";
import ProjectSwipeCongrats from '../components/ProjectSwipeCongrats';
import { useRequestService } from "../services/RequestService";
import { Puff } from "react-loader-spinner";
import { useTranslation } from 'react-i18next';
import { useAccountService } from '../services/AccountService';
import { useImageService } from '../services/ImageService';
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from '@tanstack/react-query';

// import { LazyLoadImage } from 'react-lazy-load-image-component';
function ProjectSwipe() {
  const { projectId } = useParams();
  const { trackPageView, trackEvent } = useMatomo()
  const { t } = useTranslation();
  const [dorples, setDorples] = useState([]);
  const [reversedDorples, setReversedDorples] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [showcardContainer, setShowcardContainer] = useState(true);
  const requestService = useRequestService();
  const accountService = useAccountService();
  const imageService = useImageService();
  const queryClient = useQueryClient();
  const [imageWidth] = useState(300);
  const [imageHeight] = useState(400);

  const currentIndexRef = useRef(currentIndex);
  const childRefs = useMemo(() => Array(dorples.length).fill(0).map(() => React.createRef()), [dorples]);

  // Use React Query to fetch and cache buildings data
  const { data: buildingsData, isLoading, refetch } = useQuery({
    queryKey: ['buildings', projectId],
    queryFn: async () => {
      const response = await requestService.get(`projects/${projectId}/swipe`);
      if (response && response.length > 0) {
        // Filter out items without IDs to prevent errors
        const validItems = response.filter(item => item && item.id);
        
        if (validItems.length > 0) {
          setDorples(validItems);
          setReversedDorples([...validItems].reverse());
          setCurrentIndex(validItems.length - 1);
          setShowcardContainer(true);
        } else {
          console.warn('No valid items with IDs found in the response');
          setDorples([]);
          setReversedDorples([]);
          setCurrentIndex(-1);
          setShowcardContainer(true);
        }
      } else {
        setDorples([]);
        setReversedDorples([]);
        setCurrentIndex(-1);
        setShowcardContainer(true);
      }
      return response;
    },
    staleTime: 0, // Consider data immediately stale
    cacheTime: 0, // Don't cache the data
    refetchOnMount: true, // Always refetch when component mounts
    refetchOnWindowFocus: true, // Refetch when window regains focus
    refetchOnReconnect: true, // Refetch when reconnecting
    refetchInterval: 0, // Don't refetch periodically
    enabled: !!projectId, // Only run the query if projectId exists
    retry: 3, // Retry failed requests 3 times
    retryDelay: 1000, // Wait 1 second between retries
  });

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  const handleSwipeLocalChange = (isLocal) => {
    // Use React Query's refetch instead of making a direct request
    refetch();
    trackEvent({ category: 'swipe-page', action: 'changelocale' });
  };

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  const sendScore = (buildingid, buildingtype, direction) => {
    // Check if buildingid and buildingtype are valid
    if (!buildingid || !buildingtype) {
      console.error(`Cannot send score: Missing required parameters. ID: ${buildingid}, Type: ${buildingtype}`);
      return;
    }
    
    let score = direction === 'left' ? '0' : '1';
    console.log(`id: ${buildingid} Type: ${buildingtype} Score: ${score}`);

    try {
      requestService.post('scores', {id: buildingid, type: buildingtype, scoring: score })
        .then(data => console.log("Score sent successfully:", data))
        .catch(error => console.error("Error sending score:", error));
    } catch (ex) {
      console.error(ex);
    }
    trackEvent({ category: 'swipe-page', action: 'sendscore' });
  };

  const canGoBack = currentIndex < dorples.length - 1;
  const canSwipe = currentIndex >= 0;

  const swiped = (direction, buildingid, nameToDelete, buildingtype, index, currentIndex) => {
    // Only send score if buildingid and buildingtype are valid
    if (buildingid && buildingtype) {
      sendScore(buildingid, buildingtype, direction);
    } else {
      console.warn('Skipping score submission for item with missing ID or type');
    }
    
    updateCurrentIndex(index - 1);
    if (currentIndexRef.current === -1) {
      setShowcardContainer(!showcardContainer)
    }
  };

  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current, childRefs[idx].current);
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();
  };

  const swipe = async (dir) => {
    try {
      if (canSwipe && currentIndex < dorples.length) {
        // Ensure the current item has a valid reference
        if (childRefs[currentIndex] && childRefs[currentIndex].current) {
          await childRefs[currentIndex].current.swipe(dir);
          console.log(`${dir}) async dir!`);
        } else {
          console.warn(`Cannot swipe: Invalid reference for item at index ${currentIndex}`);
          // Move to the next item if the current one is invalid
          updateCurrentIndex(currentIndex - 1);
        }
      }
    } catch (error) {
      console.error('Error during swipe operation:', error);
      // Move to the next item if there was an error
      updateCurrentIndex(currentIndex - 1);
    }
  };

  const goBack = async () => {
    if (!canGoBack) return;
    const newIndex = currentIndex + 1;
    updateCurrentIndex(newIndex);
    if (childRefs[newIndex]?.current) {
      await childRefs[newIndex].current.restoreCard();
    }
  };

  // Add this component for the break message
  const NoMoreBuildings = () => {
    const { t } = useTranslation();
    
    return (
      <div className="flex flex-col items-center justify-center h-[calc(100vh-20vh)] p-4 text-center">
        <div className="mb-8">
          <svg className="w-12 h-12 mx-auto text-sky-800 animate-pulse" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
          </svg>
        </div>
        <h2 className="text-2xl font-bold text-sky-800 mb-4">
          {t('projectswipe.noMoreBuildings.title')}
        </h2>
        <p className="text-sky-800 mb-8">
          {t('projectswipe.noMoreBuildings.message')}
        </p>
        <div className="flex flex-col space-y-4">
          <Link 
            to={`/project/${projectId}/conversation`} 
            className="shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-lg px-6 py-2 rounded"
          >
            {t('projectswipe.noMoreBuildings.toConversation')}
          </Link>
        </div>
      </div>
    );
  };

  const renderCardContent = (item) => {
    // Check if item is valid
    if (!item) {
      console.warn('Attempting to render null or undefined item');
      return (
        <div className="border grid grid-cols-1 gap-0 content-end bg-gray-200">
          <div className="flex flex-row align-middle m-2 bg-white opacity-75 rounded-md">
            <div className="m-1 font-bold text-sm">{t('projectswipe.invalidItem')}</div>
          </div>
        </div>
      );
    }

    if (item.type === 'BUILDING') {
      // Modify the imageLink to include width and height parameters

      return (
        <>
          <div
            style={{ 
              backgroundImage: `url(${  imageService.getSrcForBuilding(item.id, imageWidth, imageHeight) || item.imageLink})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center'
            }}
            className='border grid grid-cols-1 gap-0 content-end w-full h-full bg-white'
          >
            <div className="flex flex-row align-middle m-2 bg-white opacity-75 rounded-md">
              <div className="m-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeLinejoin="1.5" stroke="currentColor" className="size-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819" />
                </svg>
              </div>
              <div className="m-1 font-bold text-sm">{item.name || t('projectswipe.unnamed')}</div>
            </div>

          </div>
        </>
      );
    } else if (item.type === 'SWIPE_QUESTION') {
      return (
        <div className='border grid grid-cols-1 gap-0 content-center'>
          <div 
            className="flex justify-center items-center p-0 h-full"
            dangerouslySetInnerHTML={{ __html: item.imageSrc }}
          />
        </div>
      );
    }
  };

  return (
    <div className="h-100vh">
      {showcardContainer ? (
        <>
          <div className="h-[calc(100vh-30vh)]" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            {isLoading ? (
              <div className="flex justify-center items-center">
                <Puff color="#0C4A6E" height={100} width={100} />
              </div>
            ) : dorples && dorples.length > 0 ? (
              <div className='card-container mt-4 mb-4 max-h-[calc(100vh-20vh)] h-[calc(100vh-20vh)] flex justify-center items-center'>
                {dorples.map((item, index) => (
                  <SwipeCard
                    ref={childRefs[index]}
                    className='swipe'
                    key={item.id}
                    onSwipe={(dir) => swiped(dir, item.id, item.name, item.type, index, currentIndex)}
                    onCardLeftScreen={() => outOfFrame(item.name, index)}
                    preventSwipe={['up', 'down']}
                  >
                    {renderCardContent(item)}
                  </SwipeCard>
                ))}
              </div>
            ) : (
              <NoMoreBuildings />
            )}

            {dorples && dorples.length > 0 ? (
            <div>
            <div className='buttons grid grid-flow-col justify-evenly'>
              <div>
                <button 
                  className={`rounded-full ${canSwipe ? 'bg-red-600' : 'bg-gray-300'}`} 
                  onClick={() => swipe('left')}
                  disabled={!canSwipe}
                >
                  <svg className="w-8 h-8" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <div>
                <button 
                  className={`rounded-full ${canGoBack ? 'bg-indigo-600' : 'bg-gray-300'}`} 
                  onClick={() => goBack()}
                  disabled={!canGoBack}
                >
                  <svg className="w-8 h-8" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.84998 7.49998C1.84998 4.66458 4.05979 1.84998 7.49998 1.84998C10.2783 1.84998 11.6515 3.9064 12.2367 5H10.5C10.2239 5 10 5.22386 10 5.5C10 5.77614 10.2239 6 10.5 6H13.5C13.7761 6 14 5.77614 14 5.5V2.5C14 2.22386 13.7761 2 13.5 2C13.2239 2 13 2.22386 13 2.5V4.31318C12.2955 3.07126 10.6659 0.849976 7.49998 0.849976C3.43716 0.849976 0.849976 4.18537 0.849976 7.49998C0.849976 10.8146 3.43716 14.15 7.49998 14.15C9.44382 14.15 11.0622 13.3808 12.2145 12.2084C12.8315 11.5806 13.3133 10.839 13.6418 10.0407C13.7469 9.78536 13.6251 9.49315 13.3698 9.38806C13.1144 9.28296 12.8222 9.40478 12.7171 9.66014C12.4363 10.3425 12.0251 10.9745 11.5013 11.5074C10.5295 12.4963 9.16504 13.15 7.49998 13.15C4.05979 13.15 1.84998 10.3354 1.84998 7.49998Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
                  </svg>
                </button>
              </div>
              <div>
                <button style={{ backgroundColor: !canSwipe && '#c3c4d3' }} className="rounded-full bg-green-600" onClick={() => swipe('right')}>
                  <svg className="w-8 h-8" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path></svg>
                </button>
              </div>
            </div>
            </div>
          ) : (
            <div>

            </div>
          )}

          </div>


          {dorples && dorples.length > 0 ? (
          <div className="swipe-progress-container">
            {Array.from({ length: Math.min(dorples.length, 30) }).map((_, index) => {
              // If we're limiting the indicators, adjust the calculation
              const adjustedIndex = dorples.length > 30 
                ? Math.floor(index * (dorples.length / 30))
                : index;
              
              const isCompleted = adjustedIndex < dorples.length - currentIndex - 1;
              return (
                <div 
                  key={index}
                  className={`swipe-progress-indicator ${isCompleted ? 'completed' : 'pending'}`}
                ></div>
              );
            })}
          </div>
          ) : (
            <div>
              
            </div>
          )}
        </>
      ) : (
        <ProjectSwipeCongrats />
      )}
    </div>
  );
}

export default ProjectSwipe;
