import React, { useState, useEffect } from 'react';
import {  useLocation } from "react-router-dom";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useRequestService } from '../services/RequestService';
import DorpleLogo from '../images/logo-large.svg';
import ScreenLoginBackground from '../images/background-login.jpg';
import SocialFollow from "../components/SocialFollow";

import Page1 from './Login/Page1';
import Page2 from './Login/Page2';
import Page3 from './Login/Page3';
import Page4 from './Login/Page4';
import Page5 from './Login/Page5';

function LoginPage() {

  const { trackPageView } = useMatomo()

  useEffect(() => {
    trackPageView()
  }, []);

  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const navigate = useNavigate();
  const requestService = useRequestService();
  const location = useLocation();

  // Handle state passed from signup page
  useEffect(() => {
    if (location.state?.infoMessage) {
      setInfoMessage(location.state.infoMessage);
      // Clear the state after showing the message
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  const handleLogin = async () => {
    setError(''); // Clear previous errors
    setInfoMessage(null); // Clear previous info messages
    
    try {

        if (!email || !password) {
            setError(t('login.error'));
            return;
        }

        const authService = requestService.getAuthService();
        const result = await authService.signIn(email, password);
        console.log('Success response:', result);

        if (result.token) {
            // Successful login
            setEmail('');
            setPassword('');
            navigate('/home');
        }

    } catch (error) {
        console.log('Error details:', error);
        
        // The error message is now directly in error.message
        const errorMessage = error.message;
        
        if (errorMessage === 'User is not verified') {
            setInfoMessage(
                <div>
                    <p className="text-sm">{t('login.not_verified')}</p>
                    <button
                        type="button"
                        onClick={() => navigate('/resend-verification', { 
                            state: { email: email }
                        })}
                        className="text-sky-700 hover:bg-sky-200 bg-sky-50 text-sm mt-2 p-2 rounded-md bg-sky-100 border border-sky-400"
                    >
                        {t('verification.resend.link')}
                    </button>
                </div>
            );
            return;
        }
        
        if (errorMessage === 'User is disabled') {
            setInfoMessage(
                <div>
                    <p className="text-sm">{t('login.not_enabled')}</p>
                </div>
            );
            return;
        }
        
        // Handle other errors
        setError(errorMessage || t('signup.login_failed'));
    }
  };

  return (
  <div>

  <div className="snap-y snap-mandatory overflow-y-scroll h-screen w-screen">

  <div className="snap-always snap-center" id="to-start">

  <div className="flex flex-col relative min-h-screen align-middle items-center w-full"
  style={{ backgroundImage: `url(${ScreenLoginBackground})` }}>

  <div className="max-sm:hidden flex flex-row justify-end  h-16 max-h-16 align-bottom w-full top-0 p-4">
        <SocialFollow />
  </div>

  <div className="container max-w-screen-xl mx-auto flex justify-center items-center text-4xl w-full grow">
      <div className="flex flex-col w-1/4"></div>
      <div className="flex flex-col w-1/2 justify-center items-center align-middle align-items-center">
      <div className="justify-content-center align-items-center items-center  flex flex-col">
        <div id="logo" className="flex flex-row md:flex-col justify-center items-center w-full pb-2 mb-3">
        <div className="flex flex-row justify-center items-center">
        <img src={DorpleLogo} alt="Dorple Logo" className="w-16 h-16 max-w-16 m-2"/>
        </div>    
        <div className='flex flex-col justify-left md:justify-center md:items-center'>
          <h1 className="text-4xl md:text-6xl text-left md:text-center text-sky-800 apptitle font-bold">Dorple</h1>
          <p className="text-xl md:text-2xl text-left md:text-center text-sky-800 appsubtitle font-bold">{t('home.subtitle')}</p>
        </div>
        </div>
        <div className="flex flex-col justify-center items-center border max-w-md bg-white bg-opacity-70 rounded-lg shadow-md p-4">
         
          <form className="login-panel" onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}>

            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 p-3 rounded relative" role="alert">
                <span className="block inline text-sm">{error}</span>
              </div>
            )}
            
            {infoMessage && (
              <div className="bg-sky-100 border border-sky-400 text-sky-700 p-3 rounded relative" role="alert">
                {typeof infoMessage === 'string' ? (
                    <span className="block inline text-sm">{infoMessage}</span>
                ) : (
                    infoMessage
                )}
              </div>
            )}
            
            <input
              className="border appearance-none rounded m-3 p-2 text-lg text-gray-700 leading-tight focus:outline-none bg-white border-gray-300 focus:bg-sky-50 focus:border-sky-700"
              placeholder={t('login.email')}
              id='email'
              value={email}
              type='email'
              autoComplete="username"
              onChange={(e) => setEmail(e.target.value)}
            />

            <input
              className="border appearance-none rounded m-3 p-2 text-lg text-gray-700 leading-tight focus:outline-none bg-white border-gray-300 focus:bg-sky-50 focus:border-sky-700"
              placeholder={t('login.password')}
              id='password'
              type='password'
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            
            <button
              type="submit"
              className="shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-lg m-3 p-2 rounded"
            >
              {t('login.button')}
            </button>
          </form>

          <div className="text-center text-sm mt-4">
            <Link 
              to="/request-password-reset" 
              className="font-medium text-sky-600 hover:underline"
              onClick={() => console.log('Password reset link clicked')}
            >
              {t('password_reset.forgot_password.reset_link')}
            </Link>
          </div>
          <div className="text-center text-sm mt-2">
            <Trans
              i18nKey="login.not_registered.reference"
              components={{
                signup: <Link to="/signup" className="font-medium text-sky-600 hover:underline" />,
              }}
            />
          </div>
        </div>
        <div className="flex grid-cols-1 place-content-center content-center justify-center items-center w-full pt-2 mt-2 mb-10">
          <LanguageSwitcher />
        </div>
      </div>
      </div>
      <div className="flex flex-col w-1/4"></div>
  </div>

  <div className="absolute z-10 flex flex-row justify-center items-center h-16 max-h-16 align-bottom w-full bottom-0">
        <a href="#page-1">
            <div className="border rounded-full bg-sky-800 opacity-50 m-3 p-3"><svg aria-label="Scroll down"  height="20" role="img" viewBox="0 0 24 24" width="20" className="fill-white"><path d="M20.16 6.65 12 14.71 3.84 6.65a2.27 2.27 0 0 0-3.18 0 2.2 2.2 0 0 0 0 3.15L12 21 23.34 9.8a2.2 2.2 0 0 0 0-3.15 2.26 2.26 0 0 0-3.18 0"></path></svg></div>
        </a>
  </div>

  </div>

  </div>






  <div className="snap-always snap-center overscroll-y-none" id="page-1">
    <Page1 />
  </div>
  <div className="snap-always snap-center " id="page-2">
    <Page2 />
  </div>
  <div className="snap-always snap-center" id="page-4">
    <Page4 />
  </div>
  <div className="snap-always snap-center" id="page-3">
    <Page3 />
  </div>
  <div className="snap-always snap-center" id="page-5">
    <Page5 />
  </div>







</div>

    </div>
  );
}

export default LoginPage;
