import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { Puff } from "react-loader-spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProjectConversationsEmpty from "../components/ProjectConversationsEmpty";
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useRequestService } from "../services/RequestService";
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from "react-infinite-scroll-component";
import { useImageService } from "../services/ImageService";

// Create a data URL for the fallback image with 3:4 aspect ratio
const fallbackImage = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMDAiIGhlaWdodD0iNDAwIiB2aWV3Qm94PSIwIDAgMzAwIDQwMCI+PHJlY3Qgd2lkdGg9IjMwMCIgaGVpZ2h0PSI0MDAiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=';

function ProjectConversationPage() {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { trackPageView, trackEvent } = useMatomo();
  const navigate = useNavigate();
  const location = useLocation();
  const requestService = useRequestService();
  const imageService = useImageService();
  const queryClient = useQueryClient();
  const [editingComment, setEditingComment] = useState(null);
  const [replyingTo, setReplyingTo] = useState(null);
  const [replyContent, setReplyContent] = useState('');
  const [pendingReactions, setPendingReactions] = useState({});
  const [pendingReplies, setPendingReplies] = useState({});
  const [imageWidth] = useState(150);
  const [imageHeight] = useState(200);
  
  // Hashtag filtering state
  const [activeHashtag, setActiveHashtag] = useState(null);

  // Hashtag suggestion state
  const [popularHashtags, setPopularHashtags] = useState([
    'architecture', 'design', 'modern', 'historic', 'renovation', 'sustainable', 'urban'
  ]);
  const [showHashtagSuggestions, setShowHashtagSuggestions] = useState(false);
  const [currentWord, setCurrentWord] = useState('');
  const textareaRef = useRef(null);

  // Parse the hashtag from URL query parameters on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tagParam = searchParams.get('hashtag');
    if (tagParam) {
      setActiveHashtag(tagParam);
    }
  }, [location.search]);

  // Query for fetching comments
  const {
    data,
    fetchNextPage,
    isError,
    isLoading: queryIsLoading,
    refetch
  } = useInfiniteQuery({
    queryKey: ['projectConversation', projectId, activeHashtag],
    queryFn: ({ pageParam = 0 }) => {
      let endpoint = `comments/feed/project/${projectId}?page=${pageParam}`;
      
      // Add hashtag filter if active
      if (activeHashtag) {
        endpoint += `&hashtag=${activeHashtag}`;
      }
      
      return requestService.get(endpoint);
    },
    getNextPageParam: (lastPage) => {
      // Check if there are more pages to load
      if (lastPage.last) {
        return undefined; // No more pages
      }
      return lastPage.number + 1; // Return the next page number
    },
    enabled: !!projectId, // Only run the query if projectId exists
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
    refetchOnMount: false, // Don't refetch when component mounts
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
  });

  // Effect to track page view only
  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  // Memoize allComments to prevent unnecessary re-renders
  const allComments = useMemo(() => 
    data?.pages.flatMap(page => page.content) || [],
    [data?.pages]
  );
  
  // Calculate if there are more comments to load
  const hasMoreComments = data?.pages[data.pages.length - 1]?.last === false;

  // Effect to handle hashtag changes
  useEffect(() => {
    if (activeHashtag !== null) {
      // Only refetch if the hashtag changes
      refetch();
    }
  }, [activeHashtag, refetch]); // Add refetch to dependencies

  // Extract and count hashtags from all comments
  useEffect(() => {
    if (allComments.length > 0) {
      // Extract all hashtags from comments
      const hashtagCounts = {};
      
      allComments.forEach(comment => {
        // Extract hashtags from comment content
        const commentHashtags = extractHashtags(comment.content);
        
        // Count occurrences
        commentHashtags.forEach(tag => {
          const cleanTag = tag.substring(1).toLowerCase(); // Remove # and lowercase
          hashtagCounts[cleanTag] = (hashtagCounts[cleanTag] || 0) + 1;
        });
      });
      
      // Convert to array and sort by count
      const sortedHashtags = Object.entries(hashtagCounts)
        .sort((a, b) => b[1] - a[1]) // Sort by count (descending)
        .map(entry => entry[0]); // Get just the hashtag
      
      // Update popular hashtags if we found any
      if (sortedHashtags.length > 0) {
        // Combine with default hashtags, remove duplicates
        const combinedHashtags = [...sortedHashtags];
        
        // Add some default hashtags if we don't have enough
        const defaultHashtags = ['architectuur', 'design', 'modern', 'renovatie', 'duurzaam', 'mobiliteit', 'building', 'parkeren', 'mooi', 'retro', 'sustainable', 'groen', 'fietsen', 'spelen'];
        defaultHashtags.forEach(tag => {
          if (!combinedHashtags.includes(tag) && combinedHashtags.length < 10) {
            combinedHashtags.push(tag);
          }
        });
        
        setPopularHashtags(combinedHashtags.slice(0, 10)); // Limit to 10 hashtags
      }
    }
  }, [allComments]); // Only depend on allComments

  // Function to handle hashtag clicks
  const handleHashtagClick = useCallback((hashtag) => {
    // Remove the # symbol for the URL
    const tag = hashtag.startsWith('#') ? hashtag.substring(1) : hashtag;
    
    // Update the URL with the hashtag parameter
    navigate(`/project/${projectId}/conversation?hashtag=${tag}`);
    
    // Set the active hashtag for filtering
    setActiveHashtag(tag);
    
    // Track the hashtag click
    trackEvent({ category: 'conversation', action: 'hashtag-filter', name: tag });
  }, [navigate, projectId, trackEvent]);

  // Function to clear the hashtag filter
  const clearHashtagFilter = useCallback(() => {
    navigate(`/project/${projectId}/conversation`);
    setActiveHashtag(null);
  }, [navigate, projectId]);

  // Function to render content with hashtags as clickable links
  const renderContentWithHashtags = (content) => {
    if (!content) return '';
    
    // Regular expression to match hashtags
    const hashtagRegex = /(#[a-zA-Z0-9_]+)/g;
    
    // Split the content by hashtags
    const parts = content.split(hashtagRegex);
    
    return parts.map((part, index) => {
      // Check if this part is a hashtag
      if (part.match(hashtagRegex)) {
        const hashtag = part;
        const isActive = activeHashtag === hashtag.substring(1);
        
        return (
          <span 
            key={index}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleHashtagClick(hashtag);
            }}
            className={`cursor-pointer ${isActive ? 'bg-sky-100 text-sky-800 font-bold' : 'text-sky-600 hover:underline'}`}
          >
            {hashtag}
          </span>
        );
      }
      
      // Regular text
      return <span key={index}>{part}</span>;
    });
  };

  const handleImageError = (e) => {
    // Only log errors in development
    if (process.env.NODE_ENV === 'development') {
      console.warn('Image load error:', e.target.src);
    }
    e.target.onerror = null; // Prevent infinite loop
    e.target.src = fallbackImage;
  };

  const renderCardContent = (comment) => {
    if (comment.commentType === 'BUILDING') {
      return (
        <div className='h-full w-full max-w-full  w-3/4 object-cover object-center'>
          <LazyLoadImage
            className="h-full w-full max-w-full"
            src={imageService.getSrcForBuilding(comment.buildingId, imageWidth, imageHeight) || comment.buildingImageLink}
            alt="gallery-photo"
            placeholderSrc={fallbackImage}
            onError={handleImageError}
          /> 
        </div>
      );
    } else if (comment.commentType === 'SWIPE_QUESTION') {
      return (
        <div className='w-full max-w-full  w-3/4 object-cover object-center'>
          <div 
            className="flex justify-center items-center p-0 "
            dangerouslySetInnerHTML={{ __html: comment.swipeQuestionImageSrc }}
          />
        </div>
      );
    } else if (comment.commentType === 'PROJECT_IDEA') {
      return (
        <div className='w-full max-w-full w-3/4 object-cover object-center'>
          <div 
            className="flex justify-center items-center p-0 "
            dangerouslySetInnerHTML={{ __html: comment.projectIdeaImageSrc }}
          />
        </div>
      );
    } else if (comment.commentType === 'NON_BUILDING') {
      return (
        <>
          <LazyLoadImage
            className="w-full max-w-full "
            src={comment.nonBuildingImageLink || imageService.getSrcForBuilding(comment, imageWidth, imageHeight)}
            alt="gallery-photo"
            placeholderSrc={fallbackImage}
            onError={handleImageError}
          /> 
        </>
      );
    }
  };

  // Function to extract hashtags from content
  const extractHashtags = (content) => {
    if (!content) return [];
    const hashtagRegex = /#[a-zA-Z0-9_]+/g;
    return content.match(hashtagRegex) || [];
  };

  // Mutations
  const editCommentMutation = useMutation({
    mutationFn: ({ commentId, content }) => 
      requestService.put(`comments/${commentId}`, { content }),
    onSuccess: () => {
      queryClient.invalidateQueries(['projectConversation', projectId, activeHashtag]);
      setEditingComment(null);
    }
  });

  const deleteCommentMutation = useMutation({
    mutationFn: (commentId) => 
      requestService.delete(`comments/${commentId}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['projectConversation', projectId, activeHashtag]);
    }
  });

  const replyMutation = useMutation({
    mutationFn: ({ commentId, content }) => 
      requestService.post(`comments/${commentId}/replies`, { 
        content,
        parentId: commentId 
      }),
    onSuccess: (_, variables) => {
      // Remove the pending state for this reply
      setPendingReplies(prev => {
        const updated = { ...prev };
        delete updated[variables.commentId];
        return updated;
      });
      queryClient.invalidateQueries(['projectConversation', projectId, activeHashtag]);
      setReplyingTo(null);
      setReplyContent('');
    },
    onError: (error, variables) => {
      // Remove the pending state for this reply
      setPendingReplies(prev => {
        const updated = { ...prev };
        delete updated[variables.commentId];
        return updated;
      });
      console.error('Error submitting reply:', error);
      // We don't need to revert any optimistic update here since we're invalidating the query
      queryClient.invalidateQueries(['projectConversation', projectId, activeHashtag]);
    }
  });

  const toggleReactionMutation = useMutation({
    mutationFn: ({ commentId, reactionType }) => 
      requestService.post(`comments/${commentId}/reactions?type=${reactionType}`),
    onSuccess: (_, variables) => {
      // Remove the pending state for this comment
      setPendingReactions(prev => {
        const updated = { ...prev };
        delete updated[variables.commentId];
        return updated;
      });
      queryClient.invalidateQueries(['projectConversation', projectId, activeHashtag]);
    },
    onError: (error, variables) => {
      // Remove the pending state for this comment
      setPendingReactions(prev => {
        const updated = { ...prev };
        delete updated[variables.commentId];
        return updated;
      });
      // Revert the optimistic update on error
      console.error('Error toggling reaction:', error);
      // Revert the optimistic update by invalidating the query
      queryClient.invalidateQueries(['projectConversation', projectId, activeHashtag]);
    }
  });

  // Handlers
  const handleEditComment = (comment) => {
    setEditingComment({
      id: comment.id,
      content: comment.content
    });
  };

  const handleSaveEdit = (commentId) => {
    if (editingComment.content.trim()) {
      editCommentMutation.mutate({
        commentId,
        content: editingComment.content.trim()
      });
    }
  };

  const handleDeleteComment = (commentId) => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      deleteCommentMutation.mutate(commentId);
    }
  };

  const handleToggleReaction = (commentId, reactionType) => {
    // Set this reaction as pending
    setPendingReactions(prev => ({
      ...prev,
      [commentId]: true
    }));
    
    // Find the comment in the current data
    const allPages = queryClient.getQueryData(['projectConversation', projectId, activeHashtag])?.pages || [];
    const allComments = allPages.flatMap(page => page.content) || [];
    const commentIndex = allComments.findIndex(comment => comment.id === commentId);
    
    if (commentIndex !== -1) {
      const comment = allComments[commentIndex];
      const currentReaction = comment.userReaction;
      const currentLikeCount = comment.reactionCounts?.['LIKE'] || 0;
      
      // Create an optimistic update
      const updatedComment = {
        ...comment,
        userReaction: currentReaction === reactionType ? null : reactionType,
        reactionCounts: {
          ...comment.reactionCounts,
          [reactionType]: currentReaction === reactionType 
            ? Math.max(0, currentLikeCount - 1) // Decrement if toggling off
            : currentLikeCount + 1 // Increment if toggling on
        }
      };
      
      // Apply the optimistic update
      const updatedPages = allPages.map(page => ({
        ...page,
        content: page.content.map(c => 
          c.id === commentId ? updatedComment : c
        )
      }));
      
      // Update the cache with our optimistic result
      queryClient.setQueryData(['projectConversation', projectId, activeHashtag], {
        pages: updatedPages,
        pageParams: queryClient.getQueryData(['projectConversation', projectId, activeHashtag])?.pageParams || []
      });
    }
    
    // Then send the actual request
    toggleReactionMutation.mutate({ commentId, reactionType });
  };

  const handleReply = (commentId) => {
    setReplyingTo(commentId);
  };

  const handleSubmitReply = (commentId, content) => {
    if (content.trim()) {
      // Set this reply as pending
      setPendingReplies(prev => ({
        ...prev,
        [commentId]: true
      }));
      
      // Find the comment in the current data
      const allPages = queryClient.getQueryData(['projectConversation', projectId, activeHashtag])?.pages || [];
      const allComments = allPages.flatMap(page => page.content) || [];
      const commentIndex = allComments.findIndex(comment => comment.id === commentId);
      
      if (commentIndex !== -1) {
        const comment = allComments[commentIndex];
        
        // Create an optimistic update
        const updatedComment = {
          ...comment,
          replyCount: (comment.replyCount || 0) + 1
        };
        
        // Apply the optimistic update
        const updatedPages = allPages.map(page => ({
          ...page,
          content: page.content.map(c => 
            c.id === commentId ? updatedComment : c
          )
        }));
        
        // Update the cache with our optimistic result
        queryClient.setQueryData(['projectConversation', projectId, activeHashtag], {
          pages: updatedPages,
          pageParams: queryClient.getQueryData(['projectConversation', projectId, activeHashtag])?.pageParams || []
        });
      }
      
      // Extract hashtags for analytics
      const hashtags = extractHashtags(content);
      if (hashtags.length > 0) {
        trackEvent({ 
          category: 'conversation', 
          action: 'reply-with-hashtags', 
          name: hashtags.join(',') 
        });
      }
      
      // Then send the actual request
      replyMutation.mutate({
        commentId,
        content: content.trim()
      });
    }
  };

  // Function to handle textarea input and detect hashtag typing
  const handleReplyInputChange = (e) => {
    const value = e.target.value;
    setReplyContent(value);
    
    // Check if we're currently typing a hashtag
    const cursorPosition = e.target.selectionStart;
    const textBeforeCursor = value.substring(0, cursorPosition);
    const words = textBeforeCursor.split(/\s/);
    const currentTypingWord = words[words.length - 1];
    
    if (currentTypingWord.startsWith('#') && currentTypingWord.length > 1) {
      setCurrentWord(currentTypingWord.substring(1).toLowerCase());
      setShowHashtagSuggestions(true);
    } else {
      setShowHashtagSuggestions(false);
    }
  };

  // Function to insert a hashtag suggestion
  const insertHashtag = (hashtag) => {
    if (!textareaRef.current) return;
    
    const textarea = textareaRef.current;
    const cursorPosition = textarea.selectionStart;
    const textBeforeCursor = replyContent.substring(0, cursorPosition);
    const textAfterCursor = replyContent.substring(cursorPosition);
    
    // Find the start of the current hashtag
    const hashtagStartPos = textBeforeCursor.lastIndexOf('#');
    if (hashtagStartPos === -1) return;
    
    // Replace the current hashtag with the suggested one
    const newText = 
      textBeforeCursor.substring(0, hashtagStartPos) + 
      '#' + hashtag + ' ' + 
      textAfterCursor;
    
    setReplyContent(newText);
    setShowHashtagSuggestions(false);
    
    // Set focus back to textarea and place cursor after the inserted hashtag
    setTimeout(() => {
      textarea.focus();
      const newCursorPosition = hashtagStartPos + hashtag.length + 2; // +2 for # and space
      textarea.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  // Filter hashtag suggestions based on current input
  const filteredHashtags = popularHashtags
    .filter(tag => tag.toLowerCase().includes(currentWord))
    .slice(0, 5); // Limit to 5 suggestions

  if (isError) {
    return (
      <div className="text-center text-red-600 p-4">
        Error loading conversation
      </div>
    );
  }

  return (
    <div className="w-full"> 
      <div id="conversation-container" className="mx-auto max-w-lg pt-12 z-0 h-screen overflow-auto">
        {/* Hashtag filter indicator */}
        {activeHashtag && (
          <div className="bg-sky-50 p-3 mb-4 rounded-lg flex justify-between items-center">
            <div className="flex items-center">
              <span className="mr-2 text-sky-800">Filtering by:</span>
              <span className="bg-sky-100 text-sky-800 font-bold px-2 py-1 rounded">
                #{activeHashtag}
              </span>
            </div>
            <button 
              onClick={clearHashtagFilter}
              className="text-sky-600 hover:text-sky-800"
            >
              Clear filter
            </button>
          </div>
        )}
        
        {/* Popular hashtags section */}
        {!activeHashtag && popularHashtags.length > 0 && (
          <div className="bg-white border rounded-lg p-3 mb-4">
            <h3 className="text-sm font-semibold text-gray-700 mb-2">Trending hashtags</h3>
            <div className="flex flex-wrap gap-1">
              {popularHashtags.map(tag => (
                <button
                  key={tag}
                  onClick={() => handleHashtagClick(`#${tag}`)}
                  className="text-sm bg-gray-100 hover:bg-sky-50 hover:text-sky-700 text-gray-700 px-2 py-1 rounded"
                >
                  #{tag}
                </button>
              ))}
            </div>
          </div>
        )}
        
        {queryIsLoading ? (
          <div className="flex justify-center items-center p-4">
            <Puff color="#0C4A6E" height={100} width={100} />
          </div>
        ) : !allComments.length ? (
          activeHashtag ? (
            <div className="text-center p-4">
              <p className="text-gray-600 mb-4">No comments found with hashtag <span className="font-bold">#{activeHashtag}</span></p>
              <button 
                onClick={clearHashtagFilter}
                className="bg-sky-800 text-white px-4 py-2 rounded hover:bg-sky-700"
              >
                Show all comments
              </button>
            </div>
          ) : (
            <ProjectConversationsEmpty projectId={projectId} />
          )
        ) : (
          <InfiniteScroll
            dataLength={allComments.length}
            next={fetchNextPage}
            hasMore={hasMoreComments}
            loader={
              <div className="flex justify-center items-center p-4">
                <Puff color="#0C4A6E" height={50} width={50} />
              </div>
            }
            scrollableTarget="conversation-container"
            endMessage={
              <p className="text-center text-gray-500 my-4">
                {t('common.noMoreComments')}
              </p>
            }
          >
            {allComments.map((comment) => (
              <div className="conversation-tile p-2" key={comment.id}>
                <div className="flex items-start gap-2.5">
                  <div className="flex flex-col w-full leading-1.5 border-gray-200 bg-gray-100 rounded-lg">

                    {/* Building Card */}
                    <Link
                      to={comment.commentType === "SWIPE_QUESTION" 
                        ? `/project/${projectId}/questiondetail` 
                        : comment.commentType === "NON_BUILDING"
                          ? `/project/${projectId}/nonbuildingdetail`
                        : comment.commentType === "PROJECT_IDEA"
                          ? `/project/${projectId}/projectideadetail`
                          : `/project/${projectId}/buildingdetail`}
                      state={comment.commentType === "SWIPE_QUESTION" 
                        ? { question: {
                          id: comment.swipeQuestionId,
                          name: comment.buildingName,
                          questionText: comment.questionText,
                          questionType: comment.questionType,
                          imageSrc: comment.swipeQuestionImageSrc,
                          scoren: comment.buildingScoren,
                          scorey: comment.buildingScorey
                          }}
                        : comment.commentType=== "PROJECT_IDEA"
                          ? { projectIdea: {
                              id: comment.projectIdeaId,
                              name: comment.projectIdeaDescription,
                              description: comment.projectIdeaDescription,
                              category: comment.projectIdeaCategory,
                              latitude: comment.projectIdeaLatitude,
                              longitude: comment.projectIdeaLongitude,
                              photoUrl: comment.projectIdeaImageSrc,
                              imageSrc: comment.projectIdeaImageSrc,
                              scoren: comment.projectIdeaScoren || 0,
                              scorey: comment.projectIdeaScorey || 0
                            }}
                            : comment.commentType=== "NON_BUILDING"
                            ? { nonBuilding: {
                                id: comment.nonBuildingId,
                                name: comment.nonBuildingName,
                                description: comment.nonBuildingDescription,
                                category: comment.nonBuildingCategory,
                                latitude: comment.nonBuildingLatitude,
                                longitude: comment.nonBuildingLongitude,
                                imageLink: comment.nonBuildingImageLink,
                                imageSrc: comment.nonBuildingImageSrc,
                                scoren: comment.nonBuildingScoren || 0,
                                scorey: comment.nonBuildingScorey || 0
                              }}                      
                          : { building: {
                            id: comment.buildingId,
                            name: comment.buildingName,
                            location: comment.buildingAddress,
                            imageLink: comment.buildingImageLink,
                            scoren: comment.buildingScoren,
                            scorey: comment.buildingScorey
                            }}
                      }
                      className="bg-sky-50 border border-gray-200 rounded-t-lg"
                    >
                      <div className="w-full flex flex-row gap-2 grid grid-cols-3">
                        <div className="conversation-img-tile col-span-1">
                          {renderCardContent(comment)}
                        </div>
                        <div className="col-span-2 p-2">
                          <div className="flex items-center space-x-2 rtl:space-x-reverse">
                            <span className="text-sm font-semibold text-gray-900">{comment.authorName}</span>
                            <span className="text-sm font-normal text-gray-500">
                              {(() => {
                                try {
                                  if (!comment.createdAt) return '';
                                  
                                  // Parse createdAt as a string in ISO format
                                  return formatDistanceToNow(new Date(comment.createdAt), { addSuffix: true });
                                } catch (error) {
                                  console.error('Date parsing error:', error, comment.createdAt);
                                  return 'some time ago'; // Fallback text
                                }
                              })()}
                            </span>
                          </div>
                          <p className="font-normal text-left py-2.5 text-gray-900">{renderContentWithHashtags(comment.content)}</p>
                        </div>
                      </div>
                    </Link>

                    {/* Reactions and Actions */}
                    <div className="flex items-center justify-between text-sm m-1">
                      <div className="flex space-x-4 ">
                        <button 
                          onClick={() => handleToggleReaction(comment.id, 'LIKE')}
                          className={`flex items-center space-x-1 ${comment.userReaction === 'LIKE' ? 'text-sky-600' : ''} ${pendingReactions[comment.id] ? 'opacity-70' : ''}`}
                          disabled={pendingReactions[comment.id]}
                        >
                          <div className={pendingReactions[comment.id] ? 'animate-pulse' : ''}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                            </svg>
                          </div>
                          <div>{comment.reactionCounts?.['LIKE'] || 0}</div>
                        </button>
                        <button 
                          onClick={() => handleReply(comment.id)}
                          className="flex items-center space-x-1"
                          disabled={replyingTo === comment.id}
                        >
                          <div className={replyingTo === comment.id ? 'animate-pulse' : ''}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                            </svg> 
                          </div>
                          <div>{comment.replyCount}</div>
                        </button>
                      </div>
                    </div>

                    {/* Reply Form */}
                    {replyingTo === comment.id && (
                      <div className="mt-3 pl-4 border-l-2 border-gray-300">
                        <div className="relative">
                          <textarea
                            ref={textareaRef}
                            value={replyContent}
                            onChange={handleReplyInputChange}
                            placeholder={t('common.replyPlaceholder')}
                            className="w-full p-2 border rounded-lg resize-none"
                            rows="2"
                          />
                          {showHashtagSuggestions && filteredHashtags.length > 0 && (
                            <div className="absolute z-10 bg-white border rounded-md shadow-lg mt-1 w-full max-h-40 overflow-y-auto">
                              {filteredHashtags.map((tag) => (
                                <div
                                  key={tag}
                                  className="px-3 py-2 hover:bg-sky-50 cursor-pointer"
                                  onClick={() => insertHashtag(tag)}
                                >
                                  #{tag}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="flex flex-col mt-2">
                          <div className="flex justify-end space-x-2">
                            <button
                              onClick={() => {
                                setReplyingTo(null);
                                setReplyContent('');
                              }}
                              className="text-gray-500 hover:text-gray-700"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={() => {
                                if (replyContent.trim()) {
                                  handleSubmitReply(comment.id, replyContent);
                                }
                              }}
                              className={`bg-sky-800 text-white px-3 py-1 rounded hover:bg-sky-700 ${pendingReplies[comment.id] ? 'opacity-70' : ''}`}
                              disabled={!replyContent.trim() || pendingReplies[comment.id]}
                            >
                              {pendingReplies[comment.id] ? 'Sending...' : 'Reply'}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
}

export default ProjectConversationPage;