import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useRequestService } from '../services/RequestService';
import BadgeUrl from "../images/compass.svg";
import AmbassadorUrl from "../images/ambassador.svg";
import { useTranslation } from 'react-i18next';

function RewardsPage() {
  const requestService = useRequestService();
  const [rewards, setRewards] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const data = await requestService.get('badges/rewards');
        setRewards(data);
      } catch (error) {
        console.error('Error fetching rewards:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRewards();
  }, []);

  if (isLoading || !rewards) return null;

  // Group badges by type, excluding NEWCOMER
  const groupedBadges = rewards.badges
    .filter(badge => badge.type !== 'NEWCOMER')
    .reduce((groups, badge) => {
      const type = badge.type.startsWith('SCORER_') ? 'SCORER' : 'AMBASSADOR';
      if (!groups[type]) groups[type] = [];
      groups[type].push(badge);
      return groups;
    }, {});

  // Find next unearned badge for each type
  const getNextBadge = (badges) => {
    return badges.find(badge => !badge.earned) || null;
  };

  // Get the next badge overall
  const nextScorerBadge = getNextBadge(groupedBadges['SCORER'] || []);
  const nextAmbassadorBadge = getNextBadge(groupedBadges['AMBASSADOR'] || []);
  const nextBadge = nextScorerBadge || nextAmbassadorBadge;

  // Add console.log to debug the data
  console.log('Rewards data:', rewards);

  return (
    <div className="max-w-lg mx-auto p-4">


      {/* Badges List */}
      <div className="grid gap-8">
        {Object.entries(groupedBadges).map(([type, badges]) => {
          const nextBadge = getNextBadge(badges);
          const isScorer = type === 'SCORER';
          const currentValue = isScorer ? rewards.totalPoints : (rewards.totalInvites || 0);
          const pointsToNext = isScorer ? rewards.pointsToNextBadge : rewards.invitesToNextBadge;

          return (
            <div key={type} className="w-full">
              <div className="flex justify-between">  
                <div className="text-lg text-left font-bold text-sky-800">
                  {isScorer ? t('rewards.scoringBadges') : t('rewards.ambassadorBadges')}
                </div>
                <div className="text-sm text-right flex justify-between items-center text-gray-600 ">
                    {isScorer ? <Link to="/swipe">{t('rewards.swipeForPoints')}</Link> : <Link to="/invite">{t('rewards.inviteFriends')}</Link> }
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                    </svg>
                </div>
              </div>

              {nextBadge && (
                <p className="text-sm text-left text-gray-600 my-1 mb-2">
                  {pointsToNext} {isScorer ? t('rewards.points') : t('rewards.invites')} {t('rewards.until')} {t(`badges.${nextBadge.type}.title`)}
                </p>
              )}
              <div className="space-y-2">
                {badges.map((badge, index) => {
                  const badgeLevel = badge.type.split('_')[1]?.toLowerCase();
                  const badgeColor = {
                    'bronze': 'bg-gradient-to-br from-orange-800 via-orange-600 to-orange-800',
                    'silver': 'bg-gradient-to-br from-gray-500 via-gray-300 to-gray-500',
                    'gold': 'bg-gradient-to-br from-yellow-500 via-yellow-300 to-yellow-500'
                  }[badgeLevel] || 'bg-gradient-to-br from-sky-500 via-sky-300 to-sky-500';

                  return (
                    <div key={index} className={`border-b pb-2 flex items-center ${!badge.earned && 'opacity-50'}`}>
                      <div className={`rounded-full ${badgeColor} border border-sky-800 w-14 h-14 flex items-center justify-center mr-4`}>
                        <img 
                          src={isScorer ? BadgeUrl : AmbassadorUrl} 
                          alt={badge.title}
                          className="w-10 h-10 stroke-sky-800" 
                        />
                      </div>
                      <div className="flex-1 text-left">
                        <h3 className="font-bold text-sky-800 text-left">
                          {t(`badges.${badge.type}.title`)}
                        </h3>
                        <p className="text-sm text-gray-600">
                          {t(`badges.${badge.type}.description`)}
                        </p>
                        {badge.earned && (
                          <div className="text-xs text-gray-500 mt-1">
                            {t('rewards.earnedIn')} {badge.daysToEarn} {t('rewards.days')} • {t('rewards.awarded')} {new Date(badge.dateAwarded).toLocaleDateString()}
                          </div>
                        )}
                        {!badge.earned && (
                          <div className="text-xs text-gray-500 mt-1">
                            {t('rewards.unlockAt')} {badge.threshold} {isScorer ? t('rewards.points') : t('rewards.invites')}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RewardsPage; 