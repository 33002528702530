import React, { useState } from 'react';
import Switch from "react-switch";
import { useAccountService } from '../services/AccountService';


function SwipeGeoSwitcher({ onSwipeLocalChange }) {
    
    const accountService = useAccountService();
    
    // Safely get auth service
    const getAuthService = () => {
        try {
            return accountService?.requestService?.getAuthService();
        } catch (error) {
            console.warn('Error accessing auth service:', error);
            return null;
        }
    };
    
    // Safely get storage service
    const getStorageService = () => {
        const authService = getAuthService();
        return authService?.storageService;
    };
    
    const [isSwipelocal, setIsSwipelocal] = useState(() => {
        const storageService = getStorageService();
        if (storageService) {
            const saved = storageService.loadLocalSwipe();
            return saved === "true" || saved === true;
        }
        return false;
    });

    const getGeolocation = async () => {
        if ("geolocation" in navigator) {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const coords = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                        const storageService = getStorageService();
                        if (storageService) {
                            storageService.saveGeolocation(coords);
                        }
                        resolve(coords);
                    },
                    (error) => {
                        console.error("Error getting location:", error);
                        setIsSwipelocal(false);
                        reject(error);
                    }
                );
            });
        } else {
            console.error("Geolocation not supported");
            setIsSwipelocal(false);
            return null;
        }
    };

    const handleChange = async (checked) => {
        if (checked) {
            await getGeolocation();
        } else {
            const storageService = getStorageService();
            if (storageService) {
                storageService.removeGeolocation();
            }
        }
        setIsSwipelocal(checked);
        
        const storageService = getStorageService();
        if (storageService) {
            storageService.saveLocalswipe(checked);
        }
        
        onSwipeLocalChange(checked);
    };

    return (
        <div className='flex flex-row justify-center align-middle m-2'>
            <Switch id="switchswipegeo" onChange={handleChange} checked={isSwipelocal || false} height={20} width={40} />
            <span className='ml-2 text-sm align-middle'>Mijn omgeving - 5km Radius</span>
        </div>
    );
}

export default SwipeGeoSwitcher; 