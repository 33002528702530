import { Link } from "react-router-dom";
import ScreenMap from '../../images/dorple-screen-map.png';
import { useTranslation } from 'react-i18next'; 

function Page4(props) {
    const { t } = useTranslation();
    return (
    <div className="relative min-h-screen flex flex-col bg-gradient-to-b from-pink-300 to-sky-200 m-0">
 
    <div className="absolute z-10 flex flex-col md:flex-row justify-center items-center h-16 max-h-16 align-bottom  w-full">
        <a href="#page-2">
        <div className="border rounded-full bg-sky-800 opacity-50 m-3 p-3">
        <svg aria-label="Scroll up"  fill="none" viewBox="0 0 24 24" strokeWidth="3" className="size-6 stroke-white"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" /></svg>
        </div>
        </a>
    </div>


        <div className="container max-w-screen-xl mx-auto flex justify-center items-center text-4xl w-full grow">
        <div className="flex flex-col md:flex-row align-middle items-center m-2 w-full">
            <div className="flex flex-col md:w-1/2 align-middle items-center text-center md:text-left">
                <span className="text-3xl md:text-8xl text-sky-800 md:p-2 mb-4 text-center">
                {t('login.page4.title')}
                </span>
                <span className="text-lg md:text-3xl text-sky-800 md:p-2 px-4 md:px-0 mb-6 text-center">
                {t('login.page4.description')}
                </span>
                <Link to="/signup" className="hidden md:block shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-lg m-3 p-2 rounded">
                {t('login.page4.button')}
                </Link>
            </div>

            <div className="flex md:w-1/2 justify-center items-center">
            <div className="p-4 col-span-2">
          <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[506px] w-[250px] shadow-2xl shadow-gray-500/50">
          <div className="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
          <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
          <div className="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
          <div className="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
          <div className="rounded-[2rem] overflow-hidden w-[222px] h-[480px] bg-white dark:bg-gray-800">
          <img className="bg-white bg-opacity-75 w-[222px]] h-[480px]" src={ScreenMap} alt="Dorple Rewards" />
          </div>
          </div>

        </div>


            </div>
            </div> 
        </div>
        
        <div className="absolute z-10 flex flex-row justify-center items-center h-16 max-h-16 align-bottom w-full bottom-0">
            <a href="#page-3">
            <div className="border rounded-full bg-sky-800 opacity-50 m-3 p-3"><svg aria-label="Scroll down" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="size-6 stroke-white"><path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" /></svg></div>
            </a>
        </div>


    </div>
    );
}

export default Page4;