import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

export default function NavFooterLink({to, children, label, viewBox, fill}) {

  const { t } = useTranslation();

  const isSvgContent = children.type === 'path' || children.length > 1;

  return <Link to={to} className="w-full nav-footer-link hover:bg-gray-50 dark:hover:bg-gray-800 dark:group-hover:text-sky-500 group">
    {isSvgContent ? <svg className="size-6 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500" viewBox={viewBox || "0 0 15 15"} fill={fill ? fill : "none"} xmlns="http://www.w3.org/2000/svg">{children}</svg> : children}
    <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-sky-700 dark:group-hover:text-sky-600">
      {t(label)}
    </span>
  </Link>
}