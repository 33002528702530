import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRequestService } from '../services/RequestService';
import { useAuthenticationService } from '../services/AuthenticationService';

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const requestService = useRequestService();
  const authenticationService = useAuthenticationService();

  const changeLanguage = async (language) => {
    console.debug('Changing language to:', language);
    await i18n.changeLanguage(language);
    
    // Only update in backend if user is authenticated
    if (authenticationService.isTokenValid()) {
      console.debug('User is authenticated, updating language in backend');
      try {
        const success = await requestService.updateLanguage(language);
        if (!success) {
          console.error('Failed to update language preference');
        } else {
          console.debug('Language updated successfully');
        }
      } catch (error) {
        console.error('Failed to update language preference:', error);
        // If we get a 401, the user's token might be invalid
        if (error.message && error.message.includes('401')) {
          console.debug('Token might be invalid, clearing auth state');
          authenticationService.clearToken();
        }
      }
    } else {
      console.debug('User is not authenticated, skipping backend update');
    }
  };

  return (
    <div className="flex flex-row space-x-2">
      <button
        className={`px-2 py-1 rounded ${i18n.language === 'nl' ? 'text-sky-800' : 'text-gray-400'} text-lg`}
        onClick={() => changeLanguage('nl')}
      >
        NL
      </button>
      <button
        className={`px-2 py-1 rounded ${i18n.language === 'en' ? 'text-sky-800' : 'text-gray-400'} text-lg`}
        onClick={() => changeLanguage('en')}
      >
        EN
      </button>
      <button
        className={`px-2 py-1 rounded ${i18n.language === 'fr' ? 'text-sky-800' : 'text-gray-400'} text-lg`}
        onClick={() => changeLanguage('fr')}
      >
        FR
      </button>
      <button
        className={`px-2 py-1 rounded ${i18n.language === 'de' ? 'text-sky-800' : 'text-gray-400'} text-lg`}
        onClick={() => changeLanguage('de')}
      >
        DE
      </button>
    </div>
  );
}

export default LanguageSwitcher; 