import RequestService from "./RequestService";
import StorageService from "./StorageService";

class NonBuildingService {
  /** @type {RequestService} */
  _requestService;
  storageService;
  
  endpoint = "non-buildings";

  constructor(requestService) {
    // Use the provided requestService or create a new one
    this._requestService = requestService;
    this.storageService = new StorageService();
    
    // Bind methods to this instance
    this.createNonBuildingFromForm = this.createNonBuildingFromForm.bind(this);
    this.getNonBuildings = this.getNonBuildings.bind(this);
    this.getNonBuilding = this.getNonBuilding.bind(this);
  }

  get requestService() {
    if (!this._requestService) {
      // Create a new RequestService instance if not provided
      this._requestService = new RequestService();
    }
    return this._requestService;
  }

  async getNonBuildings() {
    return this.requestService.get(this.endpoint);
  }

  async getNonBuilding(id) {
    return this.requestService.get(`${this.endpoint}/${id}`);
  }
  
  async createNonBuildingFromForm(form, photoBlob) {
    try {
      console.log("createNonBuildingFromForm called with:", { form, photoBlob });
      
      // Get the project ID from the URL
      const pathParts = window.location.pathname.split('/');
      const projectIdIndex = pathParts.indexOf('project') + 1;
      const projectId = pathParts[projectIdIndex];
      
      if (!projectId) {
        throw new Error('Project ID not found in URL');
      }
      
      console.log("Project ID:", projectId);
      
      // Create the request data object
      const requestData = {
        description: form.description.value,
        category: form.category.value,
        latitude: parseFloat(form.latitude.value),
        longitude: parseFloat(form.longitude.value)
      };
      
      console.log("Request data:", requestData);
      
      // Create a FormData object
      const formData = new FormData();
      formData.append('data', new Blob([JSON.stringify(requestData)], { type: 'application/json' }));
      if (photoBlob) {
        formData.append('image', photoBlob);
      }
      
      // Use the RequestService to post the form data with proper headers
      const result = await this.requestService.postForm(
        `projects/${projectId}/nonbuildings`, 
        formData,
        {
          'Accept': 'application/json'
        }
      );
      
      console.log("API result:", result);
      return result;
    } catch (error) {
      console.error('Error creating non-building:', error);
      throw error;
    }
  }
}

// Create a singleton instance with a new RequestService
const requestServiceInstance = new RequestService();
const nonBuildingServiceInstance = new NonBuildingService(requestServiceInstance);

/**
 * 
 * @returns {NonBuildingService} NonBuilding service.
 */
export function useNonBuildingService() {
  return nonBuildingServiceInstance;
}

export default NonBuildingService;