import React, { useState, useRef } from "react";
import CameraFeed from "./CameraFeed";

/** @type {function (function(string, function (Blob): number): number)} */
function capture(video, callback, asBlob = false) {
  const type = "image/jpeg";
  const TARGET_WIDTH = 600;
  const TARGET_HEIGHT = 800;
  
  if (!video || !video.videoWidth) {
    throw new Error("Video not ready");
  }
  
  try {
    const canvas = document.createElement("canvas");
    canvas.width = TARGET_WIDTH;
    canvas.height = TARGET_HEIGHT;
    
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("Could not get canvas context");
    }

    // Calculate scaling and positioning to maintain aspect ratio
    const videoAspect = video.videoWidth / video.videoHeight;
    const targetAspect = TARGET_WIDTH / TARGET_HEIGHT;
    let sx = 0, sy = 0, sWidth = video.videoWidth, sHeight = video.videoHeight;

    if (videoAspect > targetAspect) {
      // Video is wider than target, crop width
      sWidth = video.videoHeight * targetAspect;
      sx = (video.videoWidth - sWidth) / 2;
    } else if (videoAspect < targetAspect) {
      // Video is taller than target, crop height
      sHeight = video.videoWidth / targetAspect;
      sy = (video.videoHeight - sHeight) / 2;
    }

    // Handle mirroring for front camera
    if (video.classList.contains("-scale-x-100")) {
      ctx.scale(-1, 1);
      ctx.translate(-TARGET_WIDTH, 0);
    }
    
    // Draw the video frame to canvas with correct cropping
    ctx.drawImage(video, 
      sx, sy, sWidth, sHeight,  // Source rectangle
      0, 0, TARGET_WIDTH, TARGET_HEIGHT  // Destination rectangle
    );
    
    const dataUrl = canvas.toDataURL(type, 0.95);
    
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          callback(null, new Error("Failed to create blob from canvas"));
          return;
        }
        callback(dataUrl, blob);
      },
      type,
      0.95
    );
  } catch (error) {
    throw error;
  }
}

/**
 * @param {{onPhotoTaken: function(string, Blob), asBlob:boolean}} props
 */
function Camera({ onPhotoTaken, asBlob }) {
  const [isCapturing, setIsCapturing] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [error, setError] = useState(null);
  const videoRef = useRef(null);

  const handleVideoReady = (ready) => {
    setIsVideoReady(ready);
    if (ready) {
      setError(null);
      videoRef.current = document.getElementById("videoElement");
    }
  };

  const onCaptureClicked = () => {
    if (!isVideoReady || isCapturing || !videoRef.current) {
      setError("Camera not ready");
      return;
    }
    
    setIsCapturing(true);
    setError(null);
    
    try {
      capture(videoRef.current, (result, blobOrError) => {
        if (blobOrError instanceof Error) {
          setError(blobOrError.message);
          setIsCapturing(false);
          return;
        }
        onPhotoTaken(result, blobOrError);
        setIsCapturing(false);
      }, asBlob);
    } catch (error) {
      console.error("Capture failed:", error);
      setError(error.message);
      setIsCapturing(false);
    }
  };

  return (
    <div className="relative w-full h-full bg-black">
      <CameraFeed onVideoReady={handleVideoReady} />
      {error && (
        <div className="absolute bottom-16 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-4 py-2 rounded-lg z-20">
          {error}
        </div>
      )}
      <button 
        onClick={onCaptureClicked} 
        disabled={!isVideoReady || isCapturing}
        className={`absolute bottom-10 left-1/2 transform -translate-x-1/2 border border-white text-white rounded-full px-4 py-2 z-10 ${
          !isVideoReady || isCapturing
            ? 'bg-gray-500 opacity-50 cursor-not-allowed'
            : 'bg-gray-700 bg-opacity-25 backdrop-blur-sm cursor-pointer hover:bg-opacity-40'
        }`}
      >
        {isCapturing ? 'Capturing...' : 'Capture'}
      </button>
    </div>
  );
}

export default Camera;