import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { TiTick, TiTimes } from "react-icons/ti";
import QuestionComments from '../components/QuestionComments';
import { useRequestService } from '../services/RequestService';
import { useImageService } from "../services/ImageService";
import { LazyLoadImage } from "react-lazy-load-image-component";
function ProjectQuestionDetailPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [questionState, setQuestionState] = useState(location.state?.question);
  const requestService = useRequestService();
  const imageService = useImageService();

  const handleScore = async (liked) => {
    try {
      // If clicking the same score, remove it
      if ((liked && questionState.userScore === true) || (!liked && questionState.userScore === false)) {
        setQuestionState(prev => ({ ...prev, userScore: null }));
      } else {
        // Set new score
        setQuestionState(prev => ({ ...prev, userScore: liked }));
      }

      // Make API call to update score
      await requestService.post('scores', {
        id: questionState.id,
        type: 'SWIPE_QUESTION',
        scoring: liked ? '1' : '0'
      });
    } catch (error) {
      console.error('Error updating score:', error);
      // Revert state on error
      setQuestionState(prev => ({ ...prev, userScore: location.state?.question.userScore }));
    }
  };

  return (
    <div id="building-detail-container" className='flex flex-col h-screen overflow-auto pb-24'>
        <div className="h-12 w-full flex items-start justify-left p-2">
          <button
           onClick={() => navigate(-1)}
          className='p-2 hover:bg-gray-100 rounded-full border border-gray-300 w-10 h-10'
          type='button'>
                        <svg 
                xmlns="http://www.w3.org/2000/svg" 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              >
                <path d="M19 12H5M12 19l-7-7 7-7"/>
              </svg>
          </button>
        </div>

        <div className="flex items-center justify-center px-4">
          <div className="border border-gray-200 rounded-md w-full max-w-md flex flex-col">


          {questionState.imageLink ? (
            <>
              <LazyLoadImage
                className="w-full max-w-full w-3/4 object-cover object-center overflow-hidden"
                src={  imageService.getSrcForQuestion(questionState.id, 300, 200) || questionState.imageLink }
                alt="gallery-photo"
                placeholderSrc={imageService.getSrcForQuestion(questionState.id, 300, 200) || questionState.imageLink}
              />
              <div 
                className="flex justify-center items-center p-0 mt-0"
                dangerouslySetInnerHTML={{ __html: questionState.imageSrc }}
              />
            </>
          ) : (
            <div 
              className="flex justify-center items-center p-0"
              dangerouslySetInnerHTML={{ __html: questionState.imageSrc }}
            />
          )}




          <div className="flex flex-row align-middle items-start ml-2 mb-2">
              <button 
                onClick={() => handleScore(false)}
                className={`flex flex-row items-start align-middle mr-3 cursor-pointer`}>
                <div className={`rounded-full ${questionState.userScore === false ? 'bg-red-600' : 'bg-gray-300'} text-white m-1 p-1`}>
                  <TiTimes className="w-7 h-7" />
                </div>
              </button>
              <button 
                onClick={() => handleScore(true)}
                className={`flex flex-row items-start align-middle cursor-pointer`}>
                <div className={`rounded-full ${questionState.userScore === true ? 'bg-green-600' : 'bg-gray-300'} text-white m-1 p-1`}>
                  <TiTick className="w-7 h-7" />
                </div>
              </button>
          </div>

        </div>
      </div>

      <QuestionComments questionId={questionState.id} />
    </div>
  );
}

export default ProjectQuestionDetailPage;    