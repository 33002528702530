import React, { useState, useEffect } from 'react';
import { useQueryClient, useMutation, useInfiniteQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useRequestService } from '../services/RequestService';
import { formatDistanceToNow } from 'date-fns';
import { Puff } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import HashtagInput from './HashtagInput';
import { getPopularHashtags, renderContentWithHashtags } from '../utils/hashtagUtils';

function ProjectideaComments({ projectIdeaId }) {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const requestService = useRequestService();
  const [newComment, setNewComment] = useState('');
  const [replyingTo, setReplyingTo] = useState(null);
  const [replyContent, setReplyContent] = useState('');
  const [expandedComments, setExpandedComments] = useState(new Set());
  const [popularHashtags, setPopularHashtags] = useState([
    'architecture', 'design', 'modern', 'historic', 'renovation', 'sustainable', 'urban'
  ]);
  const { t } = useTranslation();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ['projectideaComments', projectIdeaId],
    queryFn: ({ pageParam = 0 }) => {
      console.log('Fetching page:', pageParam);
      return requestService.get(`projects/${projectId}/project-ideas/${projectIdeaId}/comments?page=${pageParam}`);
    },
    getNextPageParam: (lastPage) => {
      console.log('Last page:', lastPage);
      return !lastPage.last ? lastPage.number + 1 : undefined;
    },
  });


  useEffect(() => {
    if (data?.pages) {
      const allComments = data.pages.flatMap(page => page.content || []);
      const hashtags = getPopularHashtags(allComments);
      setPopularHashtags(hashtags);
    }
  }, [data]);

  const createCommentMutation = useMutation({
    mutationFn: (content) => 
      requestService.post(`projects/${projectId}/project-ideas/${projectIdeaId}/comments`, { content }),
    onSuccess: () => {
      queryClient.invalidateQueries(['projectideaComments', projectIdeaId]);
      setNewComment('');
    }
  });

  const replyMutation = useMutation({
    mutationFn: ({ commentId, content }) => 
      requestService.post(`projects/${projectId}/project-ideas/${projectIdeaId}/comments`, { 
        content,
        parentId: commentId 
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['projectideaComments', projectIdeaId]);
      setReplyingTo(null);
      setReplyContent('');
    }
  });

  const toggleReactionMutation = useMutation({
    mutationFn: ({ commentId, reactionType }) => 
      requestService.post(`comments/${commentId}/reactions?type=${reactionType}`),
    onSuccess: () => {
      queryClient.invalidateQueries(['projectideaComments', projectIdeaId]);
    }
  });

  const toggleReplies = (commentId) => {
    setExpandedComments(prev => {
      const newSet = new Set(prev);
      if (newSet.has(commentId)) {
        newSet.delete(commentId);
      } else {
        newSet.add(commentId);
      }
      return newSet;
    });
  };


  const handleNewCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleReplyChange = (e) => {
    setReplyContent(e.target.value);
  };



  return (
    <div className="mt-6 px-4">
      
      {/* New Comment Form */}
      <form onSubmit={(e) => {
        e.preventDefault();
        if (newComment.trim()) createCommentMutation.mutate(newComment.trim());
      }} className="mb-6">



        <HashtagInput
          id="new-projectidea-comment"
          value={newComment}
          onChange={handleNewCommentChange}
          placeholder={t('nonbuildings.postCommentPlaceholder')}
          rows={3}
          className="focus:ring focus:ring-sky-500"
          popularHashtags={popularHashtags}
          showSuggestionButtons={true}
        />










        <button 
          type="submit"
          disabled={createCommentMutation.isLoading || !newComment.trim()}
          className="mt-2 bg-sky-800 text-white px-4 py-2 rounded-lg hover:bg-sky-700 disabled:opacity-50"
        >
          {t('projects.postComment')}
        </button>
      </form>

      {/* Comments List */}
      {isLoading ? (
        <div className="flex justify-center">
          <Puff color="#0C4A6E" height={50} width={50} />
        </div>
      ) : !data?.pages?.[0]?.content ? (
        <div className="text-center text-gray-500">No comments yet. Be the first to comment!</div>
      ) : (
        <div className="space-y-4">
          {data.pages.map((page, i) => (
            <React.Fragment key={i}>
              {page.content && page.content
                .filter(comment => !comment.parentCommentId) // Only show top-level comments
                .map((comment) => (
                  <div key={comment.id} className="bg-gray-50 rounded-lg p-4">
                    {/* Comment Header */}
                    <div className="flex justify-between items-start">
                      <div>
                        <span className="font-semibold">{comment.author.fullName}</span>
                        <span className="text-gray-500 text-sm ml-2">
                         
                        {(() => {
                              try {
                                if (!comment.createdAt) return '';
                                
                                // Use the same pattern as in other components
                                return formatDistanceToNow(new Date(comment.createdAt), { addSuffix: true });
                              } catch (error) {
                                console.error('Date parsing error:', error, comment.createdAt);
                                return 'some time ago'; // Fallback text
                              }
                            })()}
                        </span>
                      </div>

                    </div>
                    
                    {/* Comment Content with clickable hashtags */}
                    <p className="mt-2">
                      {renderContentWithHashtags(
                        comment.content,
                        (hashtag) => console.log('Hashtag clicked:', hashtag)
                      )}
                    </p>

                    <div className="flex space-x-2 pt-2 mt-2">
                        <button 
                          onClick={() => toggleReactionMutation.mutate({ commentId: comment.id, reactionType: 'LIKE' })}
                          className={`flex items-center justify-between text-gray-600 space-x-1 ${comment.userReaction === 'LIKE' ? 'text-sky-600' : ''}`}
                        >
                          <div>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z" />
                          </svg>
                          </div>
                          <div>{comment.reactionCounts?.['LIKE'] || 0}</div>
                        </button>
                        <button 
                          onClick={() => {
                            toggleReplies(comment.id);
                            setReplyingTo(replyingTo === comment.id ? null : comment.id);
                          }}
                          className="flex items-center justify-between text-gray-600 hover:text-gray-700"
                        >
                          <div>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                          </svg> 
                            </div>
                             <div className='ml-1'>{comment.replyCount} </div>
                        </button>
                      </div>

                    {/* Replies Section */}
                    {expandedComments.has(comment.id) && (
                      <div className="mt-3 space-y-3">
                        {comment.replies?.map((reply) => (
                          <div key={reply.id} className="pl-4 border-l-2 border-gray-300">
                            <div className="bg-gray-100 rounded-lg p-3">
                              <div className="flex justify-between items-start">
                                <div>
                                  <span className="font-semibold">{reply.author.fullName}</span>
                                  <span className="text-gray-500 text-sm ml-2">
                                    {formatDistanceToNow(new Date(Date.UTC(...reply.createdAt)), { addSuffix: true })}
                                  </span>
                                </div>
                                <button 
                                  onClick={() => toggleReactionMutation.mutate({ commentId: reply.id, reactionType: 'LIKE' })}
                                  className={`flex items-center space-x-1 ${reply.userReaction === 'LIKE' ? 'text-sky-600' : ''}`}
                                >
                                  <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z" />
                                    </svg>
                                  </div>
                                  <div>{reply.reactionCounts?.['LIKE'] || 0}</div>
                                </button>
                              </div>
                              <p className="mt-2 text-gray-700">{reply.content}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Reply Form */}
                    {replyingTo === comment.id && (
                      <div className="mt-3 pl-4 border-l-2 border-gray-300">
                        <HashtagInput
                          id={`reply-${comment.id}`}
                          value={replyContent}
                          onChange={handleReplyChange}
                          placeholder={t('common.replyPlaceholder')}
                          rows={2}
                          popularHashtags={popularHashtags}
                          showSuggestionButtons={false}
                        />
                        <div className="flex justify-end space-x-2 mt-2">
                          <button
                            onClick={() => {
                              setReplyingTo(null);
                              setReplyContent('');
                            }}
                            className="text-gray-500 hover:text-gray-700"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => {
                              if (replyContent.trim()) {
                                replyMutation.mutate({
                                  commentId: comment.id,
                                  content: replyContent.trim()
                                });
                              }
                            }}
                            className="bg-sky-800 text-white px-3 py-1 rounded hover:bg-sky-700"
                            disabled={!replyContent.trim()}
                          >
                            Reply
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </React.Fragment>
          ))}

          {hasNextPage && (
            <div className="text-center mt-4">
              <button
                onClick={() => fetchNextPage()}
                className="text-sky-800 hover:text-sky-700"
              >
                Load More Comments
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ProjectideaComments; 