import React, { useState, useEffect } from "react";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Link } from "react-router-dom";
import { MapContainer,  TileLayer, Popup } from 'react-leaflet'
import { useBuildingService } from "../services/BuildingService";
import CustomMarker from "../components/CustomMarker";
import { useTranslation } from 'react-i18next';

function GallerymapPage() {
  const { t } = useTranslation();
  const { trackPageView } = useMatomo()
  const [dorples, setDorples] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const buildingService = useBuildingService();

  const fetchMoreData = () => {
      if (!isLoading && hasMore) {
        setIsLoading(true);

        buildingService.getGallerymap(index).then(data => {
          console.log("Received data:", data);
          setDorples(data);
          setHasMore(data.length > 0);
          setIndex((prevIndex) => prevIndex + 1);
          setIsLoading(false);
        }).catch(error => {
          console.error("Error fetching data:", error)
          setIsLoading(false);
        });
      }
  };

  useEffect(() => {
    fetchMoreData();
    trackPageView();
  }, []);

  console.log("Current dorples state:", dorples);

  return (

<div className="flex flex-col">
  <div id="gallery-nav" className="fixed top-0 z-50 w-full  bg-white text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
    <ul className="flex flex-row -mb-px max-w-lg mx-auto">
      <li className="me-2 w-1/2 border-b-4 border-transparent">
        <a href="/gallery" className="flex items-center justify-center inline-block p-3 rounded-t-lg dark:hover:text-gray-300 text-bold">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
        </svg>
        <span className="font-semibold text-gray-700">
             {t('navigation.gallery_view')}
        </span>
        </a>
      </li>
      <li className="me-2 w-1/2 border-b-4 border-sky-800 bg-sky-50">
        <a href="/gallerymap" className="flex items-center justify-center inline-block p-3 text-sky-800 rounded-t-lg active dark:text-sky-800 dark:border-sky-500  text-bold" aria-current="page">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
        </svg>
        <span className="font-semibold text-sky-800">
             {t('navigation.map_view')}
        </span>
        </a>
      </li>
    </ul>
  </div>

  <MapContainer center={[50.779673, 5.462478285337998]} zoom={15} scrollWheelZoom={false}
  id="gallerymap-container"
  className="w-full h-full mt-12">
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {dorples  && dorples.length > 0 && (
        dorples.map((dorple, index) => {
          console.log("Rendering marker for:", dorple);
          return (
            <CustomMarker 
              key={index} 
              position={[dorple.latitude, dorple.longitude]}
              scoreY={dorple.scorey}
              scoreN={dorple.scoren}
            >
              <Popup>
                <Link
                  to={`/gallerydetail`}
                  state={{ dorple: dorple }}
                  className="bg-sky-50"
                >
                  <img src={dorple.imageLink} alt={dorple.name} className="rounded-sm" width="130" height="160" />
                </Link>
              </Popup>
            </CustomMarker>
          );
        })
      )}

  </MapContainer>

</div>

  );
}

export default GallerymapPage;