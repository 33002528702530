import React, { useState, useEffect } from "react";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Puff } from "react-loader-spinner";
import { useRequestService } from "../services/RequestService";
import { useTranslation } from 'react-i18next';
import ProjectListBadge from '../components/ProjectListBadge';

function formatDate(dateString) {
  if (!dateString) return '';
  try {
    return new Date(dateString).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  } catch (e) {
    console.error('Error formatting date:', e);
    return '';
  }
}

function ProjectsListPage(props) {
  const { trackPageView } = useMatomo()
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]); 
  const [isLoading, setIsLoading] = useState(false);
  const requestService = useRequestService();
  
  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      const data = await requestService.get('projects');
      console.log('Fetched projects:', data);
      
      // Log each project's status for debugging
      data.forEach(project => {
        console.log(`Project ${project.id} - ${project.name}: canAccess=${project.canAccess}, invitationStatus=${project.invitationStatus}`);
      });
      
      setProjects(data);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching projects:', err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
    trackPageView();
  }, []); 

  // Function to refresh the project list after status change
  const handleStatusChange = () => {
    fetchProjects();
  };

  // Group projects by access status
  const accessibleProjects = projects.filter(project => project.canAccess === true);
  const pendingProjects = projects.filter(project => 
    project.canAccess === false && 
    (project.invitationStatus === 'INVITED' || project.invitationStatus === 'PENDING')
  );
  const otherProjects = projects.filter(project => 
    project.canAccess === false && 
    project.invitationStatus !== 'INVITED' && 
    project.invitationStatus !== 'PENDING'
  );
  
  console.log('Projects categorization:', {
    accessible: accessibleProjects.length,
    pending: pendingProjects.length,
    other: otherProjects.length
  });

  return (
    <div className="w-full">
      <div id="gallery-nav" className="fixed top-0 z-50 w-full p-2 bg-white text-center border-b border-gray-200">
        <h1 className="text-xl font-semibold text-sky-800">{t('projects.projects_for_me')}</h1>
      </div>

      <div id="project-list-container" className='mx-auto max-w-lg pt-12 pb-20 z-0 h-screen overflow-auto'>
        <div className="flex flex-col max-w-lg w-full p-4">
          {isLoading ? (
            <div className="flex justify-center mt-4">
              <Puff color="#0ea5e9" height={60} width={60} />
            </div>
          ) : projects.length === 0 ? (
            <p className="text-gray-500 text-center py-8"
              dangerouslySetInnerHTML={{ __html: t('projects.noProjects') }}
            />
          ) : (
            <>
              {/* My Projects Section */}
              {accessibleProjects.length > 0 && (
                <div className="mb-6">
                  <h2 className="font-semibold text-gray-700 mb-3 border-b pb-2">
                    {t('projects.my_projects') || 'My Projects'}
                  </h2>
                  <div className="w-full space-y-2">
                    {accessibleProjects.map((project) => (
                      <ProjectListBadge 
                        key={project.id} 
                        project={project} 
                        onStatusChange={handleStatusChange}
                      />
                    ))}
                  </div>
                </div>
              )}
              
              {/* Pending Projects Section */}
              {pendingProjects.length > 0 && (
                <div className="mb-6">
                  <h2 className="font-semibold text-gray-700 mb-3 border-b pb-2">
                    {t('projects.pending_projects') || 'Pending Projects'}
                  </h2>
                  <div className="w-full">
                    {pendingProjects.map((project) => (
                      <ProjectListBadge 
                        key={project.id} 
                        project={project} 
                        onStatusChange={handleStatusChange}
                      />
                    ))}
                  </div>
                </div>
              )}
              
              {/* Other Projects Section */}
              {otherProjects.length > 0 && (
                <div className="mb-6">
                  <h2 className="font-semibold text-gray-700 mb-3 border-b pb-2">
                    {t('projects.other_projects') || 'Other Projects'}
                  </h2>
                  <div className="w-full">
                    {otherProjects.map((project) => (
                      <ProjectListBadge 
                        key={project.id} 
                        project={project}
                        onStatusChange={handleStatusChange}
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectsListPage;    