import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProjectImgUrl from "../images/project.svg";
import { useRequestService } from '../services/RequestService';
import { Puff } from 'react-loader-spinner';
function ProjectBadge() {
  const { t } = useTranslation();
  const requestService = useRequestService();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [projectsCount, setProjectsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      const data = await requestService.get('projects');
      setProjects(data);
      setProjectsCount(data.length);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching projects:', err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []); // E


  return (

<div>
    {isLoading ? (
      <div className="flex justify-center">
        <Puff color="#0C4A6E" height={10} width={10} />
      </div>
    )  : (

    <div 
      className="p-2 rounded-md flex flex-col items-center cursor-pointer "
      onClick={() => navigate('/projects')}
    >
      <div className={`rounded-full bg-gradient-to-br from-lime-800 via-lime-600 to-lime-800 border border-sky-800 w-14 h-14 flex items-center justify-center`}>
      <img 
                          src={ ProjectImgUrl } 
                          alt="Project"
                          className="w-12 h-12 stroke-lime-800" 
                        />
      </div>
      <h3 className="font-bold text-sky-800">
       {t('projects.projects')}
      </h3>
      <p className="text-xs text-gray-600">  {projectsCount}  {t('projects.projects_for_me')}
      </p>
    </div>


  )}
</div>


  );
}

export default ProjectBadge;