import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequestService } from '../services/RequestService';
import { useQuery } from '@tanstack/react-query';

function ProjectHeader(props) {
  const navigate = useNavigate();
  const requestService = useRequestService();
  const { projectId } = useParams();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Use React Query to fetch and cache projects
  const { data: projects = [], isLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: async () => {
      const response = await requestService.get('projects');
      // Filter projects to only include those with canAccess=true
      return Array.isArray(response) 
        ? response.filter(project => project.canAccess === true)
        : [];
    },
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
  });

  // Find current project from cached data
  const currentProject = projectId ? projects.find(p => p.id === parseInt(projectId)) : null;

  const handleLeaveProject = () => {
    navigate('/home');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleProjectSelect = (project) => {
    // Only navigate if it's a different project
    if (project.id !== parseInt(projectId)) {
      navigate(`/project/${project.id}`);
    }
    setIsMenuOpen(false);
  };

  return (
    <>
      <div className="fixed top-0 z-50 max-h-8 h-8 w-full p-1 mb-3 border-b border-gray-300 bg-lime-200">
        <div className="flex max-w-lg justify-between items-center h-6 px-2">
          <div 
            className='text-sm text-gray-600 flex items-center cursor-pointer truncate'
            onClick={toggleMenu}
          >
            <span className="flex items-center">
              <span className="font-medium">{t('projects.project_mode')}</span>
              {currentProject && (
                <span className="mx-1 truncate max-w-[150px]">: {currentProject.name}</span>
              )}
            </span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-4 w-4 ml-1 flex-shrink-0" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          <div className="ml-auto">
            {/* Right side content if needed */}
          </div>
        </div>
      </div>

      {/* Project Switcher Menu */}
      <div 
        className={`fixed top-8 left-0 right-0 bg-white  rounded-b-xl  transform transition-transform duration-300 ease-in-out z-40 ${
          isMenuOpen ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        {/* Projects List */}
        <div className="p-2 max-h-80 overflow-y-auto">
          {isLoading ? (
            <div className="flex justify-center p-4">
              <svg className="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          ) : projects.length === 0 ? (
            <div className="text-center p-4 text-gray-500">
              {t('projects.no_projects_found')}
            </div>
          ) : (
            projects.map(project => (
              <button 
                key={project.id}
                onClick={() => handleProjectSelect(project)}
                className={`w-full flex items-center p-3 rounded-lg transition-colors ${
                  project.id === parseInt(projectId) 
                    ? 'bg-sky-100 text-sky-700' 
                    : 'hover:bg-gray-100'
                }`}
              >
                <div className="text-left">
                  <div className="font-medium">{project.name}</div>
                  <div className="text-sm text-gray-500">{project.description || t('projects.no_description')}</div>
                </div>
              </button>
            ))
          )}
        </div>

        <div onClick={() => setShowModal(true)} className="flex justify-between items-center p-4 border-t border-gray-200 w-full overflow-y-auto">
          <h3 className="text-md font-semibold text-gray-800">{t('projects.leave_project_mode')}</h3>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25" />
          </svg>
        </div>
      </div>

      {/* Backdrop */}
      {isMenuOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={toggleMenu}
        ></div>
      )}

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-sm mx-4">
            <div className="mb-2">{t('projects.leave_project_mode')}.</div>
            <div className="mb-4">{t('common.continue_question')}</div>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              >
                {t('common.no')}
              </button>
              <button
                onClick={handleLeaveProject}
                className="px-4 py-2 bg-sky-600 text-white rounded-md hover:bg-sky-700"
              >
                {t('common.yes')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProjectHeader;    