import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useRequestService } from '../services/RequestService';
import { toast } from 'react-toastify';

function ProjectListBadge(props) {
  const { project, onStatusChange } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const requestService = useRequestService();
  const [isProcessing, setIsProcessing] = useState(false);
  const [showJoinDialog, setShowJoinDialog] = useState(false);

  // Check if project is active - handles cases where isActive might be undefined, null, or false
  const isProjectActive = project.canAccess === true;

  // Function to render invitation status badge
  const renderInvitationStatus = () => {
    if (!project.invitationStatus) return null;
    
    switch (project.invitationStatus) {
      case 'INVITED':
        return (
          <div className="ml-2 px-2 py-1 text-xs bg-yellow-100 text-yellow-800 rounded-full">
            {t('projects.invitation_pending') || 'Invitation'}
          </div>
        );
      case 'ACCEPTED':
        return (
          <div className="ml-2 px-2 py-1 text-xs bg-green-100 text-green-800 rounded-full">
            {t('projects.invitation_accepted') || 'Accepted'}
          </div>
        );
      case 'PENDING':
        return (
          <div className="ml-2 px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded-full">
            {t('projects.request_pending') || 'Pending'}
          </div>
        );
      default:
        return null;
    }
  };

  // Check if action is allowed for the current invitation status
  const canAcceptInvitation = () => {
    return project.invitationStatus === 'INVITED';
  };

  const canDeclineInvitation = () => {
    return project.invitationStatus === 'INVITED';
  };

  const handleAcceptInvitation = async (e) => {
    if (e) e.stopPropagation(); // Prevent navigation to project page
    
    console.log('Trying to accept invitation with status:', project.invitationStatus);
    
    // Check if invitation status is valid for acceptance
    if (!canAcceptInvitation()) {
      console.log('Cannot accept invitation - invalid status:', project.invitationStatus);
      toast.error(t('projects.invalid_invitation_status') || 'Cannot accept this invitation - invalid status.');
      setShowJoinDialog(false);
      return;
    }
    
    setIsProcessing(true);
    
    try {
      console.log('Sending acceptance request for project:', project.id);
      await requestService.post(`projects/${project.id}/members/accept-invitation`);
      toast.success(t('projects.invitation_accepted_success') || 'Invitation accepted successfully');
      
      // Navigate to project page
      navigate(`/project/${project.id}`);
      
      if (onStatusChange) {
        onStatusChange();
      }
    } catch (error) {
      console.error('Error accepting invitation:', error);
      let errorMessage = t('projects.error_accepting_invitation') || 'Error accepting invitation';
      
      // Handle specific error cases
      if (error.message && error.message.includes('Invalid invitation status')) {
        errorMessage = t('projects.invalid_invitation_status') || 'Cannot accept this invitation - invalid status.';
      }
      
      toast.error(errorMessage);
    } finally {
      setIsProcessing(false);
      setShowJoinDialog(false);
    }
  };

  const handleDeclineInvitation = async (e) => {
    if (e) e.stopPropagation(); // Prevent navigation to project page
    
    console.log('Trying to decline invitation with status:', project.invitationStatus);
    
    // Check if invitation status is valid for rejection
    if (!canDeclineInvitation()) {
      console.log('Cannot decline invitation - invalid status:', project.invitationStatus);
      toast.error(t('projects.invalid_invitation_status') || 'Cannot decline this invitation - invalid status.');
      setShowJoinDialog(false);
      return;
    }
    
    setIsProcessing(true);
    
    try {
      console.log('Sending decline request for project:', project.id);
      await requestService.post(`projects/${project.id}/members/decline-invitation`);
      toast.info(t('projects.invitation_declined_success') || 'Invitation declined');
      
      if (onStatusChange) {
        onStatusChange();
      }
    } catch (error) {
      console.error('Error declining invitation:', error);
      let errorMessage = t('projects.error_declining_invitation') || 'Error declining invitation';
      
      // Handle specific error cases
      if (error.message && error.message.includes('Invalid invitation status')) {
        errorMessage = t('projects.invalid_invitation_status') || 'Cannot decline this invitation - invalid status.';
      }
      
      toast.error(errorMessage);
    } finally {
      setIsProcessing(false);
      setShowJoinDialog(false);
    }
  };

  // Close dialog without any action
  const handleCloseDialog = (e) => {
    if (e) e.stopPropagation();
    setShowJoinDialog(false);
  };

  // Handle project click
  const handleProjectClick = () => {
    console.log('Project clicked:', project.name, 'Status:', project.invitationStatus, 'Active:', isProjectActive);
    
    if (isProjectActive) {
      // If project is active, navigate directly to the project page
      navigate(`/project/${project.id}`);
    } else if (project.invitationStatus === 'INVITED') {
      // Only show join dialog for INVITED projects
      setShowJoinDialog(true);
    } else {
      // For other statuses, show an informational message
      if (project.invitationStatus === 'PENDING') {
        toast.info(t('projects.pending_project_message') || 'Your request to join this project is pending approval.');
      } else {
        toast.info(t('projects.inactive_project_message') || 'You cannot access this project currently.');
      }
    }
  };



  // Render invitation action buttons if the status is INVITED
  const renderInvitationActions = () => {
    // Extra safety check - only show buttons for INVITED status
    if (project.invitationStatus === 'INVITED') {
      return (
        <div className="flex mt-2 space-x-2">
          <button
            onClick={handleAcceptInvitation}
            disabled={isProcessing}
            className="px-3 py-1 text-xs bg-green-600 text-white rounded hover:bg-green-700 disabled:opacity-50"
          >
            {t('projects.accept_invitation') || 'Accept'}
          </button>
          <button
            onClick={handleDeclineInvitation}
            disabled={isProcessing}
            className="px-3 py-1 text-xs bg-red-600 text-white rounded hover:bg-red-700 disabled:opacity-50"
          >
            {t('projects.decline_invitation') || 'Decline'}
          </button>
        </div>
      );
    } else if (project.invitationStatus === 'PENDING') {
      // For pending requests, show a waiting message instead of action buttons
      return (
        <div className="mt-2 text-xs text-gray-500 italic">
          {t('projects.pending_approval') || 'Waiting for approval'}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className={`border-b p-2 flex items-center w-full cursor-pointer hover:bg-gray-50 ${!isProjectActive ? 'opacity-90' : ''}`} onClick={handleProjectClick}>
        <div className="flex-1 text-left">
          <div className="flex items-center">
            <h3 className="font-bold text-sky-800 text-left">
              {project.name}
            </h3>
            {renderInvitationStatus()}
          </div>
          <p className="text-sm text-gray-600">
            {project.description}
          </p>
          {renderInvitationActions()}
        </div>

        {!isProjectActive && (
          <div className="border p-1 rounded-full text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
            </svg>
          </div>
        )}
      </div>
     
    </>
  );
}

export default ProjectListBadge;