import { Link } from "react-router-dom";
import ScreenMap from '../../images/dorple-screen-map.png';
import { useTranslation } from 'react-i18next'; 
import SocialFollow from "../../components/SocialFollow";

function Page4(props) {
    const { t } = useTranslation();
    return (
    <div className="relative min-h-screen flex flex-col bg-gradient-to-b from-pink-300 to-sky-200 m-0">
 
    <div className="absolute z-10 flex flex-col md:flex-row justify-center items-center h-16 max-h-16 align-bottom  w-full">
        <a href="#page-3">
        <div className="border rounded-full bg-sky-800 opacity-50 m-3 p-3">
        <svg aria-label="Scroll up"  fill="none" viewBox="0 0 24 24" strokeWidth="3" className="size-6 stroke-white"><path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" /></svg>
        </div>
        </a>
    </div>


        <div className="container max-w-screen-xl mx-auto flex flex-col  justify-center items-center text-lg w-full mt-10 grow">

            <div className="flex flex-col align-middle items-center text-center md:text-left">
                <span className="text-2xl md:text-8xl text-sky-800 md:p-2 mb-4 text-center">
                {t('login.page5.title')}
                </span>
                <span className="text-lg md:text-3xl text-sky-800 md:p-2 px-4 md:px-0 mb-6 text-center">
                {t('login.page5.description')}
                </span>

            </div>

            <div className="flex flex-col justify-center items-center w-full">
            
            
            
    <ul className="max-w-4xl mx-auto mt-10 divide-y w-full ">
    <li className="w-full">
        <details className="group">
            <summary className="flex items-center gap-3 px-4 py-3 font-medium marker:content-none hover:cursor-pointer">
                <svg className="w-5 h-5 text-gray-500 transition group-open:rotate-90" xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                    </path>
                </svg>
                <span className="w-full text-left">Wat gebeurt er met mijn data?</span>
            </summary>

            <article className="px-4 pb-4">
                <p className="text-left">
                    Jouw data wordt gebruikt om de app te verbeteren en te ontwikkelen. We zullen deze data niet delen met derde partijen.
                </p>
            </article>
        </details>
    </li>
    <li className="w-full">
        <details className="group">
            <summary className="flex items-center gap-3 px-4 py-3 font-medium marker:content-none hover:cursor-pointer">
                <svg className="w-5 h-5 text-gray-500 transition group-open:rotate-90" xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                    </path>
                </svg>
                <span className="w-full text-left">Kan ik mijn badges inruilen?</span>
            </summary>

            <article className="px-4 pb-4">
                <p className="text-left">
                    Dat kan niet. En waarom zou je? De badges tonen aan hoeveel je Dorple gebruikt hebt. Je verdient ze niet voor een ander maar voor jezelf. </p>
            </article>
        </details>
    </li>
    <li className="w-full">
        <details className="group">
            <summary className="flex items-center gap-3 px-4 py-3 font-medium marker:content-none hover:cursor-pointer">
                <svg className="w-5 h-5 text-gray-500 transition group-open:rotate-90" xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                    </path>
                </svg>
                <span>Hoe help ik Dorple te groeien?</span>
            </summary>

            <article className="px-4 pb-4">
                <p className="text-left">
                Je kunt ons helpen groeien door andere gebruikers binnen de app uit te nodigen en je ervaringen te delen binnen onze community. Volg ons op LinkedIn om op de hoogte te blijven van de laatste ontwikkelingen en om je netwerk uit te breiden </p>
            </article>
        </details>
    </li>
    <li className="w-full">
        <details className="group">
            <summary className="flex items-center gap-3 px-4 py-3 font-medium marker:content-none hover:cursor-pointer">
                <svg className="w-5 h-5 text-gray-500 transition group-open:rotate-90" xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                    </path>
                </svg>
                <span>Kan ik met Dorple samenwerken?</span>
            </summary>

            <article className="px-4 pb-4">
                <p className="text-left">
                    Heel graag zelfs. De droom is groot en dat kunnen we zeker niet alleen. </p>
            </article>
        </details>
    </li>


    <li className="w-full">
        <details className="group">
            <summary className="flex items-center gap-3 px-4 py-3 font-medium marker:content-none hover:cursor-pointer">
                <svg className="w-5 h-5 text-gray-500 transition group-open:rotate-90" xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                    </path>
                </svg>
                <span>Wie is Dorple?</span>
            </summary>

            <article className="px-4 pb-4">
                <p className="text-left">
                    Dorple is een idee van Stijn Scholts en is ontwikkeld door Depole.io BV. </p>
            </article>
        </details>
    </li>

















</ul>
            
            
            
             
            </div>













        </div>
        
        <div className="flex flex-row justify-end h-16 max-h-16 align-bottom w-full bottom-0 p-4">
        <SocialFollow />
        </div>


    </div>
    );
}

export default Page4;