export default class RequestService {
  baseAddress;
  _authService;

  constructor() {

    this.baseAddress = process.env.REACT_APP_API_URL || 'http://localhost:8080/api/';

  }

  getAuthService() {
    if (!this._authService) {
      if (!window.authService) {
        console.error('No AuthenticationService instance found in window object');
        return null;
      }
      this._authService = window.authService;
    }
    return this._authService;
  }

  removeEmptyValues = object => Object.fromEntries(Object.entries(object).filter(([_, value]) => value !== undefined && value !== null));

  makeUrl(endpoint, parameters = null, trailingSlash = false) {
    if(Array.isArray(endpoint)) endpoint = endpoint.join("/");
    
    let url = this.baseAddress + endpoint;

    if (trailingSlash && !url.endsWith("/")) url += "/";
    url.replaceAll("//", "/");
  
    if (parameters) {
      parameters = this.removeEmptyValues(parameters);
      if (Object.keys(parameters).length) url += "?" + new URLSearchParams(parameters).toString();
    }
    return url;
  }

  async request(endpoint = "", method = "GET", parameters = null, headers = {}, body = null) {
    try {
      // Initialize default headers
      headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        ...headers
      };

      const authService = this.getAuthService();
      console.debug('Auth service status:', {
        hasAuthService: !!authService,
        isTokenValid: authService?.isTokenValid(),
        token: authService?.token ? 'Present' : 'Not present'
      });

      if (authService && authService.isTokenValid()) {
        const token = authService.token;
        if (!token) {
          console.error('No token found in auth service');
          throw new Error('No valid token found');
        }
        headers['Authorization'] = `Bearer ${token.trim()}`;
        console.debug('Request headers:', {
          ...headers,
          Authorization: 'Bearer [REDACTED]'
        });
      } else {
        console.error('Auth service or token validation failed:', {
          hasAuthService: !!authService,
          isTokenValid: authService?.isTokenValid(),
          token: authService?.token ? 'Present' : 'Not present'
        });
      }

      let url = this.makeUrl(endpoint, parameters);
      console.debug('Making request to:', url);

      const requestOptions = {
        method: method,
        headers: headers,
        mode: 'cors',
        credentials: 'include'
      };

      // Only add body for POST, PUT, PATCH methods
      if (['POST', 'PUT', 'PATCH'].includes(method.toUpperCase()) && body !== null) {
        requestOptions.body = JSON.stringify(body);
      }

      const response = await fetch(url, requestOptions);
      console.debug('Response status:', response.status);
      
      // Check if the response has content
      const contentLength = response.headers.get('Content-Length');
      const hasContent = contentLength === null || parseInt(contentLength) > 0;
      
      // Only try to parse JSON if there's content
      let data;
      if (hasContent) {
        try {
          data = await response.json();
          console.debug('Response data:', data);
        } catch (e) {
          console.warn('Failed to parse response as JSON:', e);
          data = null;
        }
      } else {
        data = null;
      }

      if (!response.ok) {
        // For 403 responses, pass through the error message
        if (response.status === 403) {
          return data;
        }
        throw new Error(data?.error || `HTTP error! status: ${response.status}`);
      }

      return data;
    } catch (ex) {
      console.error("Request failed:", ex);
      throw ex;
    }
  }

  async post(endpoint, body, parameters = null, jsonResponse = true, headers = null, encode) {
    return this.request(endpoint, "POST", parameters, headers, body, jsonResponse, true, encode);
  }

  async postForm(endpoint, formData, headers = {}) {
    try {
      // Create headers with Accept header for JSON responses
      const defaultHeaders = {
        'Accept': 'application/json'
      };
      
      const authService = this.getAuthService();
      if (authService && authService.isTokenValid()) {
        const token = authService.token;
        if (!token) {
          throw new Error('No valid token found');
        }
        defaultHeaders['Authorization'] = `Bearer ${token.trim()}`;
        console.debug('Request headers:', {
          ...defaultHeaders,
          Authorization: 'Bearer [REDACTED]'
        });
      } else {
        console.error('Auth service or token validation failed:', {
          hasAuthService: !!authService,
          isTokenValid: authService?.isTokenValid(),
          token: authService?.token ? 'Present' : 'Not present'
        });
        throw new Error('Authentication required');
      }
      
      // Merge default headers with provided headers
      const finalHeaders = {
        ...defaultHeaders,
        ...headers
      };
      
      let url = this.baseAddress + endpoint;
      console.debug('Making request to:', url);
      
      const response = await fetch(url, {
        method: 'POST',
        headers: finalHeaders,
        body: formData,
        mode: 'cors',
        credentials: 'include'
      });
      
      // Get the content type from the response
      const contentType = response.headers.get('content-type');
      console.debug('Response content type:', contentType);
      
      let data;
      if (contentType && contentType.includes('application/json')) {
        // Handle JSON response
        data = await response.json();
        console.debug('Response data:', data);
      } else if (contentType && contentType.includes('text/html')) {
        // Handle HTML response
        const text = await response.text();
        console.warn('Received HTML response instead of JSON:', text);
        // If the response is OK (200-299), treat it as a success
        if (response.ok) {
          data = { success: true, message: 'Request processed successfully' };
        } else {
          throw new Error('Server returned HTML instead of JSON. This might indicate an authentication issue.');
        }
      } else {
        // Handle empty response or other content types
        if (response.ok) {
          data = { success: true, message: 'Request processed successfully' };
        } else {
          data = null;
        }
      }
      
      if (!response.ok) {
        if (response.status === 403) {
          return data;
        }
        throw new Error(data?.error || `HTTP error! status: ${response.status}`);
      }
      
      return data;
    } catch (ex) {
      console.error("Form post failed:", ex);
      throw ex;
    }
  }

  async get(endpoint, parameters = null, jsonResponse = true, api = true) {
    return this.request(endpoint, "GET", parameters, null, null, jsonResponse, api);
  }

  async put(endpoint, body, parameters = null, jsonResponse = true) {
    return this.request(endpoint, "PUT", parameters, null, body, jsonResponse);
  }

  async delete(endpoint, parameters = null, jsonResponse = true) {
    return this.request(endpoint, "DELETE", parameters, null, null, jsonResponse);
  }

  async updateLanguage(language) {
    try {
      await this.put('auth/language', { language });
      return true;
    } catch (error) {
      console.error('Error updating language:', error);
      return false;
    }
  }
}

// Create a singleton instance
const requestServiceInstance = new RequestService();

export function useRequestService() {
  return requestServiceInstance;
}