import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useInfiniteQuery, useQueryClient, useQuery } from '@tanstack/react-query';
import { useRequestService } from "../services/RequestService";
import { MapContainer, TileLayer, Popup, GeoJSON, useMap } from 'react-leaflet'
import CustomMarker from "../components/CustomMarker";
import GalleryEmpty from "../components/GalleryEmpty";
import { Puff } from "react-loader-spinner";
import { useTranslation } from 'react-i18next';

// Component to recenter map when coordinates change
function MapUpdater({ center, zoom }) {
  const map = useMap();
  
  useEffect(() => {
    if (center && center[0] && center[1]) {
      map.setView(center, zoom);
    }
  }, [center, zoom, map]);
  
  return null;
}

function ProjectGallerymapPage() {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { trackPageView } = useMatomo();
  const [activeFilter, setActiveFilter] = useState(null);
  const requestService = useRequestService();
  const queryClient = useQueryClient();

  // Use React Query for project data
  const { data: projectData, isLoading: isProjectLoading } = useQuery({
    queryKey: ['project', projectId],
    queryFn: async () => {
      const projectdata = await requestService.get(`projects/${projectId}`);
      
      // Ensure centerCoordinates is properly formatted
      if (projectdata && !projectdata.centerCoordinates && projectdata.latitude && projectdata.longitude) {
        projectdata.centerCoordinates = {
          lat: projectdata.latitude,
          lng: projectdata.longitude
        };
      }
      
      return projectdata;
    },
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
    refetchOnMount: false, // Don't refetch when component mounts
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
  });

  // Use React Query for infinite scrolling with optimized settings
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isError,
    isLoading: queryIsLoading,
    refetch
  } = useInfiniteQuery({
    queryKey: ['projectGalleryMap', projectId, activeFilter],
    queryFn: async ({ pageParam = 0 }) => {
      const params = { page: pageParam };
      if (activeFilter) {
        params.filter = activeFilter;
      }
      return requestService.get(`projects/${projectId}/gallerymap`, params);
    },
    getNextPageParam: (lastPage) => lastPage.hasMore ? lastPage.nextPage : undefined,
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
    refetchOnMount: false, // Don't refetch when component mounts
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnReconnect: false, // Don't refetch when reconnecting
    refetchInterval: false, // Don't refetch periodically
  });

  const handleFilterChange = (filter) => {
    // If clicking the active filter, remove the filter
    if (filter === activeFilter) {
      setActiveFilter(null);
    } else {
      setActiveFilter(filter);
    }
  };

  // Effect to track page view only
  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  // Effect to handle filter changes
  useEffect(() => {
    if (activeFilter !== null) {
      // Only refetch if the filter changes
      refetch();
    }
  }, [activeFilter, refetch]);

  const getGeoJsonData = () => {
    if (!projectData?.regionGeoJson) return null;
    try {
      return JSON.parse(projectData.regionGeoJson);
    } catch (error) {
      console.error('Error parsing GeoJSON:', error);
      return null;
    }
  };

  const geoJsonStyle = {
    fillColor: '#4B5563',
    fillOpacity: 0.3,
    color: '#1F2937',
    weight: 2
  };

  // Get center coordinates from dorple or from the first item with valid coordinates
  const getCenterCoordinates = () => {
    // First try to use centerCoordinates from the API response
    if (data?.pages && data.pages.length > 0) {
      const firstPage = data.pages[0];
      
      if (firstPage.centerCoordinates) {
        return [
          firstPage.centerCoordinates.lat,
          firstPage.centerCoordinates.lng
        ];
      }
      
      // Then try to use project.centerCoordinates from the API response
      if (firstPage.project?.centerCoordinates) {
        return [
          firstPage.project.centerCoordinates.lat,
          firstPage.project.centerCoordinates.lng
        ];
      }
      
      // Then try to use the first item with valid coordinates
      const items = firstPage.items || firstPage.content || [];
      
      for (const item of items) {
        if (item?.latitude && item?.longitude && 
            !isNaN(item.latitude) && !isNaN(item.longitude)) {
          return [item.latitude, item.longitude];
        }
      }
    }
    
    // Then try to use projectData's centerCoordinates
    if (projectData?.centerCoordinates?.lat && projectData?.centerCoordinates?.lng) {
      return [projectData.centerCoordinates.lat, projectData.centerCoordinates.lng];
    }
    
    // Default fallback coordinates
    return [50.95695673626373, 5.351329329670331];
  };

  const centerCoordinates = getCenterCoordinates();

  if (isError) {
    return (
      <div className="text-center text-red-600 p-4">
        Error loading gallery
      </div>
    );
  }

  return (
    <div className="w-full top-8">

      {/* Filter buttons */}
      <div id="filter-buttons" className="fixed top-8 z-30 w-full bg-white border-b border-gray-200">
        <div className="overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
          <div className="flex space-x-2 px-2 py-2 min-w-max">
            <button
              onClick={() => handleFilterChange('my_contributions')}
              className={`p-1 rounded-full text-sm font-medium flex-shrink-0 ${
                activeFilter === 'my_contributions' 
                  ? 'bg-sky-600 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>
            </button>           
            <button
              onClick={() => handleFilterChange('buildings')}
              className={`px-3 py-1 rounded-full text-sm font-medium flex items-center flex-shrink-0 ${
                activeFilter === 'buildings' 
                  ? 'bg-sky-600 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {t('navigation.gallery_buildings')}
            </button>
            <button
              onClick={() => handleFilterChange('project_ideas')}
              className={`px-3 py-1 rounded-full text-sm font-medium flex-shrink-0 ${
                activeFilter === 'project_ideas' 
                  ? 'bg-sky-600 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {t('navigation.gallery_project_ideas')}
            </button>
            <button
              onClick={() => handleFilterChange('non_buildings')}
              className={`px-3 py-1 rounded-full text-sm font-medium flex-shrink-0 ${
                activeFilter === 'non_buildings' 
                  ? 'bg-sky-600 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {t('navigation.gallery_non_buildings')}
            </button>
          </div>
        </div>
      </div>

      <div id="gallery-container" className="mx-auto max-w-lg h-screen pt-24">
        {queryIsLoading || isProjectLoading ? (
          <div className="flex justify-center items-center p-4">
            <Puff color="#0C4A6E" height={100} width={100} />
          </div>
        ) : !data?.pages || !data.pages[0] ? (
          <GalleryEmpty />
        ) : (
          <MapContainer 
            center={centerCoordinates} 
            zoom={15} 
            scrollWheelZoom={false}
            id="gallerymap-container"
            className="w-full mb-16 z-0"
          >
            <MapUpdater center={centerCoordinates} zoom={15} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {getGeoJsonData() && (
              <GeoJSON 
                data={getGeoJsonData()} 
                style={geoJsonStyle}
              />
            )}
            {data.pages.map((page, pageIndex) => {
              if (!page) return null;
              
              // Check for different property names that might contain the items
              const items = page.items || page.content || [];
              
              return (
                <React.Fragment key={`page-${pageIndex}`}>
                  {items.map((item) => {
                    // Validate coordinates
                    if (!item?.latitude || !item?.longitude || 
                        isNaN(item.latitude) || isNaN(item.longitude)) {
                      console.warn('Invalid coordinates for item:', item);
                      return null;
                    }

                    // Ensure scores are numbers
                    const scoreY = parseInt(item.scorey) || 0;
                    const scoreN = parseInt(item.scoren) || 0;

                    return (
                      <CustomMarker 
                        key={`marker-${item.id}`}
                        position={[item.latitude, item.longitude]}
                        scoreY={scoreY}
                        scoreN={scoreN}
                      >
                        <Popup>
                          <div className="flex flex-col items-center p-2">
                            <h3 className="font-semibold mb-2">{item.name || 'Unnamed Item'}</h3>
                            {item.imageLink ? (
                              <img 
                                src={item.imageLink} 
                                alt={item.name || 'Item'} 
                                className="rounded-sm w-32 h-40 object-cover mb-2" 
                              />
                            ) : item.imageSrc ? (
                              item.type === 'NON_BUILDING' || item.type === 'PROJECT_IDEA' || item.type === 'SWIPE_QUESTION' ? (
                                <div 
                                  className="w-32 h-40 flex items-center justify-center"
                                  dangerouslySetInnerHTML={{ __html: item.imageSrc }}
                                />
                              ) : (
                                <img 
                                  src={item.imageSrc} 
                                  alt={item.name || 'Item'} 
                                  className="rounded-sm w-32 h-40 object-cover mb-2" 
                                />
                              )
                            ) : (
                              <div className="w-32 h-40 bg-sky-50 flex items-center justify-center">
                                <span className="text-sky-800">No image</span>
                              </div>
                            )}
                            <div className="text-sm text-gray-600">
                              {item.location || (
                                item.type === 'PROJECT_IDEA' 
                                  ? 'Project Idea' 
                                  : item.type === 'SWIPE_QUESTION' 
                                    ? 'Question' 
                                    : item.type === 'NON_BUILDING'
                                      ? 'Non-Building'
                                      : 'No location specified'
                              )}
                            </div>
                            {item.commentCount > 0 && (
                              <div className="flex items-center mt-2 text-sm text-gray-600">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                </svg>
                                {item.commentCount} {item.commentCount === 1 ? 'comment' : 'comments'}
                              </div>
                            )}
                          </div>
                        </Popup>
                      </CustomMarker>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </MapContainer>
        )}
      </div>
    </div>
  );
}

export default ProjectGallerymapPage;