import React, { Fragment, useState } from 'react';
import DorpleLogo from '../images/logo-large.svg';
import "@fontsource/raleway/700.css";
import '@fontsource-variable/outfit';
import { useTranslation } from 'react-i18next';
import ScoringBadge from '../components/ScoringBadge';
import ProjectBadge from '../components/ProjectBadge';
import '@khmyznikov/pwa-install';


function HomePage() {
  const { t } = useTranslation();


  return (
    <div className="flex flex-col items-center justify-center mb-14">
      <pwa-install></pwa-install>
      <div className="flex flex-row items-center justify-center p-5 pb-5">
        <div id="frontlogo" className="w-42 max-w-42 p-2 flex justify-center items-center">
          <img src={DorpleLogo} alt="Dorple Logo" className="bg-white bg-opacity-75 w-16 h-16 max-w-16 m-2"/>
        </div>
        <div className="flex flex-col items-start justify-center">
        <h1 className="text-5xl text-sky-800 apptitle font-bold">Dorple</h1>
        <p className="text-xl text-sky-800 appsubtitle font-bold">{t('home.subtitle')}</p>
        </div>
      </div>
      <ScoringBadge />
      <ProjectBadge />


    </div>
  );
}

export default HomePage;
