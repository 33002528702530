import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { LazyLoadImage } from "react-lazy-load-image-component";
import Building from '../images/building.svg';
import { Puff } from "react-loader-spinner";
import ProjectGalleryEmpty from "../components/ProjectGalleryEmpty";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRequestService } from "../services/RequestService";
import { useImageService } from "../services/ImageService";
import { useTranslation } from 'react-i18next';
import { TiTimes, TiTick } from 'react-icons/ti';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';

// Create a data URL for the fallback image with 3:4 aspect ratio
const fallbackImage = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMDAiIGhlaWdodD0iNDAwIiB2aWV3Qm94PSIwIDAgMzAwIDQwMCI+PHJlY3Qgd2lkdGg9IjMwMCIgaGVpZ2h0PSI0MDAiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=';

function ProjectGalleryPage() {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const { trackPageView } = useMatomo()
  const [activeFilter, setActiveFilter] = useState(null); // null means no filter (show all)
  const [showLoading, setShowLoading] = useState(false);
  const requestService = useRequestService();
  const imageService = useImageService();

  // Add a delay before showing loading state
  useEffect(() => {
    let timeoutId;
    if (showLoading) {
      timeoutId = setTimeout(() => {
        setShowLoading(true);
      }, 50); // Wait 50ms before showing loading state
    } else {
      setShowLoading(false);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showLoading]);

  const handleImageError = (e) => {
    console.log('Image load error:', e.target.src);
    e.target.onerror = null; // Prevent infinite loop
    e.target.src = fallbackImage;
    console.log('Set fallback image:', fallbackImage);
  };

  // Use React Query for infinite scrolling
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError
  } = useInfiniteQuery({
    queryKey: ['projectGallery', projectId, activeFilter],
    queryFn: async ({ pageParam = 0 }) => {
      const params = { page: pageParam, size: 50 };
      if (activeFilter) {
        params.filter = activeFilter;
      }
      const response = await requestService.get(`projects/${projectId}/gallery`, params, {
        timeout: 30000
      });
      return response;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.last) return undefined;
      return lastPage.number + 1;
    },
    initialPageParam: 0
  });

  const handleFilterChange = (filter) => {
    // If clicking the active filter, remove the filter
    if (filter === activeFilter) {
      setActiveFilter(null);
    } else {
      setActiveFilter(filter);
    }
  };

  // Effect to track page view
  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  // Memoize the renderEmptyState function
  const renderEmptyState = useCallback(() => {
    if (isLoading) {
      return (
        <div className="flex items-center justify-center w-full min-h-[60vh]">
          <Puff
            visible={true}
            height={100}
            width={100}
            color="#0C4A6E"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperclassName=""
          />
        </div>
      );
    }

    if (activeFilter === 'non_buildings') {
      return (
        <div className="flex flex-col items-center justify-center w-full min-h-[60vh] text-center p-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 text-sky-600 mb-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
          </svg>
          <h2 className="text-xl font-bold text-sky-800 mb-2">{t('gallery.no_meaningful_places')}</h2>
          <p className="text-gray-600 mb-6">{t('gallery.be_first_meaningful_place')}</p>
          <Link 
            to={`/project/${projectId}/capture?type=nonbuilding`}
            className="px-4 py-2 bg-sky-600 text-white rounded-md hover:bg-sky-700 transition-colors"
          >
            {t('gallery.add_meaningful_place')}
          </Link>
        </div>
      );
    } else if (activeFilter === 'project_ideas') {
      return (
        <div className="flex flex-col items-center justify-center w-full min-h-[60vh] text-center p-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 text-sky-600 mb-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
          </svg>
          <h2 className="text-xl font-bold text-sky-800 mb-2">{t('gallery.no_project_ideas')}</h2>
          <p className="text-gray-600 mb-6">{t('gallery.be_first_project_idea')}</p>
          <Link 
            to={`/project/${projectId}/capture?type=projectidea`}
            className="px-4 py-2 bg-sky-600 text-white rounded-md hover:bg-sky-700 transition-colors"
          >
            {t('gallery.add_project_idea')}
          </Link>
        </div>
      );
    } else {
      return <ProjectGalleryEmpty projectId={projectId} />;
    }
  }, [isLoading, activeFilter, projectId, t]);

  // Memoize the flattened data
  const dorples = useMemo(() => 
    data?.pages
      .flatMap(page => page.content || [])
      .filter(item => item && typeof item === 'object' && 'type' in item) || [],
    [data?.pages]
  );

  const renderCardContent = (item) => {
    if (item.type === 'BUILDING') {
      return (
        <>
          <LazyLoadImage
            className="w-full max-w-full w-3/4 h-4/4 object-cover object-center overflow-hidden"
            src={imageService.getSrcForBuilding(item.id, 150, 200) || item.imageLink || Building}
            alt="gallery-photo"
            placeholderSrc={fallbackImage}
            onError={handleImageError}
          />
        </>
      );
    } else if(item.type === 'NON_BUILDING') {
      return (
        <>
          <LazyLoadImage
            className="w-full max-w-full w-3/4 object-cover object-center overflow-hidden"
            src={imageService.getSrcForNonBuilding(item.id, 150, 200) || item.imageLink}
            alt="gallery-photo"
            placeholderSrc={fallbackImage}
            onError={handleImageError}
          />
        </>
      );
    } else if (item.type === 'SWIPE_QUESTION') {
      return (
        <div className='w-full max-w-full w-3/4 object-cover object-center overflow-hidden'>
          {item.imageLink ? (
            <>
              <LazyLoadImage
                className="w-full max-w-full w-3/4 object-cover object-center overflow-hidden"
                src={imageService.getSrcForQuestion(item.id, 150, 100) || item.imageLink}
                alt="swipe-photo"
                placeholderSrc={fallbackImage}
                onError={handleImageError}
              />
              <div 
                className="flex justify-center items-center p-0 mt-0"
                dangerouslySetInnerHTML={{ __html: item.imageSrc }}
              />
            </>
          ) : (
            <div 
              className="flex justify-center items-center p-0"
              dangerouslySetInnerHTML={{ __html: item.imageSrc }}
            />
          )}
        </div>
      );
    } else if (item.type === 'PROJECT_IDEA') {
      return (
        <div className='w-full max-w-full w-3/4 object-cover object-center overflow-hidden'>
          <div 
            className="flex justify-center items-center p-0"
            dangerouslySetInnerHTML={{ __html: item.imageSrc }}
          />
        </div>
      );
    }
  };

  return (
    <div className="w-full top-8">
      {/* Filter buttons */}
      <div id="filter-buttons" className="fixed top-8 z-30 w-full bg-white border-b border-gray-200">
        <div className="overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
          <div className="flex space-x-2 px-2 py-2 min-w-max">
            <button
              onClick={() => handleFilterChange('my_contributions')}
              className={`p-1 rounded-full text-sm font-medium flex-shrink-0 ${
                activeFilter === 'my_contributions' 
                  ? 'bg-sky-600 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>
            </button>           
            <button
              onClick={() => handleFilterChange('buildings')}
              className={`px-3 py-1 rounded-full text-sm font-medium flex items-center flex-shrink-0 ${
                activeFilter === 'buildings' 
                  ? 'bg-sky-600 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {t('navigation.gallery_buildings')}
            </button>
            <button
              onClick={() => handleFilterChange('swipe_questions')}
              className={`px-3 py-1 rounded-full text-sm font-medium flex-shrink-0 ${
                activeFilter === 'swipe_questions' 
                  ? 'bg-sky-600 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {t('navigation.gallery_swipe_questions')}
            </button>
            <button
              onClick={() => handleFilterChange('project_ideas')}
              className={`px-3 py-1 rounded-full text-sm font-medium flex-shrink-0 ${
                activeFilter === 'project_ideas' 
                  ? 'bg-sky-600 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {t('navigation.gallery_project_ideas')}
            </button>
            <button
              onClick={() => handleFilterChange('non_buildings')}
              className={`px-3 py-1 rounded-full text-sm font-medium flex-shrink-0 ${
                activeFilter === 'non_buildings' 
                  ? 'bg-sky-600 text-white' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {t('navigation.gallery_non_buildings')}
            </button>
          </div>
        </div>
      </div>
      
      <div id="gallery-container" className="mx-auto max-w-lg pt-28 z-0 h-screen overflow-auto p-2">
        {
          dorples && dorples.length > 0 ? (
            <InfiniteScroll
              dataLength={dorples.length}
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={
                isFetchingNextPage && (
                  <div className="flex items-center justify-center w-full min-h-[80vh] col-span-3">
                    <Puff
                      visible={true}
                      height={100}
                      width={100}
                      color="#0C4A6E"
                      ariaLabel="puff-loading"
                      wrapperStyle={{}}
                      wrapperclassName=""
                    />
                  </div>
                )
              }
              scrollThreshold={0.5}
              className="grid grid-cols-3 gap-2 sm:grid-cols-3 md:grid-cols-3"
              scrollableTarget="gallery-container"
            >
              {dorples.map((dorple, index) => {
                // Skip invalid items
                if (!dorple || typeof dorple !== 'object' || !('type' in dorple)) {
                  console.warn('Invalid item in gallery:', dorple);
                  return null;
                }

                // Generate a unique key based on type and id
                const keyPrefix = {
                  'BUILDING': 'bld',
                  'NON_BUILDING': 'nbl',
                  'SWIPE_QUESTION': 'swq',
                  'PROJECT_IDEA': 'pid'
                }[dorple.type] || 'unk';

                return (
                  <div className="gallery-tile" key={`${keyPrefix}-${dorple.id}-${index}`}>
                    <Link
                      to={dorple.type === "SWIPE_QUESTION" 
                        ? `/project/${projectId}/questiondetail` 
                        : dorple.type === "PROJECT_IDEA"
                          ? `/project/${projectId}/projectideadetail`
                          : dorple.type === "NON_BUILDING"
                            ? `/project/${projectId}/nonbuildingdetail`
                            : `/project/${projectId}/buildingdetail`}
                      state={dorple.type === "SWIPE_QUESTION" 
                        ? { question: {
                            id: dorple.id,
                            name: dorple.name,
                            questionText: dorple.questionText,
                            questionType: dorple.questionType,
                            imageSrc: dorple.imageSrc,
                            imageLink: dorple.imageLink,
                            scoren: dorple.scoren,
                            scorey: dorple.scorey,
                            userScore: dorple.userScore
                          }}
                        : dorple.type === "NON_BUILDING"
                        ? { nonBuilding: {
                            id: dorple.id,
                            name: dorple.name,
                            description: dorple.name,
                            category: dorple.category,
                            coordinates: dorple.coordinates,
                            latitude: dorple.latitude,
                            longitude: dorple.longitude,
                            imageLink: dorple.imageLink,
                            imageSrc: dorple.imageSrc,
                            scoren: dorple.scoren,
                            scorey: dorple.scorey,
                            userScore: dorple.userScore
                          }}
                        : dorple.type === "PROJECT_IDEA"
                          ? { projectIdea: {
                              id: dorple.id,
                              name: dorple.name,
                              description: dorple.name,
                              category: dorple.category,
                              coordinates: dorple.coordinates,
                              latitude: dorple.latitude,
                              longitude: dorple.longitude,
                              photoUrl: dorple.imageLink,
                              imageSrc: dorple.imageSrc,
                              scoren: dorple.scoren,
                              scorey: dorple.scorey,
                              userScore: dorple.userScore
                            }}
                          : { building: {
                              id: dorple.id,
                              name: dorple.name,
                              location: dorple.location,
                              imageLink: dorple.imageLink,
                              scoren: dorple.scoren,
                              scorey: dorple.scorey,
                              userScore: dorple.userScore
                            }}
                      }
                      className="bg-sky-50 border border-gray-200"
                    >
                      {renderCardContent(dorple)}

                      <div className="flex flex-row justify-between align-middle mr-2">
                        <div className="flex flex-row items-start align-middle">
                          <div className="m-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.068.157 2.148.279 3.238.364.466.037.893.281 1.153.671L12 21l2.652-3.978c.26-.39.687-.634 1.153-.67 1.09-.086 2.17-.208 3.238-.365 1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                            </svg> 
                          </div>
                          <div className="m-1 text-sm text-sky-700">{dorple.commentCount || 0}</div>
                        </div>
                        <div className="flex flex-row align-middle items-start ml-2 mb-2">
                          {dorple.userScore === false && (
                            <div className="flex flex-row items-start align-middle">
                              <div className="rounded-full bg-red-600 text-white m-1">
                                <TiTimes className="w-4 h-4" />
                              </div>
                            </div>
                          )}
                          {dorple.userScore === true && (
                            <div className="flex flex-row items-start align-middle">
                              <div className="rounded-full bg-green-600 text-white m-1">
                                <TiTick className="w-4 h-4" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : renderEmptyState()
        }
      </div>
    </div>
  );
}

export default ProjectGalleryPage;