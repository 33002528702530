import React, { useState, useEffect, useCallback } from "react";
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InfiniteScroll from "react-infinite-scroll-component";
import { Puff } from "react-loader-spinner";
import { useBuildingService } from "../services/BuildingService";
import GalleryEmpty from "../components/GalleryEmpty";
import { useTranslation } from 'react-i18next';
import { useImageService } from "../services/ImageService";

// Create a data URL for the fallback image with 3:4 aspect ratio
const fallbackImage = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMDAiIGhlaWdodD0iNDAwIiB2aWV3Qm94PSIwIDAgMzAwIDQwMCI+PHJlY3Qgd2lkdGg9IjMwMCIgaGVpZ2h0PSI0MDAiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=';

function GalleryPage() {
  const { t } = useTranslation();
  const { trackPageView } = useMatomo()
  const [dorples, setDorples] = useState([]); // Hier slaan we de data op
  const [hasMore, setHasMore] = useState(true);
  const [imageWidth, setImageWidth] = useState(150);
  const [imageHeight, setImageHeight] = useState(200);
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const buildingService = useBuildingService();
  const imageService = useImageService();

  const handleImageError = (e) => {
    console.log('Image load error:', e.target.src);
    e.target.onerror = null; // Prevent infinite loop
    e.target.src = fallbackImage;
    console.log('Set fallback image:', fallbackImage);
  };

  const fetchMoreData = useCallback(() => {
      if (!isLoading && hasMore) {
        setIsLoading(true);

        buildingService.getGallery(index)
          .then(data => {
            if (Array.isArray(data)) {
              setDorples(prevDorples => prevDorples.concat(data));
              setHasMore(data.length > 0);
              setIndex(prevIndex => ++prevIndex);
            }
            setIsLoading(false);
          })
          .catch(error => {
            console.error("Error fetching data:", error);
            setHasMore(false);
            setIsLoading(false);
          });
      } else console.log("I'm fetching data;");
  }, [buildingService, index, isLoading, hasMore]);

  useEffect(() => {
    fetchMoreData();
    trackPageView();
  }, []); // Empty dependency array means run once on mount
  // This is dangerous though, it could get stuck in a loop.

  return <div className="flex flex-col">
    <div id="gallery-nav" className="fixed top-0 z-50 w-full bg-white text-sm font-medium text-center border-b border-gray-200">
      <ul className="flex flex-row -mb-px max-w-lg mx-auto">
      <li className="me-2 w-1/2 border-b-4 border-sky-800 bg-sky-50">
          <a href="/gallery" className="flex items-center justify-center inline-block  p-3 text-sky-800 text-bold">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
          </svg>
          <span className="font-semibold text-sky-800">
              {t('navigation.gallery_view')}
          </span>
          </a>
        </li>
        <li className="me-2 w-1/2 border-b-4 border-transparent">
          <a href="/gallerymap" className="flex items-center justify-center inline-block  p-3 text-bold">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
          </svg>
          <span className="font-semibold text-gray-700">
              {t('navigation.map_view')}
          </span>

          </a>
        </li>
      </ul>
    </div>
    
    <div id="gallery-container" className="mx-auto max-w-lg pt-16 z-0 h-screen overflow-auto">
      {
        dorples && !dorples.length && (!hasMore || !isLoading) ? <GalleryEmpty /> :
      <InfiniteScroll
        dataLength={dorples.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={  <div className="flex items-center justify-center w-full min-h-[80vh] col-span-3">
          <Puff
            visible={true}
            height={100}
            width={100}
            color="#0C4A6E"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperclassName=""
          />
        </div>}
        scrollThreshold={0.8}
        className="grid grid-cols-3 gap-2 sm:grid-cols-3 md:grid-cols-3"
        scrollableTarget="gallery-container"
      >
        {dorples &&
          dorples
            .map((dorple, index) => (
              <div className="gallery-tile" key={index}>
                <Link
                  to={`/gallerydetail`}
                  state={{ dorple: dorple }}
                  className="bg-sky-50"
                >
                  <LazyLoadImage 
                    className="w-full max-w-full h-[20vh] md:h-[30vh] object-cover object-center overflow-hidden overflow-hidden"
                    src={imageService.getSrcForBuilding(dorple.id, imageWidth, imageHeight) || dorple.imageLink}
                    alt={dorple.name || "Building"}
                    placeholderSrc={fallbackImage}
                    onError={handleImageError}
                  />
                  <progress className="[&::-webkit-progress-bar]:bg-green-600 [&::-webkit-progress-value]:bg-red-600 [&::-moz-progress-bar]:bg-red-600" value={dorple.scoren} max={dorple.scoren + dorple.scorey}> </progress>
                </Link>
              </div>
            ))}
      </InfiniteScroll>
      }
    </div>
  </div>;
}

export default GalleryPage;