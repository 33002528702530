import { useTranslation } from "react-i18next";

export default function SwipeCongrats() {
  const {t} = useTranslation();

  return (
    <div style={{ textAlign: 'center', padding: '20px' }} className="flex flex-col items-center justify-center">

        <h1 className="text-4xl text-sky-800 apptitle font-bold  pt-10">{t("oops")}</h1>
        <p className="text-lg text-sky-800 ">{t("gallery.empty")}<br />{t("gallery.swipeToFill")}</p>

        <div className="flex w-full justify-center pt-10">
        <a href="/swipe" className="shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-md m-3 p-2  rounded">Start met Swipen</a>
      </div>


    </div>
  );
}