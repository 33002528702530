import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Puff } from "react-loader-spinner";
import { useRequestService } from '../services/RequestService';
import { toast } from 'react-toastify';

function ProjectProfilePage() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const requestService = useRequestService();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [areaUsages, setAreaUsages] = useState([]);
  const [selectedUsages, setSelectedUsages] = useState([]);
  const [projectName, setProjectName] = useState('');

  // Fetch project profile data
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setLoading(true);
        
        // First get the project details to show the project name
        const projectData = await requestService.get(`projects/${projectId}`);
        setProjectName(projectData.name);
        
        // Then get the profile data
        const profileData = await requestService.get(`projects/${projectId}/profile`);
        
        // If we get an array, it means we don't have a profile yet and these are the available options
        if (Array.isArray(profileData)) {
          setAreaUsages(profileData);
        } else {
          // We have a profile, set the selected usages
          setAreaUsages(profileData.areaUsages || []);
          setSelectedUsages(profileData.areaUsages || []);
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
        toast.error(t('projects.profile.loading_error'));
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [projectId, requestService, t]);

  // Handle checkbox change
  const handleUsageChange = (usage) => {
    setSelectedUsages(prev => {
      if (prev.includes(usage)) {
        return prev.filter(u => u !== usage);
      } else {
        return [...prev, usage];
      }
    });
  };

  // Submit the profile
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (selectedUsages.length === 0) {
      toast.error(t('projects.profile.select_at_least_one'));
      return;
    }
    
    try {
      setSubmitting(true);
      
      await requestService.post(`projects/${projectId}/profile`, {
        areaUsages: selectedUsages
      });
      
      toast.success(t('projects.profile.success'));
      
      // Navigate to the project page
      navigate(`/project/${projectId}`);
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error(t('projects.profile.error'));
    } finally {
      setSubmitting(false);
    }
  };

  // Get the display name for a usage
  const getUsageDisplayName = (usage) => {
    switch (usage) {
      case 'LIVE':
        return t('projects.profile.living');
      case 'WORK':
        return t('projects.profile.working');
      case 'STUDY':
        return t('projects.profile.studying');
      case 'LEISURE':
        return t('projects.profile.leisure');
      case 'PASSING_THROUGH':
        return t('projects.profile.passing_through');
      default:
        return usage;
    }
  };

  return (
    <div className="flex flex-col h-full pb-7 overflow-y-auto">
      {/* Content */}
      <div className="flex-1 p-4">
        {loading ? (
            <div className="flex justify-center mt-4">
            <Puff color="#0ea5e9" height={50} width={50} />
          </div>
        ) : (
          <div className="max-w-md mx-auto">
            <div className="text-center mb-6">
              <h2 className="text-xl font-bold text-sky-800">{projectName}</h2>
              <p className="text-gray-600 mt-2">
                {t('projects.profile.description')}
              </p>
            </div>
            
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold mb-2">
                  {t('projects.profile.area_usage_question')}
                </h3>
                <p className="text-gray-600 mb-2 text-sm">
                  {t('projects.profile.select_all')}
                </p>
                
                <div className="space-y-3">
                  {areaUsages.map((usage) => (
                    <label 
                      key={usage} 
                      className="flex items-center space-x-3 p-3 border rounded-lg hover:bg-gray-50 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        checked={selectedUsages.includes(usage)}
                        onChange={() => handleUsageChange(usage)}
                        className="h-5 w-5 text-sky-600 rounded"
                      />
                      <span>{getUsageDisplayName(usage)}</span>
                    </label>
                  ))}
                </div>
              </div>
              
              <button
                type="submit"
                disabled={submitting || selectedUsages.length === 0}
                className="w-full py-3 px-4 bg-sky-700 text-white rounded-lg hover:bg-sky-800 disabled:opacity-50"
              >
                {submitting ? t('projects.profile.saving') : t('projects.profile.continue')}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectProfilePage; 