import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBuildingService } from "../../services/BuildingService";
import { useRequestService } from "../../services/RequestService";
import { useTranslation } from 'react-i18next';
import { MapContainer, TileLayer, Marker, GeoJSON, useMapEvents, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { toast } from 'react-toastify';

// Fix for default marker icon in Leaflet
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});
L.Marker.prototype.options.icon = DefaultIcon;

// Simple function to check if a point is inside a polygon using ray casting algorithm
function isPointInPolygon(point, polygon) {
  try {
    if (!polygon || !polygon.geometry || !polygon.geometry.coordinates || !polygon.geometry.coordinates[0]) {
      return true; // If no polygon is provided, assume the point is valid
    }
    
    const x = point.lng;
    const y = point.lat;
    
    // Get the coordinates of the polygon (first ring)
    const coords = polygon.geometry.coordinates[0];
    
    let inside = false;
    for (let i = 0, j = coords.length - 1; i < coords.length; j = i++) {
      const xi = coords[i][0], yi = coords[i][1];
      const xj = coords[j][0], yj = coords[j][1];
      
      const intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    
    return inside;
  } catch (error) {
    console.error('Error checking if point is in polygon:', error);
    return true; // In case of error, allow the point
  }
}

// Component to handle map clicks and set marker
function LocationMarker({ position, setPosition, projectBoundary }) {
  const map = useMapEvents({
    click(e) {
      // Check if the clicked position is within the project boundary
      if (projectBoundary && !isPointInPolygon(e.latlng, projectBoundary)) {
        toast.warning('Please select a location within the project boundary');
        return;
      }
      
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    }
  });

  return position ? <Marker position={position} /> : null;
}

// Component to recenter map when coordinates change
function MapUpdater({ center, zoom }) {
  const map = useMap();
  
  useEffect(() => {
    if (center && center[0] && center[1]) {
      map.flyTo(center, zoom, {
        duration: 1.5,
        easeLinearity: 0.5
      });
    }
  }, [center, zoom, map]);
  
  return null;
}

function SubmitBuilding() {
  const buildingService = useBuildingService();
  const requestService = useRequestService();
  const location = useLocation();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [position, setPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]); // Default center
  const [projectBoundary, setProjectBoundary] = useState(null);
  const [nearbyBuilding, setNearbyBuilding] = useState(null);
  const [nearbyBuildings, setNearbyBuildings] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showBuildingList, setShowBuildingList] = useState(false);
  const [searchRadius] = useState(50); // Fixed search radius of 50 meters
  const [isDirectSubmission, setIsDirectSubmission] = useState(false);
  const [isLocating, setIsLocating] = useState(false);
  const [submissionProgress, setSubmissionProgress] = useState(0);
  const [submissionError, setSubmissionError] = useState(null);
  const [error, setError] = useState(null);

  // Helper function to validate and format center coordinates
  const validateCenterCoordinates = (coords) => {
    if (!coords) return null;
    
    // Check if coords is in the format {lat: number, lng: number}
    if (typeof coords.lat === 'number' && typeof coords.lng === 'number') {
      return [coords.lat, coords.lng];
    }
    
    // Check if coords is already in the format [lat, lng]
    if (Array.isArray(coords) && coords.length === 2 && 
        typeof coords[0] === 'number' && typeof coords[1] === 'number') {
      return coords;
    }
    
    console.error('Invalid center coordinates format:', coords);
    return null;
  };

  // Function to get user's current location
  const getCurrentLocation = () => {
    setError(null);
    setIsLocating(true);
    
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          
          console.log("Current location detected:", newPosition);
          
          // Set map center to current location
          setMapCenter([newPosition.lat, newPosition.lng]);
          
          // Only set position marker if within project boundary
          if (projectBoundary && isPointInPolygon(newPosition, projectBoundary)) {
            setPosition(newPosition);
          } else if (projectBoundary) {
            toast.warning(t('capture.submitBuilding.outsideBoundary') || 
              'Your current location is outside the project boundary. Please select a location within the boundary.');
          } else {
            setPosition(newPosition);
          }
          
          setIsLocating(false);
        },
        (error) => {
          console.error("Error detecting location:", error);
          toast.error(t('capture.submitBuilding.locationError') || "Could not detect your location");
          setIsLocating(false);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
      );
    } else {
      toast.error(t('capture.submitBuilding.geolocationNotSupported') || "Geolocation is not supported by your browser");
      setIsLocating(false);
    }
  };

  // Fetch project boundary data and set initial location
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const projectData = await requestService.get(`projects/${projectId}`);
        console.log('Project data:', projectData);
        
        if (projectData) {
          // Parse regionGeoJson if it's a string
          let boundary = null;
          if (projectData.regionGeoJson) {
            try {
              boundary = typeof projectData.regionGeoJson === 'string' 
                ? JSON.parse(projectData.regionGeoJson) 
                : projectData.regionGeoJson;
              setProjectBoundary(boundary);
            } catch (error) {
              console.error('Error parsing regionGeoJson:', error);
            }
          }
          
          // Set map center using centerCoordinates if available
          if (projectData.centerCoordinates) {
            const validatedCenter = validateCenterCoordinates(projectData.centerCoordinates);
            if (validatedCenter) {
              console.log('Setting map center to project center:', validatedCenter);
              setMapCenter(validatedCenter);
            } else {
              console.warn('Invalid centerCoordinates in project data:', projectData.centerCoordinates);
              
              // Fallback to calculating center from boundary
              if (boundary && boundary.geometry && 
                  boundary.geometry.coordinates && 
                  boundary.geometry.coordinates.length > 0) {
                
                // For Polygon, calculate center from first ring
                const coords = boundary.geometry.coordinates[0];
                if (coords && coords.length > 0) {
                  // Calculate center of polygon
                  let lat = 0, lng = 0;
                  coords.forEach(coord => {
                    lng += coord[0];
                    lat += coord[1];
                  });
                  lat /= coords.length;
                  lng /= coords.length;
                  const newCenter = [lat, lng];
                  console.log('Calculated center from boundary:', newCenter);
                  setMapCenter(newCenter);
                }
              }
            }
          }
          
          // After setting project data, try to get user's current location
          getCurrentLocation();
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
        toast.error(t('Error loading project data'));
        
        // Even if project data fails, try to get user's location
        getCurrentLocation();
      }
    };

    fetchProjectData();
  }, [projectId, requestService, t]);

  const detectLocation = (e) => {
    e.preventDefault();
    getCurrentLocation();
  };

  const checkNearbyBuildings = async () => {
    if (!position || typeof position.lng !== 'number' || typeof position.lat !== 'number') {
      toast.error(t('capture.submitBuilding.selectLocation') || "Please select a valid location on the map");
      return false; // Return false to indicate failure
    }
    
    // Validate that the position is within the project boundary
    if (projectBoundary) {
      const isInBoundary = isPointInPolygon(position, projectBoundary);
      
      if (!isInBoundary) {
        toast.error(t('Please select a location within the project boundary'));
        return false; // Return false to indicate failure
      }
    }

    setIsChecking(true);
    setNearbyBuilding(null);
    setNearbyBuildings([]);
    setShowConfirmation(false);
    setShowBuildingList(false);
    setIsDirectSubmission(false);
    setSubmissionError(null);

    try {
      const result = await buildingService.findBuildingsNearCoordinates(
        projectId, 
        position.lat, 
        position.lng, 
        searchRadius
      );

      if (result && result.length > 0) {
        setNearbyBuildings(result);
        
        // Check if this is a direct submission (mock building)
        if (result.length === 1 && result[0].id === "direct-submission") {
          setIsDirectSubmission(true);
          setNearbyBuilding(result[0]);
          setShowConfirmation(true);
          setIsChecking(false);
          return true; // Return true to indicate success
        } else if (result.length === 1) {
          // If only one building found, show confirmation directly
          setNearbyBuilding(result[0]);
          setShowConfirmation(true);
          setIsChecking(false);
          return true; // Return true to indicate success
        } else {
          // If multiple buildings found, show the list for selection
          setShowBuildingList(true);
          setIsChecking(false);
          return true; // Return true to indicate success
        }
      } else {
        toast.warning(t('capture.submitBuilding.noBuildingsFound') || 
          `No buildings found within ${searchRadius} meters. Please try a different location.`);
        setIsChecking(false);
        return false; // Return false to indicate failure (no buildings found)
      }
    } catch (error) {
      toast.error(t('capture.submitBuilding.errorCheckingBuildings') || 
        "Error checking nearby buildings. Please try again.");
      setIsChecking(false);
      return false; // Return false to indicate failure
    }
  };

  const selectBuilding = (building) => {
    setNearbyBuilding(building);
    setShowBuildingList(false);
    setShowConfirmation(true);
    setIsDirectSubmission(building.id === "direct-submission");
  };

  const confirmAndSubmit = async () => {
    if (!nearbyBuilding) {
      toast.error(t('capture.submitBuilding.noBuildingSelected') || 
        "No building selected for confirmation");
      return;
    }
    
    // Validate that the position is within the project boundary
    if (projectBoundary && !isPointInPolygon(position, projectBoundary)) {
      toast.error(t('Please select a location within the project boundary'));
      return;
    }

    setIsSubmitting(true);
    setSubmissionProgress(10);
    setSubmissionError(null);

    try {
      const blob = location.state?.photoBlob;
      if (!blob) {
        toast.error(t('capture.submitBuilding.noImage') || "No image provided");
        setIsSubmitting(false);
        return;
      }
      
      setSubmissionProgress(30);
      
      // Create coordinates and building data object
      const buildingData = {
        latitude: position.lat,
        longitude: position.lng,
        verified: true,
        publish: true,
        buildingId: nearbyBuilding.id
      };
      
      // If this is a direct submission, add name and address
      if (isDirectSubmission) {
        buildingData.name = `Building at ${position.lat.toFixed(4)}, ${position.lng.toFixed(4)}`;
        buildingData.address = `Coordinates: ${position.lat.toFixed(6)}, ${position.lng.toFixed(6)}`;
      }
      
      setSubmissionProgress(50);
      
      // Use the endpoint to add the image to the building
      const result = await buildingService.addImageToNearestBuilding(projectId, buildingData, blob);
      
      setSubmissionProgress(100);
      
      // Show success message
      const successMessage = isDirectSubmission 
        ? "Building created successfully!" 
        : "Image added to building successfully!";
      toast.success(t('capture.submitBuilding.success') || successMessage);
      
      navigate(`/project/${projectId}`, { 
        state: { dorple: result }, 
        replace: true 
      });
    } catch (error) {
      setSubmissionError(error.message || t('capture.submitBuilding.error') || "Error submitting building");
      toast.error(t('capture.submitBuilding.error') || "Error submitting building");
      setIsSubmitting(false);
      setSubmissionProgress(0);
    }
  };

  const cancelConfirmation = () => {
    setShowConfirmation(false);
    setNearbyBuilding(null);
    setSubmissionError(null);
    
    // If we have multiple buildings, go back to the list
    if (nearbyBuildings.length > 1) {
      setShowBuildingList(true);
    }
  };

  const cancelBuildingList = () => {
    setShowBuildingList(false);
    setNearbyBuildings([]);
  };

  const submitBuilding = async (e) => {
    e.preventDefault();
    
    if (!position || typeof position.lng !== 'number' || typeof position.lat !== 'number') {
      toast.error(t('capture.submitBuilding.selectLocation') || "Please select a valid location on the map");
      return;
    }
    
    // Validate that the position is within the project boundary
    if (projectBoundary) {
      const isInBoundary = isPointInPolygon(position, projectBoundary);
      
      if (!isInBoundary) {
        toast.error(t('Please select a location within the project boundary'));
        return;
      }
    }
    
    // Instead of submitting directly, first check for nearby buildings
    await checkNearbyBuildings();
  };

  // Calculate distance between two points in meters
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Earth's radius in meters
    const φ1 = lat1 * Math.PI/180;
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    return R * c; // Distance in meters
  };

  return (
    <div className="flex flex-col min-h-screen p-8 pb-24 overflow-y-auto">
      <div className="w-full max-w-md">
        <h2 className="text-xl font-semibold text-center mb-4">
          {t('capture.submitBuilding.title') || 'Submit Building'}
        </h2>
        
        {location.state?.photoUrl && (
          <div className="mb-4 rounded-lg overflow-hidden border border-gray-300">
            <img 
              src={location.state.photoUrl} 
              alt="Building" 
              className="w-full h-auto"
            />
          </div>
        )}
        
        {/* Building List Dialog */}
        {showBuildingList && nearbyBuildings.length > 0 && (
          <div className="mb-6 p-4 border border-gray-300 rounded-lg bg-gray-50">
            <h3 className="font-medium text-lg mb-2">
              {t('capture.submitBuilding.multipleBuildings') || 'Multiple Buildings Found'}
            </h3>
            <p className="mb-2">
              {t('capture.submitBuilding.selectCorrectBuilding') || 
                `We found ${nearbyBuildings.length} buildings near your selected location. Please select the correct one:`}
            </p>
            <div className="max-h-60 overflow-y-auto mb-3">
              {nearbyBuildings.map((building, index) => {
                const distance = position ? 
                  calculateDistance(position.lat, position.lng, building.latitude, building.longitude) : 0;
                
                return (
                  <div 
                    key={building.id || index}
                    onClick={() => selectBuilding(building)}
                    className="p-3 bg-white rounded border border-gray-200 mb-2 cursor-pointer hover:bg-blue-50"
                  >
                    <p className="font-medium">{building.name || 'Unnamed Building'}</p>
                    <p className="text-gray-600">{building.address || 'No address available'}</p>
                    <p className="text-xs text-gray-500 mt-1">
                      {t('capture.submitBuilding.distance') || 'Distance'}: {distance.toFixed(1)}m
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-end">
              <button
                onClick={cancelBuildingList}
                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
              >
                {t('capture.submitBuilding.cancel') || 'Cancel'}
              </button>
            </div>
          </div>
        )}
        
        {/* Confirmation Dialog */}
        {showConfirmation && nearbyBuilding && (
          <div className="mb-6 p-4 border border-gray-300 rounded-lg bg-gray-50">
            <h3 className="font-medium text-lg mb-2">
              {isDirectSubmission 
                ? (t('capture.submitBuilding.createNewBuilding') || 'Create New Building')
                : (t('capture.submitBuilding.confirmNewBuilding') || 'Confirm Building')}
            </h3>
            <p className="mb-2">
              {isDirectSubmission
                ? (t('capture.submitBuilding.noExistingBuildings') || 'No existing buildings found. A new building will be created at this location:')
                : (t('capture.submitBuilding.foundNearbyBuilding') || 'We found a building near your selected location:')}
            </p>
            <div className="p-3 bg-white rounded border border-gray-200 mb-3">
              <p className="font-medium">{nearbyBuilding.name || 'Unnamed Building'}</p>
              <p className="text-gray-600">{nearbyBuilding.address || 'No address available'}</p>
              {position && nearbyBuilding.latitude && nearbyBuilding.longitude && !isDirectSubmission && (
                <p className="text-xs text-gray-500 mt-1">
                  {t('capture.submitBuilding.distance') || 'Distance'}: 
                  {calculateDistance(
                    position.lat, 
                    position.lng, 
                    nearbyBuilding.latitude, 
                    nearbyBuilding.longitude
                  ).toFixed(1)}m
                </p>
              )}
            </div>
            <p className="mb-4 text-sm text-gray-600">
              {isDirectSubmission
                ? (t('capture.submitBuilding.confirmNewBuilding') || 'Do you want to create a new building at this location?')
                : (t('capture.submitBuilding.confirmQuestion') || 'Is this the correct building?')}
            </p>
            
            {submissionError && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md text-sm">
                {submissionError}
              </div>
            )}
            
            {isSubmitting && (
              <div className="mb-4">
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div 
                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-300" 
                    style={{ width: `${submissionProgress}%` }}
                  ></div>
                </div>
                <p className="text-xs text-gray-500 mt-1 text-center">
                  {submissionProgress < 100 
                    ? (t('capture.submitBuilding.uploading') || 'Uploading...') 
                    : (t('capture.submitBuilding.processing') || 'Processing...')}
                </p>
              </div>
            )}
            
            <div className="flex space-x-3">
              <button
                onClick={confirmAndSubmit}
                disabled={isSubmitting}
                className="flex-1 px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 disabled:bg-green-300 flex items-center justify-center"
              >
                {isSubmitting ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    {t('capture.submitBuilding.submitting') || 'Submitting...'}
                  </>
                ) : isDirectSubmission
                  ? (t('capture.submitBuilding.createBuilding') || 'Create Building')
                  : (t('capture.submitBuilding.confirmYes') || 'Yes, Confirm')}
              </button>
              <button
                onClick={cancelConfirmation}
                disabled={isSubmitting}
                className="flex-1 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 disabled:bg-gray-300"
              >
                {t('capture.submitBuilding.confirmNo') || 'No, Cancel'}
              </button>
            </div>
          </div>
        )}
        
        <form 
          id="building-form" 
          onSubmit={submitBuilding} 
          className="flex flex-col space-y-4"
          encType="multipart/form-data"
        >
          {/* Map for Location Selection */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('capture.submitBuilding.location') || 'Location'} 
              {position && ` (${position.lat.toFixed(6)}, ${position.lng.toFixed(6)})`}
            </label>
            <div className="h-64 w-full border border-gray-300 rounded-md overflow-hidden">
              <MapContainer 
                center={mapCenter} 
                zoom={15} 
                style={{ height: '100%', width: '100%' }}
                className="z-0"
              >
                <MapUpdater center={mapCenter} zoom={15} />
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {projectBoundary && (
                  <GeoJSON 
                    data={projectBoundary} 
                    style={() => ({
                      color: '#3b82f6',
                      weight: 2,
                      fillOpacity: 0.1
                    })}
                  />
                )}
                <LocationMarker 
                  position={position} 
                  setPosition={setPosition} 
                  projectBoundary={projectBoundary}
                />
              </MapContainer>
            </div>
            <div className="flex justify-between items-center mt-1">
              <p className="text-sm text-gray-500">
                {t('capture.submitBuilding.mapInstructions') || 'Click on the map to select the building location'}
              </p>
          <button 
            type="button"
            onClick={detectLocation}
                disabled={isLocating}
                className="text-xs px-2 py-1 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 flex items-center"
              >
                {isLocating ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-1 h-3 w-3 text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    {t('capture.submitBuilding.locating') || 'Locating...'}
                  </>
                ) : (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    {t('capture.submitBuilding.useMyLocation') || 'Use My Location'}
                  </>
                )}
          </button>
            </div>
            {projectBoundary && (
              <p className="text-xs text-amber-600 mt-1">
                {t('capture.submitBuilding.boundaryNote') || 'Note: You can only select locations within the highlighted project boundary'}
              </p>
            )}
          </div>
          
          {/* Note about search radius */}
          <div className="mb-4">
            <p className="text-sm text-gray-500">
              {t('capture.submitBuilding.searchRadiusNote') || `Buildings within ${searchRadius} meters of the selected location will be found.`}
            </p>
          </div>
          
          <button 
            type="submit"
            disabled={isChecking || isSubmitting || !position || showBuildingList || showConfirmation}
            className="px-4 py-2 bg-sky-600 text-white rounded-md hover:bg-sky-700 disabled:bg-sky-300 flex items-center justify-center"
            onClick={async (e) => {
              e.preventDefault(); // Prevent default form submission
              
              if (!position) {
                toast.error(t('capture.submitBuilding.selectLocation') || "Please select a location on the map");
                return;
              }
              
              // Call the submitBuilding function which will check for nearby buildings
              await submitBuilding(e);
            }}
          >
            {isChecking ? (
              <>
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                {t('capture.submitBuilding.checking') || 'Checking Nearby Buildings...'}
              </>
            ) : (
              t('capture.submitBuilding.findBuilding') || 'Find Nearby Building'
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default SubmitBuilding;