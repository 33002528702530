import { useEffect, useState, useRef, useCallback } from "react";

function CameraFeed({ onVideoReady }) {
    const [stream, setStream] = useState(null);
    const [facingMode, setFacingMode] = useState('environment');
    const [isVideoReady, setIsVideoReady] = useState(false);
    const [error, setError] = useState(null);
    const [isPortrait, setIsPortrait] = useState(true);
    const [isSwitching, setIsSwitching] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [retryCount, setRetryCount] = useState(0);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [hasZoom, setHasZoom] = useState(false);
    const [maxZoom, setMaxZoom] = useState(1);
    const [minZoom, setMinZoom] = useState(0.1);
    const ZOOM_INCREMENT = 0.2;
    const videoRef = useRef(null);
    const streamRef = useRef(null);
    const retryTimeoutRef = useRef(null);

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const MAX_RETRIES = 3;
    const RETRY_DELAY = 2000;

    // Handle orientation changes
    useEffect(() => {
        const checkOrientation = () => {
            const isPortrait = window.matchMedia("(orientation: portrait)").matches;
            setIsPortrait(isPortrait);
            if (!isPortrait) {
                setError("Please rotate your device to portrait mode");
            } else {
                setError(null);
            }
        };

        checkOrientation();
        const mediaQuery = window.matchMedia("(orientation: portrait)");
        mediaQuery.addListener(checkOrientation);

        return () => mediaQuery.removeListener(checkOrientation);
    }, []);

    const stopCurrentStream = useCallback(() => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => {
                track.stop();
            });
            streamRef.current = null;
        }
        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
    }, []);

    const handleZoom = useCallback(async (newZoom) => {
        if (!streamRef.current || !hasZoom) return;

        try {
            const videoTrack = streamRef.current.getVideoTracks()[0];
            const capabilities = videoTrack.getCapabilities();
            
            if (!capabilities.zoom) return;

            const constraints = {
                advanced: [{ zoom: Math.min(Math.max(newZoom, minZoom), maxZoom) }]
            };

            await videoTrack.applyConstraints(constraints);
            setZoomLevel(newZoom);
        } catch (error) {
            console.error('Error adjusting zoom:', error);
        }
    }, [hasZoom, maxZoom, minZoom]);

    const zoomIn = useCallback(() => {
        handleZoom(zoomLevel + ZOOM_INCREMENT);
    }, [zoomLevel, handleZoom]);

    const zoomOut = useCallback(() => {
        handleZoom(zoomLevel - ZOOM_INCREMENT);
    }, [zoomLevel, handleZoom]);

    const checkZoomCapabilities = useCallback(async (stream) => {
        if (!stream) return;

        const videoTrack = stream.getVideoTracks()[0];
        if (!videoTrack) return;

        const capabilities = videoTrack.getCapabilities();
        const hasZoomFeature = capabilities?.zoom !== undefined;
        setHasZoom(hasZoomFeature);

        if (hasZoomFeature) {
            setMaxZoom(capabilities.zoom.max);
            setMinZoom(capabilities.zoom.min || 0.1);
        }
    }, []);

    const startCamera = useCallback(async (isRetry = false) => {
        if (isSwitching) return;
        
        if (!isRetry) {
            setIsLoading(true);
            setError(null);
        }
        
        if (!navigator.mediaDevices?.getUserMedia) {
            const msg = 'Media Devices API not supported. Please make sure you are using HTTPS or localhost.';
            console.error(msg);
            setError(msg);
            setIsLoading(false);
            return;
        }

        if (!isPortrait) {
            setError("Please rotate your device to portrait mode");
            setIsLoading(false);
            return;
        }

        try {
            stopCurrentStream();

            const constraints = {
                video: {
                    facingMode: { exact: facingMode },
                    width: { min: 600, ideal: 600, max: 1920 },
                    height: { min: 800, ideal: 800, max: 1080 },
                    frameRate: { max: 30 }
                },
                audio: false
            };

            let mediaStream;
            try {
                mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
            } catch (constraintError) {
                console.log('Failed with exact constraints, trying fallback');
                const fallbackConstraints = {
                    video: {
                        facingMode: facingMode,
                        width: { ideal: 600 },
                        height: { ideal: 800 }
                    },
                    audio: false
                };
                mediaStream = await navigator.mediaDevices.getUserMedia(fallbackConstraints);
            }

            if (videoRef.current) {
                videoRef.current.srcObject = mediaStream;
                streamRef.current = mediaStream;
                
                await checkZoomCapabilities(mediaStream);
                
                if (isIOS) {
                    try {
                        await videoRef.current.play();
                    } catch (playError) {
                        console.error('Play error:', playError);
                        throw playError;
                    }
                }

                setStream(mediaStream);
                setIsVideoReady(true);
                setIsLoading(false);
                setRetryCount(0);
                if (onVideoReady) {
                    onVideoReady(true);
                }
            }
        } catch (err) {
            let errorMessage;
            if (err.name === 'NotAllowedError') {
                errorMessage = 'Camera access denied. Please allow camera access to use this feature.';
            } else if (err.name === 'NotReadableError') {
                errorMessage = 'Camera is in use by another application.';
            } else if (err.name === 'OverconstrainedError') {
                errorMessage = 'Unable to access camera with current settings.';
            } else {
                errorMessage = `Camera error: ${err.message}`;
            }
            console.error(errorMessage, err);
            setError(errorMessage);
            setIsVideoReady(false);
            setIsLoading(false);
            
            if (retryCount < MAX_RETRIES) {
                setRetryCount(prev => prev + 1);
                if (retryTimeoutRef.current) {
                    clearTimeout(retryTimeoutRef.current);
                }
                retryTimeoutRef.current = setTimeout(() => {
                    startCamera(true);
                }, RETRY_DELAY);
            }
            
            if (onVideoReady) {
                onVideoReady(false);
            }
        }
    }, [facingMode, isPortrait, isSwitching, stopCurrentStream, isIOS, onVideoReady, retryCount, checkZoomCapabilities]);

    useEffect(() => {
        startCamera();
        return () => {
            stopCurrentStream();
            if (retryTimeoutRef.current) {
                clearTimeout(retryTimeoutRef.current);
            }
        };
    }, [startCamera, stopCurrentStream]);

    const switchCamera = async () => {
        if (!isPortrait || isSwitching || !isVideoReady) return;

        setIsSwitching(true);
        setIsVideoReady(false);
        if (onVideoReady) {
            onVideoReady(false);
        }

        stopCurrentStream();
        setFacingMode(prevMode => prevMode === 'environment' ? 'user' : 'environment');
        
        // Add a small delay before setting isSwitching back to false
        setTimeout(() => {
            setIsSwitching(false);
        }, 500);
    };

    const handleRetry = () => {
        setRetryCount(0);
        setError(null);
        startCamera();
    };
 
    return (
        <div className="relative w-full h-full">
            {error ? (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 text-white p-4 text-center">
                    <div className="flex flex-col items-center">
                        <p>{error}</p>
                        {!isPortrait && (
                            <div className="mt-4 rotate-90 transform">
                                📱
                            </div>
                        )}
                        {retryCount >= MAX_RETRIES && (
                            <button
                                onClick={handleRetry}
                                className="mt-4 px-4 py-2 bg-white text-black rounded-lg hover:bg-gray-200"
                            >
                                Retry Camera
                            </button>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <video 
                        ref={videoRef}
                        autoPlay 
                        playsInline 
                        muted 
                        playsinline="true"
                        webkit-playsinline="true"
                        id="videoElement"
                        className={`w-full h-full object-cover transition-transform duration-300 ${facingMode === 'user' ? '-scale-x-100' : ''}`}
                    />
                    {(isLoading || isSwitching) && (
                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="flex flex-col items-center">
                                <div className="w-12 h-12 border-4 border-white border-t-transparent rounded-full animate-spin"></div>
                                <p className="mt-4 text-white">
                                    {isSwitching ? 'Switching Camera...' : 'Initializing Camera...'}
                                </p>
                                {retryCount > 0 && (
                                    <p className="mt-2 text-white text-sm">
                                        Retry attempt {retryCount} of {MAX_RETRIES}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                    <button 
                        onClick={switchCamera}
                        className={`absolute top-4 right-4 rounded-full border border-white text-white bg-gray-700 bg-opacity-35 backdrop-blur-sm p-2 z-10 transition-opacity duration-300 ${
                            (!isVideoReady || isSwitching) ? 'opacity-50 cursor-not-allowed' : 'opacity-100 hover:bg-opacity-40'
                        }`}
                        disabled={!isVideoReady || isSwitching}
                    >
                        {isSwitching ? 'Switching...' : 'Switch Camera'}
                    </button>
                    {hasZoom && isVideoReady && (
                        <div className="absolute bottom-24 right-4 flex flex-col gap-2 z-10">
                            <button
                                onClick={zoomIn}
                                disabled={zoomLevel >= maxZoom}
                                className={`rounded-full border border-white text-white bg-gray-700 bg-opacity-35 backdrop-blur-sm p-2 transition-opacity duration-300 ${
                                    zoomLevel >= maxZoom ? 'opacity-50 cursor-not-allowed' : 'opacity-100 hover:bg-opacity-40'
                                }`}
                            >
                                +
                            </button>
                            <div className="text-white text-center text-sm bg-gray-700 bg-opacity-35 backdrop-blur-sm px-2 py-1 rounded">
                                {Math.round(zoomLevel * 10) / 10}x
                            </div>
                            <button
                                onClick={zoomOut}
                                disabled={zoomLevel <= minZoom}
                                className={`rounded-full border border-white text-white bg-gray-700 bg-opacity-35 backdrop-blur-sm p-2 transition-opacity duration-300 ${
                                    zoomLevel <= minZoom ? 'opacity-50 cursor-not-allowed' : 'opacity-100 hover:bg-opacity-40'
                                }`}
                            >
                                -
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default CameraFeed;