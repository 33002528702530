// custom-marker.tsx

import React from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import MakrerUrl from "../images/mapmarker.svg";
import { Marker } from "react-leaflet";


function CustomMarker({ position, children, scoreY = 0, scoreN = 0 }) {
  const total = scoreY + scoreN;
  const percentageY = total > 0 ? (scoreY / total) * 100 : 50;


  const customIcon = L.icon({
    iconUrl: `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="size-4">
      <defs>
        <linearGradient id="split" x1="0" x2="0" y1="0" y2="1">
          <stop offset="${percentageY}%" stop-color="#22C55E"/>
          <stop offset="${percentageY}%" stop-color="#DC2626"/>
        </linearGradient>
      </defs>
      <path fill="url(#split)" strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" /></svg>`)}`,
    iconSize: [20, 20],
    iconAnchor: [10, 20],
  });

  return (
    <Marker position={position} icon={customIcon}>
      {children}
    </Marker>
  );
}

export default CustomMarker;