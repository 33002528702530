import React, { useState, useRef } from 'react';

/**
 * A reusable component for text input with hashtag suggestions
 * 
 * @param {Object} props
 * @param {string} props.value - The current value of the textarea
 * @param {function} props.onChange - Function to call when the textarea value changes
 * @param {string} props.placeholder - Placeholder text for the textarea
 * @param {number} props.rows - Number of rows for the textarea
 * @param {string} props.className - Additional CSS classes for the textarea
 * @param {Array<string>} props.popularHashtags - Array of popular hashtags to suggest
 * @param {boolean} props.showSuggestionButtons - Whether to show quick hashtag buttons below the textarea
 * @param {string} props.id - ID for the textarea element
 * @returns {JSX.Element}
 */
function HashtagInput({
  value,
  onChange,
  placeholder,
  rows = 3,
  className = "",
  popularHashtags = ['architecture', 'design', 'modern', 'historic', 'renovation', 'sustainable', 'urban'],
  showSuggestionButtons = false,
  id
}) {
  const [showHashtagSuggestions, setShowHashtagSuggestions] = useState(false);
  const [currentWord, setCurrentWord] = useState('');
  const textareaRef = useRef(null);

  // Function to handle textarea input and detect hashtag typing
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    onChange(e);

    // Get the current cursor position
    const cursorPosition = e.target.selectionStart;
    
    // Extract the current word being typed
    const textBeforeCursor = newValue.substring(0, cursorPosition);
    const words = textBeforeCursor.split(/\s/);
    const currentTypedWord = words[words.length - 1];
    
    // Check if we're currently typing a hashtag
    if (currentTypedWord.startsWith('#') && currentTypedWord.length > 1) {
      setCurrentWord(currentTypedWord.substring(1)); // Remove the # symbol
      setShowHashtagSuggestions(true);
    } else {
      setShowHashtagSuggestions(false);
    }
  };

  // Function to insert a hashtag at the current cursor position
  const insertHashtag = (hashtag) => {
    if (!textareaRef.current) return;
    
    const textarea = textareaRef.current;
    const cursorPosition = textarea.selectionStart;
    const textBeforeCursor = value.substring(0, cursorPosition);
    const textAfterCursor = value.substring(cursorPosition);
    
    // Find the start of the current word
    const lastSpaceBeforeCursor = textBeforeCursor.lastIndexOf(' ');
    const lastNewlineBeforeCursor = textBeforeCursor.lastIndexOf('\n');
    const wordStartPosition = Math.max(lastSpaceBeforeCursor, lastNewlineBeforeCursor) + 1;
    
    // Check if we're already typing a hashtag
    const currentWord = textBeforeCursor.substring(wordStartPosition);
    const isReplacingHashtag = currentWord.startsWith('#');
    
    // Create the new text value
    let newText;
    if (isReplacingHashtag) {
      // Replace the current hashtag
      newText = textBeforeCursor.substring(0, wordStartPosition) + 
                '#' + hashtag + ' ' + 
                textAfterCursor;
      
      // Set cursor position after the inserted hashtag and space
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = 
          wordStartPosition + hashtag.length + 2;
      }, 0);
    } else {
      // Insert the hashtag at the current position
      newText = textBeforeCursor + '#' + hashtag + ' ' + textAfterCursor;
      
      // Set cursor position after the inserted hashtag and space
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = 
          cursorPosition + hashtag.length + 2;
      }, 0);
    }
    
    // Update the textarea value
    const event = {
      target: {
        value: newText,
        name: textarea.name
      }
    };
    onChange(event);
    
    // Hide the suggestions
    setShowHashtagSuggestions(false);
    
    // Focus the textarea
    textarea.focus();
  };

  // Filter hashtags based on current input
  const filteredHashtags = popularHashtags
    .filter(tag => tag.toLowerCase().includes(currentWord.toLowerCase()))
    .slice(0, 5); // Limit to 5 suggestions

  return (
    <div className="relative">
      <textarea
        id={id}
        ref={textareaRef}
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={`w-full p-2 border rounded-lg resize-none ${className}`}
        rows={rows}
      />
      
      {/* Hashtag suggestions dropdown */}
      {showHashtagSuggestions && filteredHashtags.length > 0 && (
        <div className="absolute z-10 bg-white border rounded-md shadow-lg mt-1 w-full max-h-40 overflow-y-auto">
          {filteredHashtags.map((tag) => (
            <div
              key={tag}
              className="px-3 py-2 hover:bg-sky-50 cursor-pointer"
              onClick={() => insertHashtag(tag)}
            >
              #{tag}
            </div>
          ))}
        </div>
      )}
      
      {/* Quick hashtag buttons */}
      {showSuggestionButtons && popularHashtags.length > 0 && (
        <div className="flex flex-wrap gap-1 mt-2">
          {popularHashtags.slice(0, 5).map((tag) => (
            <button
              key={tag}
              type="button"
              onClick={() => insertHashtag(tag)}
              className="text-xs bg-gray-100 hover:bg-gray-200 text-gray-700 px-2 py-1 rounded"
            >
              #{tag}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default HashtagInput; 