import { useState } from 'react';
import RequestService from './RequestService';

export default class ImageService extends RequestService {
  constructor() {
    super();
  }

  getImageUrlWithEndpoint = (endpoint, width, height) => {
    return this.makeUrl(['images', ...endpoint], { width, height });
  };
  
  getImageUrlById = (id, width, height) => this.getImageUrlWithEndpoint([id], width, height);
  getSrc = (id, width, height) => this.getImageUrlById(id, width, height);
  getSrcForBuilding = (building_id, width, height) => this.getImageUrlWithEndpoint(['buildings', building_id, 'image'], width, height);
  getSrcForNonBuilding = (non_building_id, width, height) => this.getImageUrlWithEndpoint(['non_buildings', non_building_id, 'image'], width, height);
  getSrcForQuestion = (question_id, width, height) => this.getImageUrlWithEndpoint(['questions', question_id, 'image'], width, height);
}

/**
 * Use the ImageService
 * @returns {ImageService} Building service.
 */
export function useImageService() {
  const [buildingService] = useState(new ImageService());
  return buildingService;
}