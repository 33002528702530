import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useParams } from 'react-router-dom';
import { useAuthenticationService } from './services/AuthenticationService';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useRequestService } from './services/RequestService';
import { useQuery } from '@tanstack/react-query';

import './App.css';

import HomePage from './pages/HomePage';
import SwipePage from './pages/SwipePage';
import GalleryPage from './pages/GalleryPage';
import GallerymapPage from './pages/GallerymapPage';
import BuildingDetailPage from './pages/BuildingDetailPage';
import ProjectsListPage from './pages/ProjectsListPage';
import ProjectGalleryPage from './pages/ProjectGalleryPage';
import ProjectGallerymapPage from './pages/ProjectGallerymapPage';
import ProjectBuildingDetailPage from './pages/ProjectBuildingDetailPage';
import ProjectNonBuildingDetailPage from './pages/ProjectNonBuildingDetailPage';
import ProjectQuestionDetailPage from './pages/ProjectQuestionDetailPage';
import ProjectIdeaDetailPage from './pages/ProjectIdeaDetailPage';
import ProjectAddIdeaPage from './pages/ProjectAddIdeaPage';
import ProjectConversationPage from './pages/ProjectConversationPage';
import ProjectSwipePage from './pages/ProjectSwipePage';
import ProjectProfilePage from './pages/ProjectProfilePage';
import SettingsPage from './pages/Settings/SettingsPage';
import RewardsPage from './pages/RewardsPage';
import NavFooter from './components/NavFooter';
import NavProjectFooter from './components/NavProjectFooter';
import ProjectHeader from './components/ProjectHeader';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import OnlineIndicator from './components/OnlineIndicator';

import CapturePage from './pages/Capture/CapturePage';
import SubmitBuilding from './pages/Capture/SubmitBuilding';
import SubmitNonBuilding from './pages/Capture/SubmitNonBuilding';
import PhotoPreview from './pages/Capture/PhotoPreview';

import InvitePage from './pages/InvitePage';
import SendInvitationPage from './pages/SendInvitationPage';
import RequestPasswordResetPage from './pages/RequestPasswordResetPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ResendVerificationPage from './pages/ResendVerificationPage';
import VerifyEmailPage from './pages/VerifyEmailPage';

export const whitelist = ["/login", "/register", "/signup", "/signin", "/terms", "/privacy"];

// Component for public routes that should redirect to home if user is logged in


// Component for public routes

function PublicRoute({ children }) {
  const authenticationService = useAuthenticationService();
  const location = useLocation();

  // Check if user is authenticated
  const isAuthenticated = authenticationService.isTokenValid();

  // Check if current path is in whitelist
  const currentPath = window.location.pathname;
  const isWhitelistedPath = whitelist.includes(currentPath);
 
  if (isAuthenticated && isWhitelistedPath) {

    return <Navigate to="/home" replace />;
  }

  return children;
}

// Component for protected routes
function PrivateRoute({ children }) {
  const authenticationService = useAuthenticationService();
  const isAuthenticated = authenticationService.isTokenValid();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Return both the page content and the NavFooter
  return (
    <>
      {children}
      <NavFooter />
    </>
  );
}


// Component for protected routes
function ProjectRoute({ children }) {
  const authenticationService = useAuthenticationService();
  const isAuthenticated = authenticationService.isTokenValid();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Return both the page content and the NavFooter
  return (
    <>
      <ProjectHeader />
      <div className='flex-1 flex flex-col min-h-0 mt-8 mb-16 overflow-clip'>{children}</div>
      <NavProjectFooter />
    </>
  );
}

// Component for project routes that checks if the user has completed their profile
function ProjectRouteWithProfileCheck({ children }) {
  const authenticationService = useAuthenticationService();
  const requestService = useRequestService();
  const { projectId } = useParams();
  
  // Use React Query to fetch and cache project data
  const { data: projectData, isLoading } = useQuery({
    queryKey: ['project', projectId],
    queryFn: async () => {
      // Skip profile check for the profile page itself
      if (window.location.pathname.includes('/profile')) {
        return { profileCompleted: true };
      }
      
      const data = await requestService.get(`projects/${projectId}`);
      return data;
    },
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
    enabled: !!projectId && !window.location.pathname.includes('/profile')
  });
  
  // Check if user is authenticated
  const isAuthenticated = authenticationService.isTokenValid();
  
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-sky-700"></div>
      </div>
    );
  }
  
  // If profile is not completed, redirect to the profile page
  if (!projectData?.profileCompleted) {
    return <Navigate to={`/project/${projectId}/profile`} replace />;
  }
  
  // Return the project page with header and footer
  return (
    <>
      <ProjectHeader />
      <div className='flex-1 flex flex-col min-h-0 mt-8 mb-16 overflow-clip'>{children}</div>
      <NavProjectFooter />
    </>
  );
}

// Create a new component to wrap the app content
function AppContent() {
  const location = useLocation();
  const publicRoutes = ['/login', '/signup', '/terms', '/privacy', '/request-password-reset', '/reset-password', '/resend-verification', '/verify'];
  const isPublicRoute = publicRoutes.some(route => location.pathname.startsWith(route));

  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        } />
        <Route path="/signup" element={
          <PublicRoute>
            <SignupPage />
          </PublicRoute>
        } />
        <Route path="/terms" element={
          <PublicRoute>
            <TermsPage />
          </PublicRoute>
        } />
        <Route path="/privacy" element={
          <PublicRoute>
            <PrivacyPage />
          </PublicRoute>
        } />
        <Route path="/request-password-reset" element={
          <PublicRoute>
            <RequestPasswordResetPage />
          </PublicRoute>
        } />
        <Route path="/reset-password/:token" element={
          <PublicRoute>
            <ResetPasswordPage />
          </PublicRoute>
        } />
        <Route path="/resend-verification" element={
          <PublicRoute>
            <ResendVerificationPage />
          </PublicRoute>
        } />
        <Route path="/verify/:token" element={
          <PublicRoute>
            <VerifyEmailPage />
          </PublicRoute>
        } />

        {/* Protected routes */}
        <Route path="/home" element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        } />
        <Route path="/swipe" element={
          <PrivateRoute>
            <SwipePage />
          </PrivateRoute>
        } />
        <Route path="/gallery" element={
          <PrivateRoute>
            <GalleryPage />
          </PrivateRoute>
        } />
        <Route path="/gallerymap" element={
          <PrivateRoute>
            <GallerymapPage />
          </PrivateRoute>
        } />
        <Route path="/gallerydetail" element={
          <PrivateRoute>
            <BuildingDetailPage />
          </PrivateRoute>
        } />
        <Route path="/projects" element={
          <PrivateRoute>
            <ProjectsListPage />
          </PrivateRoute>
        } />
        
        {/* Project Profile Page */}
        <Route path="/project/:projectId/profile" element={
          <ProjectRoute>
            <ProjectProfilePage />
          </ProjectRoute>
        } />

        {/* Project routes with profile check */}
        <Route path="/project/:projectId" element={
          <ProjectRouteWithProfileCheck>
            <ProjectGalleryPage />
          </ProjectRouteWithProfileCheck>
        } />

        <Route path="/project/:projectId/swipe" element={
          <ProjectRouteWithProfileCheck>
            <ProjectSwipePage />
          </ProjectRouteWithProfileCheck>
        } />   

        <Route path="/project/:projectId/gallerymap" element={
          <ProjectRouteWithProfileCheck>
            <ProjectGallerymapPage />
          </ProjectRouteWithProfileCheck>
        } />
        
        <Route path="/project/:projectId/buildingdetail" element={
          <ProjectRouteWithProfileCheck>
            <ProjectBuildingDetailPage />
          </ProjectRouteWithProfileCheck>
        } />

        <Route path="/project/:projectId/nonbuildingdetail" element={
          <ProjectRouteWithProfileCheck>
            <ProjectNonBuildingDetailPage />
          </ProjectRouteWithProfileCheck>
        } />

        <Route path="/project/:projectId/questiondetail" element={
          <ProjectRouteWithProfileCheck>
            <ProjectQuestionDetailPage />
          </ProjectRouteWithProfileCheck>
        } />
        
        <Route path="/project/:projectId/projectideadetail" element={
          <ProjectRouteWithProfileCheck>
            <ProjectIdeaDetailPage />
          </ProjectRouteWithProfileCheck>
        } />
        
        <Route path="/projects/:projectId/add-idea" element={
          <ProjectRouteWithProfileCheck>
            <ProjectAddIdeaPage />
          </ProjectRouteWithProfileCheck>
        } />
        
        <Route path="/project/:projectId/capture" element={
          <ProjectRouteWithProfileCheck>
            <CapturePage />
          </ProjectRouteWithProfileCheck>
        } />
        
        <Route path="/project/:projectId/capture-preview" element={
          <ProjectRouteWithProfileCheck>
            <PhotoPreview />
          </ProjectRouteWithProfileCheck>
        } />
        
        <Route path="/project/:projectId/submit-building" element={
          <ProjectRouteWithProfileCheck>
            <SubmitBuilding />
          </ProjectRouteWithProfileCheck>
        } />
        
        <Route path="/project/:projectId/submit-nonbuilding" element={
          <ProjectRouteWithProfileCheck>
            <SubmitNonBuilding />
          </ProjectRouteWithProfileCheck>
        } />
       
        <Route path="/project/:projectId/conversation" element={
          <ProjectRouteWithProfileCheck>
            <ProjectConversationPage />
          </ProjectRouteWithProfileCheck>
        } />

        <Route path="/rewards" element={
          <PrivateRoute>
            <RewardsPage />
          </PrivateRoute>
        } />
        <Route path="/settings" element={
          <PrivateRoute>
            <SettingsPage />
          </PrivateRoute>
        } />
        <Route path="/invite" element={
          <PrivateRoute>
            <InvitePage />
          </PrivateRoute>
        } />
        <Route path="/send-invitation" element={
          <PrivateRoute>
            <SendInvitationPage />
          </PrivateRoute>
        } />

        {/* Redirect root to login */}
        <Route path="/" element={<Navigate to="/login" replace />} />
      </Routes>
      <OnlineIndicator />
    </>
  );
}

function App() {

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppContent />
      </Router>
    </QueryClientProvider>
  );

}

export default App;
