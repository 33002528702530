import React from 'react';
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

Chart.register(ArcElement, Tooltip, Legend, Title);

function CreateDoughnutData(props) {
  const { 
    scoredBuildings = 0, 
    upvotedBuildings = 0, 
    downvotedBuildings = 0 
  } = props;

  // Handle the case where both values are 0
  const chartData = [downvotedBuildings, upvotedBuildings];
  const isEmpty = chartData.every(value => value === 0);
  
  // If both values are 0, show a placeholder chart
  const data = {
    labels: [`${downvotedBuildings} Downvoted`, `${upvotedBuildings} Upvoted`],
    datasets: [
      {
        data: isEmpty ? [1, 1] : chartData,  // Use placeholder data if empty
        backgroundColor: isEmpty 
          ? ['rgba(220, 38, 38, 0.3)', 'rgba(22, 163, 74, 0.3)']  // Lighter colors for empty state
          : ['rgb(220, 38, 38)', 'rgb(22, 163, 74)'],
        borderWidth: 2,
        radius: '80%',
        cutout: '70%',
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: !isEmpty,  // Disable tooltips for empty state
      },
      legend: {
        position: 'bottom',
        title: {
          display: false,
          text: `${scoredBuildings}`,
          font: {
            family: 'Helvetica Neue',
          },
        },
      },
    },
  };

  return (
    <div>
      {isEmpty && (
        <div className="text-center text-gray-500 mb-2">
          No votes yet
        </div>
      )}
      <Doughnut data={data} options={options} />
    </div>
  );
}

export default CreateDoughnutData;