import { forwardRef, useRef, useLayoutEffect, useImperativeHandle, useState, useEffect, useCallback } from "react";
import { useSpring, animated } from "@react-spring/web";

const physics = {
  touchResponsive: {
    friction: 40,
    tension: 1500
  }
}

const settings = {
  maxTilt: 25, // in deg
  rotationPower: 50,
  swipeThreshold: 0.7,
  minimumVelocity: 3
};

export const swipeDirection = {
  up: "up",
  down: "down",
  left: "left",
  right: "right",
  none: "none"
};

const getSwipeDirection = (property) => {
  if (Math.abs(property.x) > Math.abs(property.y)) {
    if (property.x > settings.swipeThreshold)
      return swipeDirection.right;
    else if (property.x < -settings.swipeThreshold)
      return swipeDirection.left;
  } else {
    if (property.y > settings.swipeThreshold)
      return swipeDirection.down;
    else if (property.y < -settings.swipeThreshold)
      return swipeDirection.up;
  }
  return swipeDirection.none;
};

const pythagoras = (x, y) => {
  return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
};

const animateOut = async (gesture, setSpringTarget, windowHeight, windowWidth) => {
  const diagonal = pythagoras(windowHeight, windowWidth);
  let velocity = pythagoras(gesture.x, gesture.y);

  // const minimumVelocity = 10;
  if (velocity < settings.minimumVelocity) velocity = settings.minimumVelocity;

  const finalX = diagonal * gesture.x;
  const finalY = diagonal * gesture.y;
  const finalRotation = gesture.x * 45;
  const duration = diagonal / velocity;

  setSpringTarget.start({
    xyrot: [finalX, finalY, finalRotation],
    config: { duration: duration }
  });

  // for now animate back
  return await new Promise((resolve) => setTimeout(resolve, duration));
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}


const AnimatedDiv = animated.div

export const SwipeCard = forwardRef(({ children, onSwipe, preventSwipe = [swipeDirection.up, swipeDirection.down], onCardLeftScreen, swipeThreshold = settings.swipeThreshold }, ref) => {
  const element = useRef();

  settings.swipeThreshold = swipeThreshold;

  const { width, height } = useWindowSize();
  // const [clickOffset, setClickOffset] = useState({x: 0, y: 0});
  const [{ xyrot }, setSpringTarget] = useSpring(() => ({
    xyrot: [0, 0, 0],
    config: physics.touchResponsive
  }));

  let [isAnimatingBack, setAnimatingBack] = useState(false);

  const animateBack = (setSpringTarget) => {
    // translate back to the initial position
    return new Promise((resolve) => {
      setAnimatingBack(true);
      if (element.current) element.current.style.display = "block";
      setSpringTarget.start({
        xyrot: [0, 0, 0], config: physics.animateBack, onRest: () => {
          setAnimatingBack(false);
          resolve()
        }
      });
    })
  }

  useImperativeHandle(ref, () => ({
    async swipe(direction = swipeDirection.right) {
      try {
        if (onSwipe) {
          try {
            onSwipe(direction);
          } catch (error) {
            console.error('Error in onSwipe callback:', error);
          }
        }
        
        const power = 1.3;
        const disturbance = (Math.random() - 0.5) / 2;
        let gesture;
        if (direction === swipeDirection.right) gesture = { x: power, y: disturbance };
        else if (direction === swipeDirection.left) gesture = { x: -power, y: disturbance };
        else if (direction === swipeDirection.up) gesture = { x: disturbance, y: -power };
        else if (direction === swipeDirection.down) gesture = { x: disturbance, y: power };

        const wasAnimatingBack = isAnimatingBack;
        
        // Ensure width and height are valid before animating
        if (width && height) {
          await animateOut(gesture, setSpringTarget, width, height);
        } else {
          console.warn('Invalid dimensions for animation, using fallback values');
          await animateOut(gesture, setSpringTarget, window.innerWidth || 500, window.innerHeight || 800);
        }
        
        if (onCardLeftScreen) {
          try {
            onCardLeftScreen(direction);
          } catch (error) {
            console.error('Error in onCardLeftScreen callback:', error);
          }
        }
        
        if (element.current && !wasAnimatingBack && !isAnimatingBack) {
          element.current.style.display = "none";
        }
      } catch (error) {
        console.error('Error during swipe operation:', error);
        // Attempt to restore the card to a stable state
        try {
          await animateBack(setSpringTarget);
        } catch (restoreError) {
          console.error('Failed to restore card after error:', restoreError);
        }
      }
    },
    async restoreCard() {
      await animateBack(setSpringTarget);
    }
  }));

  const handleSwipeReleased = useCallback(
    async (setSpringTarget, gesture) => {
      // Check if this is a swipe
      const direction = getSwipeDirection({
        x: gesture.vx,
        y: gesture.vy
      });

      if (direction !== 'none') {
        if (!preventSwipe.includes(direction)) {
          if (onSwipe) {
            try {
              onSwipe(direction);
            } catch (error) {
              console.error('Error in onSwipe callback:', error);
            }
          }
          await animateOut({ x: gesture.vx, y: gesture.vy }, setSpringTarget, width, height);
          if (onCardLeftScreen) {
            try {
              onCardLeftScreen(direction);
            } catch (error) {
              console.error('Error in onCardLeftScreen callback:', error);
            }
          }
          if (element.current) element.current.style.display = "none";
          return;
        }
      }

      // Card was not flicked away, animate back to start
      animateBack(setSpringTarget)
    }, [preventSwipe, onSwipe, onCardLeftScreen, width, height]);

  // const [ position, setPosition ] = useState({x: 0, y: 0, rot: 0});


  const getEventCursorPosition = event => {
    if (event.touches && event.touches.length) event = event.touches[0];
    return { x: event.clientX, y: event.clientY };
  }

  useLayoutEffect(() => {
    let startPosition = { x: 0, y: 0 };
    let lastPosition;
    let isMouseDown = false;

    const setStartPostition = (x, y) => startPosition = { x, y };
    const setLastPostition = (dx, dy, vx = 0, vy = 0) => lastPosition = { dx, dy, vx, vy, timeStamp: Date.now() };

    const onMouseDown = event => {
      if (event.touches && event.touches.length === 2) return;
      event.preventDefault();
      const { x, y } = getEventCursorPosition(event);
      setStartPostition(x, y);
      setLastPostition(0, 0);
      isMouseDown = true;
    };

    const onMouseMove = event => {
      if (event.touches && event.touches.length === 2) return;
      event.preventDefault();
      if (!isMouseDown) return;
      const { x, y } = getEventCursorPosition(event);

      const
        dt = lastPosition.timeStamp - Date.now(),
        dx = x - startPosition.x,
        dy = y - startPosition.y,
        vx = -(dx - lastPosition.dx) / dt,
        vy = -(dy - lastPosition.dy) / dt;

      setLastPostition(dx, dy, vx, vy);

      let rot = vx * 13;
      if (isNaN(rot)) rot = 0;
      rot = Math.max(Math.min(rot, settings.maxTilt), -settings.maxTilt)
      setSpringTarget.start({ xyrot: [dx, dy, rot], config: physics.touchResponsive });
    }

    const onMouseUp = event => {
      if (!isMouseDown) return;
      handleSwipeReleased(setSpringTarget, lastPosition);
      isMouseDown = false;
    }

    const currentElement = element.current;
    if (currentElement) {
      window.addEventListener(('mouseup'), onMouseUp);
      window.addEventListener(('mousemove'), onMouseMove);
      currentElement.addEventListener(('mousedown'), onMouseDown);
      currentElement.addEventListener(('touchstart'), onMouseDown);
      currentElement.addEventListener(('touchmove'), onMouseMove);
      currentElement.addEventListener(('touchend'), onMouseUp);

      return () => {
        window.removeEventListener(('mouseup'), onMouseUp);
        window.removeEventListener(('mousemove'), onMouseMove);
        currentElement.removeEventListener(('mousedown'), onMouseDown);
        currentElement.removeEventListener(('touchstart'), onMouseDown);
        currentElement.removeEventListener(('touchmove'), onMouseMove);
        currentElement.removeEventListener(('touchend'), onMouseUp);
      }
    }
  }, [setSpringTarget, handleSwipeReleased]);

  // Define handlers for React component
  const handlers = {
    onMouseDown: (event) => {
      if (event.touches && event.touches.length === 2) return;
      const { x, y } = getEventCursorPosition(event);
      const startPos = { x, y };
      const lastPos = { dx: 0, dy: 0, timeStamp: Date.now() };
      
      const handleMove = (moveEvent) => {
        if (moveEvent.touches && moveEvent.touches.length === 2) return;
        moveEvent.preventDefault();
        const { x: moveX, y: moveY } = getEventCursorPosition(moveEvent);

        const
          dt = lastPos.timeStamp - Date.now(),
          dx = moveX - startPos.x,
          dy = moveY - startPos.y,
          vx = -(dx - lastPos.dx) / dt,
          vy = -(dy - lastPos.dy) / dt;

        lastPos.dx = dx;
        lastPos.dy = dy;
        lastPos.vx = vx;
        lastPos.vy = vy;
        lastPos.timeStamp = Date.now();

        let rot = vx * 13;
        if (isNaN(rot)) rot = 0;
        rot = Math.max(Math.min(rot, settings.maxTilt), -settings.maxTilt);
        setSpringTarget.start({ xyrot: [dx, dy, rot], config: physics.touchResponsive });
      };

      const handleUp = () => {
        handleSwipeReleased(setSpringTarget, lastPos);
        document.removeEventListener('mousemove', handleMove);
        document.removeEventListener('mouseup', handleUp);
        document.removeEventListener('touchmove', handleMove);
        document.removeEventListener('touchend', handleUp);
      };

      document.addEventListener('mousemove', handleMove);
      document.addEventListener('mouseup', handleUp);
      document.addEventListener('touchmove', handleMove);
      document.addEventListener('touchend', handleUp);
    }
  };

  return (
    <AnimatedDiv
      ref={element}
      style={{
        transform: xyrot.to((x, y, rot) => `translate3d(${x}px, ${y}px, 0) rotate(${rot}deg)`),
        position: 'absolute',
        width: '300px',
        height: '400px',
        willChange: 'transform',
        display: 'flex',
        borderRadius: '10px',
        boxShadow: '0 2px 5px -3px rgba(50, 50, 73, 0.1)',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
      {...handlers}
      className="swipe"
    >
      {children}
    </AnimatedDiv>
  );
});