/**
 * Extracts hashtags from a text string
 * @param {string} content - The text content to extract hashtags from
 * @returns {Array<string>} - Array of hashtags without the # symbol
 */
export const extractHashtags = (content) => {
  if (!content) return [];
  
  const hashtagRegex = /#[a-zA-Z0-9_]+/g;
  const matches = content.match(hashtagRegex) || [];
  
  // Remove the # symbol from each hashtag
  return matches.map(tag => tag.substring(1));
};

/**
 * Counts and sorts hashtags from an array of comments
 * @param {Array<Object>} comments - Array of comment objects with content property
 * @returns {Array<string>} - Array of hashtags sorted by frequency
 */
export const getPopularHashtags = (comments) => {
  if (!comments || !comments.length) {
    return ['architecture', 'design', 'modern', 'historic', 'renovation', 'sustainable', 'urban'];
  }
  
  const hashtagCounts = {};
  
  // Extract hashtags from all comments
  comments.forEach(comment => {
    if (!comment.content) return;
    
    const commentHashtags = extractHashtags(comment.content);
    commentHashtags.forEach(tag => {
      const cleanTag = tag.toLowerCase();
      hashtagCounts[cleanTag] = (hashtagCounts[cleanTag] || 0) + 1;
    });
  });
  
  // Sort hashtags by count (most frequent first)
  const sortedHashtags = Object.entries(hashtagCounts)
    .sort((a, b) => b[1] - a[1])
    .map(entry => entry[0]);
  
  // If we don't have enough hashtags, add some defaults
  if (sortedHashtags.length < 5) {
    const defaultHashtags = ['architecture', 'design', 'modern', 'historic', 'renovation', 'sustainable', 'urban'];
    defaultHashtags.forEach(tag => {
      if (!sortedHashtags.includes(tag) && sortedHashtags.length < 10) {
        sortedHashtags.push(tag);
      }
    });
  }
  
  return sortedHashtags.slice(0, 10); // Limit to 10 hashtags
};

/**
 * Renders content with clickable hashtags
 * @param {string} content - The text content containing hashtags
 * @param {function} onHashtagClick - Function to call when a hashtag is clicked
 * @param {string} activeHashtag - Currently active hashtag (without # symbol)
 * @returns {Array<React.ReactNode>} - Array of text and hashtag elements
 */
export const renderContentWithHashtags = (content, onHashtagClick, activeHashtag = null) => {
  if (!content) return [];
  
  // Regular expression to match hashtags
  const hashtagRegex = /(#[a-zA-Z0-9_]+)/g;
  
  // Split the content by hashtags
  const parts = content.split(hashtagRegex);
  
  return parts.map((part, index) => {
    // Check if this part is a hashtag
    if (part.match(hashtagRegex)) {
      const hashtag = part;
      const isActive = activeHashtag === hashtag.substring(1);
      
      return (
        <span
          key={index}
          onClick={() => onHashtagClick(hashtag)}
          className={`cursor-pointer ${isActive ? 'text-sky-600 font-semibold' : 'text-sky-500 hover:text-sky-600'}`}
        >
          {hashtag}
        </span>
      );
    }
    
    // Regular text
    return <span key={index}>{part}</span>;
  });
}; 