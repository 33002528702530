export const storageKeys = {
  token: "jwt",
  email: "email",
  username: "username",
  localSwipe: "local-swipe",
  geolocation: "geolocation"
}

export default class StorageService {

  /**
   * Save 
   * @param {string} key The key to use to store the item.
   * @param {string | object} value The value to store.
   */
  save = (key, value)  => key && localStorage.setItem(key, value);

  /**
   * Load item
   * @param {string} key The key of the stored item.
   */
  load = key => localStorage.getItem(key);

  /**
   * Removes an item from storage
   * @param {string} key The key of the stored item.
   */
  remove = key => localStorage.removeItem(key);

  saveJson = (key, value) => JSON.stringify(this.save(key, value));
  loadJson = key => JSON.parse(this.load(key));

  saveToken = token => this.save(storageKeys.token, token.trim());
  saveEmail = email => this.save(storageKeys.email, email);
  saveUsername = username => this.save(storageKeys.username, username);
  saveLocalswipe = localSwipe => this.save(storageKeys.localSwipe, localSwipe);
  saveGeolocation = coords => this.saveJson(storageKeys.geolocation, coords);

  loadToken = () => this.load(storageKeys.token);
  loadUsername = () => this.load(storageKeys.username);
  loadEmail = () => this.load(storageKeys.email);
  removeToken = () => this.remove(storageKeys.token);
  loadLocalSwipe = () => this.load(storageKeys.localSwipe);
  loadGeolocation = () => this.loadJson(storageKeys.geolocation);
  removeGeolocation = () => this.remove(storageKeys.geolocation);

  removeEmail() {
    localStorage.removeItem('email');
  }

  removeUsername() {
    localStorage.removeItem('username');
  }
}